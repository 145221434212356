import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const requestService = createApi({
  reducerPath: "request",
  tagTypes: "requests",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://welcome-habibi-backend.vercel.app/v1/manager/",
  }),
  endpoints: (builder) => {
    return {
      create: builder.mutation({
        query: (name) => {
          return {
            url: "create-worker-request",
            method: "POST",
            body: name,
          };
        },
      }),
      updaterequest: builder.mutation({
        query: (data) => {
          return {
            url: `update/${data.id}`,
            method: "PUT",
            body: { name: data.name },
          };
        },
      }),
      deleterequest: builder.mutation({
        query: (id) => {
          return {
            url: `delete/${id}`,
            method: "DELETE",
          };
        },
      }),

      fetchrequest: builder.query({
        query: (id) => {
          return {
            url: `get/${id}`,
            method: "GET",
          };
        },
      }),
      allrequests: builder.query({
        query: () => {
          return {
            url: "all-worker-request",
            method: "GET",
          };
        },
      }),
    };
  },
});
export const {
  useCreateMutation,
  useFetchrequestQuery,
  useAllrequestsQuery,
  useUpdaterequestMutation,
  useDeleterequestMutation,
} = requestService;
export default requestService;
