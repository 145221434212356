import React, { useEffect, useState } from "react";
import axios from "axios";
import { Base_url } from "../../../utils/Base_url";
import Swal from "sweetalert2";
import Input from "../../../components/Input";
import Button from "../../../components/Button";
import { PiPencilSimpleFill } from "react-icons/pi";
import { FiPlus } from "react-icons/fi";
import Super_admin_web_protal from "../../../layout/Super_admin_web_portal";
import Add_branchList from "./Add_branchList";
import { IoSearchOutline } from "react-icons/io5";
import { HiOutlineArrowLeft } from "react-icons/hi";
import { useAllbranchsQuery } from "../../../store/services/branchService";
import Edit_branchList from "./Edit_branchList";
import { RiDeleteBin5Line } from "react-icons/ri";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
const BranchList = () => {

  const { data, isFetching } = useAllbranchsQuery();
  console.log(data, isFetching);

  const [searchTerm, setSearchTerm] = useState("");




  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [filteredDeposits, setFilteredDeposits] = useState([]);
  const currentPageData = filteredDeposits.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (newPage) => setCurrentPage(newPage);

  useEffect(() => {
    filterDeposits();
  }, [data, searchTerm]);

  const filterDeposits = () => {
    let filtered = data?.branches || [];

    if (searchTerm.trim()) {
      const lowercasedSearchTerm = searchTerm.toLowerCase();
      filtered = filtered.filter((val) => {
        const investmentIdMatch = val?.city
          ?.toLowerCase()
          .includes(lowercasedSearchTerm);
        const managerIdMatch = val?.location
          ?.toLowerCase()
          .includes(lowercasedSearchTerm);
       

        return investmentIdMatch || managerIdMatch;
      });
    }

    setFilteredDeposits(filtered);
    setCurrentPage(1);
  };

  



  const [users, setUsers] = useState([]);
  const [fetchUser,setFetchUser] = useState({})
  const [isUpdateOpen, setIsUpdateOpen] = useState(false);
  const [singleUser, setSingleUser] = useState({});
  const UpdateModal = () => {
    setIsUpdateOpen(true);
  };

  const closeUpdateModal = () => {
    setIsUpdateOpen(false);
  };

  console.log(isUpdateOpen);

  const [deleteUser, setDeleteUser] = useState({});

  console.log(deleteUser, "deleteUser");

  useEffect(() => {
    axios
      .get(`${Base_url}/customer/getAll`)
      .then((res) => {
        console.log(res);

        setUsers(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  console.log(users);

  const removeFunction = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#00afef",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${Base_url}/v1/admin/delete-branch/${id}`)
          .then((res) => {
            console.log(res);
            Swal.fire("Deleted!", "Your file has been deleted.", "success");

               window.location.reload();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <Super_admin_web_protal

    language={
      <>
        <p className=" sm:block hidden">English | Urdu</p>
      </>
    }
    ToggleBtn={
      <div className=" flex gap-4 items-center">
        <HiOutlineArrowLeft/>
        <h2 className="">Branch List</h2>
      </div>
    }

      title_props={
        <>
          <div className=" w-96 relative">
            <Input
              placeholder={""}
              className={" border bg-white w-full rounded-full"}
            />

<Button
             Icon={<IoSearchOutline className=" text-white text-xl" />}
              label={"search"}
              className={
                " absolute top-0 right-0 bg-secondary  text-white uppercase font-normal rounded-full"
              }
            />
          </div>
        </>
      }
    >
      <Add_branchList
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        setUsers={setUsers}
      />

<Edit_branchList
        isModalOpen={isUpdateOpen}
        setIsModalOpen={setIsUpdateOpen}
        setUsers={setUsers}
        getData={fetchUser}
      />

<div className="  w-full  mb-4  sm:hidden block relative">
        <Input
          placeholder={""}
          className={" border bg-white w-full rounded-full"}
        />

<Button
              Icon={<IoSearchOutline className=" text-white text-xl" />}
              label={"search"}
              className={
                " absolute top-0 right-0 bg-secondary text-white uppercase font-normal rounded-full"
              }
            />
      </div>

      <div className=" sm:flex block    justify-between items-center">
        <div>
          <h2 className=" sm:block hidden">Branch List</h2>
        </div>

        <div className=" flex gap-3 items-center">
          <Button
            onClick={openModal}
            Icon={<FiPlus />}
            label={"Add Branch"}
            className={
              " bg-secondary text-white  font-normal py-3 rounded-full"
            }
          />
        </div>
      </div>
      <section className="mb-20 mt-7 text-gray-800">
        <div className="block rounded-lg ">
          <div className="flex flex-col overflow-hidden">
            <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full sm:px-6 lg:px-8">
                <div className="">
                  <table className="min-w-full mb-0">
                    <thead className=" ">
                      <tr className=" rounded-lg whitespace-nowrap ">
                        <th
                          scope="col"
                          className=" text-sm text-darkGray    font-medium px-6 "
                        >
                          City
                        </th>
                        <th
                          scope="col"
                          className=" text-sm text-darkGray   font-medium px-6 "
                        >
                          Location
                        </th>
                        <th
                          scope="col"
                          className=" text-sm text-darkGray   font-medium px-6 "
                        >
                          Email
                        </th>

                        <th
                          scope="col"
                          className="text-sm  text-darkGray   font-medium px-6 "
                        >
                          Contact number 1
                        </th>




                        <th
                          scope="col"
                          className="text-sm  text-darkGray     font-medium px-6 "
                        >
                         Contact number 2
                        </th>
                       

                        <th
                          scope="col"
                          className="text-sm    text-darkGray   font-medium px-6 "
                        ></th>
                      </tr>
                    </thead>
                    <tbody className="">
                      {currentPageData?.map((item, index) => {
                        return (
                          <>
                            <tr className="bg-white border-t-8 border-b-8    border-[#F6F6F6]  rounded-md ">
                                                            <td className="align-middle text-sm font-normal px-6 py-4 whitespace-nowrap  text-center">
                                <span className=" text-base text-secondary  py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline   bg-green-200  rounded-full">
                                 {item?.city}
                                </span>
                              </td>
                              <td className="text-sm font-normal text-center px-6 py-4 whitespace-nowrap">
                                <span className=" text-base  text-black py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline   bg-green-200  rounded-full">
                                 {item?.location}
                                </span>
                              </td>
                              <td className="text-sm font-normal text-center px-6 py-4 whitespace-nowrap">
                                <span className=" text-base  text-black py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline   bg-green-200  rounded-full">
                                 {item?.email}
                                </span>
                              </td>
                              <td className="align-middle text-center text-sm font-normal px-6 py-4 whitespace-nowrap text-left">
                                <span className=" text-base  text-black  py-1 px-2.5 leading-none  whitespace-nowrap    bg-green-200  rounded-full">
                                {item?.contactNumber1}
                                </span>
                              </td>
                              <td className="align-middle text-center text-sm font-normal px-6 py-4 whitespace-nowrap text-left">
                                <span className=" text-base text-black  py-1 px-2.5 leading-none  whitespace-nowrap    bg-green-200  rounded-full">
                                {item?.contactNumber2}
                                </span>
                              </td>

                            

                              
                              <td className="align-middle  flex items-center gap-3 justify-between px-6 pt-5 text-sm font-normal   whitespace-nowrap">
                                <span onClick={()=>{setIsUpdateOpen(true)
                                 setFetchUser(item)
                                }} className=" text-base text-black  cursor-pointer  leading-none  whitespace-nowrap    bg-green-200  rounded-full">
                                <PiPencilSimpleFill className=" text-primary" />
                                </span>
                                <span 
                                 onClick={()=>removeFunction(item?._id)}
                                className=" text-base text-black   leading-none  cursor-pointer  whitespace-nowrap    bg-green-200  rounded-full">
                                <RiDeleteBin5Line className=" text-[#d33]" />
                                </span>
                              </td>
                             
                                
                             
                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className=" flex justify-end items-center  py-4 gap-6">
            <button
              className={`${
                currentPage === 1
                  ? "bg-gray-500  text-white"
                  : "bg-secondary text-white"
              } flex justify-center items-center  w-10 h-10 rounded-md`}
              disabled={currentPage === 1}
              onClick={() => handlePageChange(currentPage - 1)}
            >
              <FaAngleLeft
               size={20} />
            </button>
            <span className="  text-primary  font-semibold">
              {currentPage} of{" "}
              {Math.ceil(filteredDeposits.length / itemsPerPage)}
            </span>
            <button
              className={`${
                currentPage * itemsPerPage >= filteredDeposits.length
                  ? "bg-gray-500  text-white"
                  : "bg-secondary text-white"
              } flex justify-center items-center  w-10 h-10 rounded-md`}
              disabled={currentPage * itemsPerPage >= filteredDeposits.length}
              onClick={() => handlePageChange(currentPage + 1)}
            >
              <FaAngleRight size={20} />
            </button>
          </div>
        </div>
      </section>
    </Super_admin_web_protal>
  );
};

export default BranchList;
