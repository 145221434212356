import React, { useEffect, useState } from "react";
import Super_admin_web_protal from "../../../layout/Super_admin_web_portal";
import { useAllinvestorsQuery } from "../../../store/services/investorService";
import { Link, useParams } from "react-router-dom";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import axios from "axios";
import Worker_web_protal from "../../../layout/Worker_web_portal";
import Button from "../../../components/Button";
import { FiPlus } from "react-icons/fi";
import AddInrollInvestor from "./InrollInvestor/AddInrollInvestor";
import { useSelector } from "react-redux";
import Register_Investor from "../investors/Register_Investor";
import Input from "../../../components/Input";
import { IoSearchOutline } from "react-icons/io5";
import { HiOutlineArrowLeft } from "react-icons/hi";

const View_plan = () => {
  const [state, setState] = useState("details");
  const { id } = useParams();

  const [viewInvestor, setViewInvestor] = useState([]);
  console.log(viewInvestor);

  
  const { data, isFetching } = useAllinvestorsQuery();
  const { workerToken } = useSelector((state) => state.authReducer);
  const [viewPlans, setViewPlans] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;
  const [filteredDeposits, setFilteredDeposits] = useState([]);
  const currentPageData = filteredDeposits.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (newPage) => setCurrentPage(newPage);

  useEffect(() => {
    filterDeposits();
  }, [viewInvestor, searchTerm]);

  const filterDeposits = () => {
    let filtered = viewInvestor || [];

    if (searchTerm.trim()) {
      const lowercasedSearchTerm = searchTerm.toLowerCase();
      filtered = filtered.filter((val) => {
        const investmentIdMatch = val?.investorName
          ?.toLowerCase()
          .includes(lowercasedSearchTerm);
        const managerIdMatch = val?.investorCnic
          ?.toLowerCase()
          .includes(lowercasedSearchTerm);
       

        return investmentIdMatch || managerIdMatch;
      });
    }

    setFilteredDeposits(filtered);
    setCurrentPage(1);
  };


  useEffect(() => {
   
    const params = {
      workerId: workerToken,
      planId: id,
    };
    axios
      .post(
        `https://welcome-habibi-backend.vercel.app/v1/worker/investor-by-worker`,
        params
      )
      .then((res) => {
        console.log(res, "dddddddddddddddddddsewwwwwwwwwwww");

        
      setViewInvestor(res?.data?.enrollments || []);
      })
      .catch((error) => {});
  }, [workerToken, id]);


  useEffect(() => {
    filterDeposits();
  }, [viewInvestor, searchTerm]);



  const [viewPlan, setViewPlan] = useState({});
 
  useEffect(() => {
    axios
      .get(
        `https://welcome-habibi-backend.vercel.app/v1/admin/plan-detail/${id}`
      )
      .then((res) => {
        console.log(res);

        setViewPlan(res.data.data);
      })
      .catch((error) => {});
  }, []);

 
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };


  const [investorModalOpen, setInvestorModalOpen] = useState(false);

  const openInvestorModal = () => {
    setInvestorModalOpen(true);
  };

  const closeInvestorModal = () => {
    setInvestorModalOpen(false);
  };


  

  const extractTextFromHTML = (htmlString) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");
    return doc.body.textContent || "";
  };



  return (
    <Worker_web_protal   language={
      <>
        <p className=" sm:block  hidden">English | Urdu</p>
      </>
    }
    ToggleBtn={
      <div className=" flex gap-4 items-center">
        <HiOutlineArrowLeft />
        <h2 className="">View Plan</h2>
      </div>
    }
    title_props={
      <>
        <div className=" w-96 relative">
          <Input
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder={""}
            className={" border bg-white w-full rounded-full"}
          />

          <Button
            Icon={<IoSearchOutline className=" text-white text-xl" />}
            label={"search"}
            className={
              " absolute top-0 right-0 bg-secondary  text-white uppercase font-normal rounded-full"
            }
          />
        </div>
      </>
    }>
      <div>
        <AddInrollInvestor
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          getData={viewPlan}
          viewPlans={viewPlans}
        />



<Register_Investor
        isModalOpen={investorModalOpen}
        setIsModalOpen={setInvestorModalOpen}
      
      />

        <div className="  flex justify-between items-center">
          <div></div>
          <ul className=" p-0 flex justify-center  gap-16 items-center">
            <li
              onClick={() => {
                setState("details");
              }}
              className={`text-black ${
                state === "details"
                  ? "font-semibold border-secondary border-b-2"
                  : ""
              }  `}
            >
              Details
            </li>
            <li
              onClick={() => setState("investors")}
              className={`text-black ${
                state === "investors"
                  ? "font-semibold border-secondary border-b-2"
                  : ""
              }  `}
            >
              Investors
            </li>
            <li className=" text-black ">
              <Button
                onClick={() => {openModal(true)
                  setViewPlans(viewPlan)

                }}
                Icon={<FiPlus />}
                className={
                  " bg-secondary text-white  font-normal py-3 rounded-full"
                }
                label={"Enroll Investor"}
              />
            </li>
            {/* <li className=" text-black ">
              <Button

                onClick={openInvestorModal}
                Icon={<FiPlus />}
                className={
                  " bg-secondary text-white  font-normal py-3 rounded-full"
                }
                label={"Register Investor"}
              />
            </li> */}
          </ul>
        </div>

        {state === "details" ? (
          <>
            <div className=" w-full mt-12">
              <img src={viewPlan?.planImage} className=" mx-auto" alt="" />
            </div>

            <div className=" text-center p-8">
              <h1 className=" text-primary text-xl font-semibold">
                {viewPlan?.planName}
              </h1>
              <img src={viewPlan?.planDescription} className=" mx-auto  w-full" alt="" />
            </div>
          </>
        ) : (
          <section className="mb-20 mt-12 text-gray-800">
            <div className="block rounded-lg ">
              <div className="flex flex-col">
                <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full sm:px-6 lg:px-8">
                    <div className="overflow-hidden">
                      <table className="min-w-full mb-0">
                        <thead className=" ">
                          <tr className=" rounded-lg whitespace-nowrap ">
                            <th
                              scope="col"
                              className=" text-sm  text-darkGray  font-medium px-6"
                            >
                              Name
                            </th>
                            <th
                              scope="col"
                              className=" text-sm text-darkGray    font-medium px-6 "
                            >
                              CNIC No
                            </th>

                            <th
                              scope="col"
                              className="text-sm  text-darkGray   font-medium px-6 "
                            >
                              Total Deposits
                            </th>

                            <th
                              scope="col"
                              className="text-sm  text-darkGray     font-medium px-6 "
                            >
                              Total Profit
                            </th>
                            <th
                              scope="col"
                              className="text-sm    text-darkGray   font-medium px-6 "
                            >
                              Total Deducations
                            </th>
                          </tr>
                        </thead>
                        <tbody className="">
                          {currentPageData?.map((item, index) => {
                            return (
                              <>
                                <tr className="bg-white border-t-8 border-b-8    border-[#F6F6F6]  rounded-md ">
                                  <th
                                    scope="row"
                                    className="text-sm font-normal px-6 py-4   whitespace-nowrap "
                                  >
                                    <Link
                                      onClick={() =>
                                        localStorage.setItem(
                                          "iName",
                                          item?.investorName
                                        )
                                      }
                                      to={`/worker/plan_investor_details/${item?.investorCnic}/${id}`}
                                      className="mb-0.5 font-medium  text-secondary"
                                    >
                                      {item?.investorName}
                                    </Link>
                                  </th>
                                  <td className="align-middle text-sm font-normal px-6 py-4 whitespace-nowrap  text-center">
                                    <span className=" text-base text-black  py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline   bg-green-200  rounded-full">
                                      {item?.investorCnic}
                                    </span>
                                  </td>

                                  <td className="align-middle text-center text-sm font-normal px-6 py-4 whitespace-nowrap text-left">
                                    <span className=" text-base text-black  py-1 px-2.5 leading-none  whitespace-nowrap    bg-green-200  rounded-full">
                                      {item?.totalDeposit}
                                    </span>
                                  </td>
                                  <td className="align-middle text-center text-sm font-normal px-6 py-4 whitespace-nowrap text-left">
                                    <span className=" text-base text-black  py-1 px-2.5 leading-none  whitespace-nowrap    bg-green-200  rounded-full">
                                      {item?.totalProfit}
                                    </span>
                                  </td>

                                  <td className="align-middle  text-center text-sm font-normal px-6 py-4 whitespace-nowrap">
                                    <span className=" text-base text-black  py-1 px-2.5 leading-none  whitespace-nowrap    bg-green-200  rounded-full">
                                      {item?.totalDeduction}
                                    </span>
                                  </td>
                                </tr>
                              </>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className=" flex justify-end items-center  py-4 gap-6">
                <button
                  className={`${
                    currentPage === 1
                      ? "bg-gray-500  text-white"
                      : "bg-secondary text-white"
                  } flex justify-center items-center  w-10 h-10 rounded-md`}
                  disabled={currentPage === 1}
                  onClick={() => handlePageChange(currentPage - 1)}
                >
                  <FaAngleLeft size={20} />
                </button>
                <span className="  text-primary  font-semibold">
                  {currentPage} of{" "}
                  {Math.ceil(filteredDeposits.length / itemsPerPage)}
                </span>
                <button
                  className={`${
                    currentPage * itemsPerPage >= filteredDeposits.length
                      ? "bg-gray-500  text-white"
                      : "bg-secondary text-white"
                  } flex justify-center items-center  w-10 h-10 rounded-md`}
                  disabled={
                    currentPage * itemsPerPage >= filteredDeposits.length
                  }
                  onClick={() => handlePageChange(currentPage + 1)}
                >
                  <FaAngleRight size={20} />
                </button>
              </div>
            </div>
          </section>
        )}
      </div>
    </Worker_web_protal>
  );
};

export default View_plan;
