import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const contactusService = createApi({
  reducerPath: "contactus",
  tagTypes: "contactuss",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://beauty-bridge-a647ece2d453.herokuapp.com/contactus/",
    prepareHeaders: (headers, { getState }) => {
      const reducers = getState();
      const token = reducers?.authReducer?.adminToken;
      console.log(token);
      headers.set("authorization", token ? `Bearer ${token}` : "");
      return headers;
    },
  }),
  endpoints: (builder) => {
    return {
      create: builder.mutation({
        query: (name) => {
          return {
            url: "send",
            method: "POST",
            body: name,
          };
        },
        invalidatesTags: ["contactuss"],
      }),
      updatecontactus: builder.mutation({
        query: (data) => {
          return {
            url: `update/${data.id}`,
            method: "PUT",
            body: { name: data.name },
          };
        },
        invalidatesTags: ["contactuss"],
      }),
      deletecontactus: builder.mutation({
        query: (id) => {
          return {
            url: `delete/${id}`,
            method: "DELETE",
          };
        },
        invalidatesTags: ["contactuss"],
      }),

      fetchcontactus: builder.query({
        query: (id) => {
          return {
            url: `get/${id}`,
            method: "GET",
          };
        },
        providesTags: ["contactuss"],
      }),
      allcontactuss: builder.query({
        query: () => {
          return {
            url: "getAll",
            method: "GET",
          };
        },
      }),
    };
  },
});
export const {
  useCreateMutation,
  useFetchcontactusQuery,
  useAllcontactussQuery,
  useUpdatecontactusMutation,
  useDeletecontactusMutation,
} = contactusService;
export default contactusService;
