import { configureStore } from "@reduxjs/toolkit";
import authService from "./services/authService";
import categoryService from "./services/categoryService";
import authReducer from "./reducers/authReducer";
import globalReducer from "./reducers/globalReducer";
import bannerService from "./services/bannerService";
import blogService from "./services/blogService";
import contactusService from "./services/contactusService";
import uploadService from "./services/uploadService";
import workerService from "./services/workerService";
import branchService from "./services/branchService";
import rewardService from "./services/rewardService";
import depositService from "./services/depositService";
import investorService from "./services/investorService";
import eventService from "./services/eventService";
import collectionService from "./services/collectionService";
import profitDeductionService from "./services/profitDeductionService";
import requestService from "./services/requestService";
import companyExpenseService from "./services/companyExpenseService";
import investmentService from "./services/investmentService";
import managerService from "./services/managerService";
import planService from "./services/planService";
import alertService from "./services/alertService";
const Store = configureStore({
  reducer: {
    [authService.reducerPath]: authService.reducer,
    [categoryService.reducerPath]: categoryService.reducer,
    [bannerService.reducerPath]: bannerService.reducer,
    [blogService.reducerPath]: blogService.reducer,
    [contactusService.reducerPath]: contactusService.reducer,
    [uploadService.reducerPath]: uploadService.reducer,
    [workerService.reducerPath]: workerService.reducer,
    [branchService.reducerPath]: branchService.reducer,
    [rewardService.reducerPath]: rewardService.reducer,
    [depositService.reducerPath]: depositService.reducer,
    [investorService.reducerPath]: investorService.reducer,
    [eventService.reducerPath]: eventService.reducer,
    [collectionService.reducerPath]: collectionService.reducer,
    [profitDeductionService.reducerPath]:profitDeductionService.reducer,
    [requestService.reducerPath]:requestService.reducer,
    [companyExpenseService.reducerPath]:companyExpenseService.reducer,
    [investmentService.reducerPath]:investmentService.reducer,
    [managerService.reducerPath]:managerService.reducer,
    [planService.reducerPath]:planService.reducer,
    [alertService.reducerPath]:alertService.reducer,
    authReducer: authReducer,
    globalReducer: globalReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      categoryService.middleware,
      bannerService.middleware,
      blogService.middleware,
      contactusService.middleware,
      uploadService.middleware,
      workerService.middleware,
      branchService.middleware,
      rewardService.middleware,
      depositService.middleware,
      investorService.middleware,
      eventService.middleware,
      collectionService.middleware,
      profitDeductionService.middleware,
      companyExpenseService.middleware,
      requestService.middleware,
      investmentService.middleware,
      managerService.middleware,
      planService.middleware,
      alertService.middleware,
    ]),
});
export default Store;
