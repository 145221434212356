import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { FaCircle } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { logout } from "../../../store/reducers/authReducer";
import { toast } from "react-toastify";
const ManagerSidebar = ({ side, closeSidebar }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };



  
  const location = useLocation();
  const isActive = (route) => location.pathname.includes(route);



  
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const adminLogout = () => {
    dispatch(logout('managerToken'));
    toast.success('Manager logout successfully!')
    navigate('/login')
  }


  return (
    <div
      className={`fixed top-0  shadow-xl ${side} sm:left-0    w-64 h-screen     z-30 transition-all`}
    >
      <div className="bg-primary  overflow-y-auto  h-full w-64">
        <i
          className="bi bi-x-lg absolute text-black top-4 right-4 sm:hidden block cursor-pointer text-lg"
          onClick={closeSidebar}
        ></i>
        <div className="  px-5 py-8 ">
          <h1 className=" text-white text-4xl font-medium">
            <img
              src={require("../../../assets/image/logo.png")}
              className="   mx-auto"
              alt=""
            />
            {/* Logo */}
          </h1>
        </div>

        <ul className=" pb-5">
          <li className="px-2 cursor-pointer font-semibold transition-all py-3 text-white flex items-center  hover:bg-primary hover:text-white">
        
            <FaCircle  size={12} className=" mr-2" color="white"/>
            <div  className="text-lg capitalize">
           
    User Management
            </div>
          </li>
          <li className="px-4 cursor-pointer font-semibold transition-all py-3 text-white flex items-center  hover:bg-primary hover:text-white">
          <i class="bi bi-grid-fill  mr-2 inline-block text-xl"></i>
       
        <Link to="/manager/managerPlans"  className={`text-lg text-white  ${
                isActive("/manager/managerPlans")
                  ? " font-semibold"
                  : "font-extralight"
              }  capitalize`}>
       
        Plan
        </Link>
      </li>
          <li className="px-4 cursor-pointer font-semibold transition-all py-3 text-white flex items-center  hover:bg-primary hover:text-white">
          <i class="bi bi-grid-fill  mr-2 inline-block text-xl"></i>
       
        <Link to="/manager/dashboard"  className={`text-lg text-white  ${
                isActive("/manager/dashboard")
                  ? " font-semibold"
                  : "font-extralight"
              }  capitalize`}>
       
        Workers
        </Link>
      </li>

      <li className="px-4 cursor-pointer  transition-all py-3 text-white flex items-center  hover:bg-primary hover:text-white">
          <i class="bi bi-grid-fill  mr-2 inline-block text-xl"></i>
       
        <Link to="/manager/investors"  className={`text-lg text-white  ${
                isActive("/manager/investors")
                  ? " font-semibold"
                  : "font-extralight"
              }  capitalize`}>
       
        Investors
        </Link>
      </li>

          <li className="px-4 cursor-pointer  transition-all py-3 text-white flex items-center  hover:bg-primary hover:text-white">
            <i class="bi bi-grid-fill  mr-2 inline-block text-xl"></i>
            <Link to="/manager/profits_and_deducations" className={`text-lg text-white  ${
                isActive("/manager/profits_and_deducations")
                  ? " font-semibold"
                  : "font-extralight"
              }  capitalize`}>
              Deductions and Profits
            </Link>
          </li>

          <li className="px-4 cursor-pointer  transition-all py-3 text-white flex items-center  hover:bg-primary hover:text-white">
            <i class="bi bi-grid-fill  mr-2 inline-block text-xl"></i>
            <Link to="/manager/rewards" className={`text-lg text-white  ${
                isActive("/manager/rewards")
                  ? " font-semibold"
                  : "font-extralight"
              }  capitalize`}>
              Rewards
            </Link>
          </li>

          <li className="px-4 cursor-pointer  transition-all py-3 text-white flex items-center  hover:bg-primary hover:text-white">
            <i class="bi bi-grid-fill  mr-2 inline-block text-xl"></i>
            <Link to="/manager/alerts" className={`text-lg text-white  ${
                isActive("/manager/alerts")
                  ? " font-semibold"
                  : "font-extralight"
              }  capitalize`}>
              Alerts
            </Link>
          </li>

          <li className="px-4 cursor-pointer transition-all py-3 text-white flex items-center  hover:bg-primary hover:text-white">
            <i class="bi bi-grid-fill  mr-2 inline-block text-xl"></i>
            <Link to="/manager/compnay_expence" className={`text-lg text-white  ${
                isActive("/manager/compnay_expence")
                  ? " font-semibold"
                  : "font-extralight"
              }  capitalize`}>
              Company Expense
            </Link>
          </li>

          <li className="px-4 cursor-pointer  transition-all py-3 text-white flex items-center  hover:bg-primary hover:text-white">
            <i class="bi bi-grid-fill  mr-2 inline-block text-xl"></i>
            <Link to="/manager/requests" className={`text-lg text-white  ${
                isActive("/manager/requests")
                  ? " font-semibold"
                  : "font-extralight"
              }  capitalize`}>
              Requests
            </Link>
          </li>

          <li className="px-4 cursor-pointer  transition-all py-3 text-white flex items-center  hover:bg-primary hover:text-white">
            <i class="bi bi-grid-fill  mr-2 inline-block text-xl"></i>
            <Link to="/manager/wht_gallery" className={`text-lg text-white  ${
                isActive("/manager/wht_gallery")
                  ? " font-semibold"
                  : "font-extralight"
              }  capitalize`}>
              WHT Gallery
            </Link>
          </li>

          <li className="px-4 cursor-pointer  transition-all py-3 text-white flex items-center  hover:bg-primary hover:text-white">
            <i class="bi bi-grid-fill  mr-2 inline-block text-xl"></i>
            <Link to="/manager/branch_list" className={`text-lg text-white  ${
                isActive("/manager/branch_list")
                  ? " font-semibold"
                  : "font-extralight"
              }  capitalize`}>
              Branch List
            </Link>
          </li>
          <li onClick={adminLogout} className="px-4 cursor-pointer font-semibold transition-all py-3 text-white flex items-center  hover:bg-primary hover:text-white">
            <i class="bi bi-grid-fill  mr-2 inline-block text-xl"></i>
            <Link className={`text-md   text-red`}>
              Logout
            </Link>
          </li>
        </ul>
      </div>
      <div
        onClick={closeSidebar}
        className={` bg-[rgba(0,0,0,0.6)]  ${
          side === "-left-64 md:left-0" ? " w-64" : " w-full"
        }     -z-20 h-screen  fixed   top-0   sm:left-0`}
      ></div>
    </div>
  );
};
export default ManagerSidebar;
