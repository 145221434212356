import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import moment from "moment";

const companyExpenseService = createApi({
  reducerPath: "companyExpense",
  tagTypes: "companyExpenses",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://welcome-habibi-backend.vercel.app/v1/manager/",
  }),
  endpoints: (builder) => {
    return {
      create: builder.mutation({
        query: (name) => {
          return {
            url: "create-company-expense",
            method: "POST",
            body: name,
          };
        },
      }),
      updatecompanyExpense: builder.mutation({
        query: (data) => {
          return {
            url: `update/${data.id}`,
            method: "PUT",
            body: { name: data.name },
          };
        },
      }),
      deletecompanyExpense: builder.mutation({
        query: (id) => {
          return {
            url: `delete/${id}`,
            method: "DELETE",
          };
        },
      }),

      fetchcompanyExpense: builder.query({
        query: (id) => {
          return {
            url: `get/${id}`,
            method: "GET",
          };
        },
      }),
      allcompanyExpenses: builder.query({
        query: ({ startDate, endDate }) => {
          let url = "all-company-expense";
          if (startDate || endDate) {
            const params = new URLSearchParams();
            if (startDate) params.append("startDate", moment(startDate).format('YYYY-MM-DD'));
            if (endDate) params.append("endDate", moment(endDate).format('YYYY-MM-DD'));
            url = `${url}?${params.toString()}`;
          }
      
          return {
            url,
            method: "GET",
          };
        },
      }),
    };
  },
});
export const {
  useCreateMutation,
  useFetchcompanyExpenseQuery,
  useAllcompanyExpensesQuery,
  useUpdatecompanyExpenseMutation,
  useDeletecompanyExpenseMutation,
} = companyExpenseService;
export default companyExpenseService;
