import React, { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { VscFilter } from "react-icons/vsc";
import { GoTriangleDown } from "react-icons/go";
import { FiPlus } from "react-icons/fi";
import Worker_web_protal from "../../../layout/Worker_web_portal";
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import { Base_url } from "../../../utils/Base_url";
import { IoSearchOutline } from "react-icons/io5";
import Add_Deposits from "./Add_Deposits";
import { HiOutlineArrowLeft } from "react-icons/hi";
import { useFetchallmydepositQuery, useFetchdepositQuery } from "../../../store/services/depositService";
import ViewSlip from "./ViewSlip";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import moment from "moment";
import { useSelector } from "react-redux";
const Deposits = () => {
  const [users, setUsers] = useState([]);
  const worker_data = JSON.parse(localStorage.getItem('worker_data'))
  const { workerToken } = useSelector((state) => state.authReducer);
  const [isUpdateOpen, setIsUpdateOpen] = useState(false);
  const { data, isFetching } = useFetchallmydepositQuery(workerToken);
  console.log(data, "ffffff");

  const [searchTerm, setSearchTerm] = useState("");
  const [minValue, setMinValue] = useState("");
  const [maxValue, setMaxValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage =6;
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [filteredDeposits, setFilteredDeposits] = useState([]);
  useEffect(() => {
    filterDeposits();
  }, [data, searchTerm, minValue, maxValue]);

  const toggleDropdown = () => setDropdownVisible(!dropdownVisible);

  const filterDeposits = () => {
    let filtered = data?.deposits?.slice()
    .reverse() || [];
    if (searchTerm) {
      filtered = filtered.filter(
        (deposit) =>
          deposit.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          deposit.cnic.toLowerCase().includes(searchTerm.toLowerCase())  ||
          deposit.acknowledgeAuth.toLowerCase().includes(searchTerm.toLowerCase()) ||
          deposit.acknowledgeDate.toLowerCase().includes(searchTerm.toLowerCase()) ||
          deposit.depositDate.toLowerCase().includes(searchTerm.toLowerCase()) 
      );
    }
    if (minValue || maxValue) {
      const min = parseFloat(minValue) || Number.MIN_SAFE_INTEGER;
      const max = parseFloat(maxValue) || Number.MAX_SAFE_INTEGER;
      filtered = filtered.filter((deposit) => {
        const amount = parseFloat(deposit.amount);
        return amount >= min && amount <= max;
      });
    }

    
    setFilteredDeposits(filtered);
    setCurrentPage(1); 
  };

  const currentPageData = filteredDeposits.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );


  const handlePageChange = (newPage) => setCurrentPage(newPage);

  const removeFunction = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#A47ABF",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${Base_url}/customer/delete/${id}`)
          .then((res) => {
            console.log(res);
            if (res.status === 200) {
              Swal.fire("Deleted!", "Your file has been deleted.", "success");
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const [getData, setGetData] = useState({});

  const [isModalOpen2, setIsModalOpen2] = useState(false);

  const openModal2 = () => {
    setIsModalOpen2(true);
  };

  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  return (
    <Worker_web_protal
      language={
        <>
          <p className=" sm:block  hidden">English | Urdu</p>
        </>
      }
      ToggleBtn={
        <div className=" flex gap-4 items-center">
          <HiOutlineArrowLeft />
          <h2 className="">Deposits</h2>
        </div>
      }
      title_props={
        <>
          <div className=" w-96 relative">
            <Input
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder={""}
              className={" border bg-white w-full rounded-full"}
            />

            <Button
              Icon={<IoSearchOutline className=" text-white text-xl" />}
              label={"search"}
              className={
                " absolute top-0 right-0 bg-secondary  text-white uppercase font-normal rounded-full"
              }
            />
          </div>
        </>
      }
    >
      <Add_Deposits
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        setUsers={setUsers}
      />

      <ViewSlip
        isModalOpen={isModalOpen2}
        setIsModalOpen={setIsModalOpen2}
        getData={getData}
      />

      <div className="  w-full  mb-4  sm:hidden block relative">
        <Input
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder={""}
          className={" border bg-white w-full rounded-full"}
        />

        <Button
          Icon={<IoSearchOutline className=" text-white text-xl" />}
          label={"search"}
          className={
            " absolute top-0 right-0 bg-secondary text-white uppercase font-normal rounded-full"
          }
        />
      </div>
      <div className=" sm:flex block    justify-between items-center">
        <div>
          <h2 className=" sm:block hidden">Deposits</h2>
        </div>

        <div className=" flex gap-3 items-center justify-between">
          <div className=" relative">
            <Button
              Icon={<VscFilter size={27} />}
              Icons={<GoTriangleDown />}
              label={"Select Filters"}
              onClick={toggleDropdown}
              className={
                " bg-white font-normal text-darkGray py-3 rounded-full"
              }
            />
            {dropdownVisible && (
              <div className="absolute z-10 p-3 mt-2 w-80 rounded-md shadow-lg bg-white left-0 ring-black ring-opacity-5">
                <div className="py-1 gap-4 flex">
                  <div className=" py-2">
                    <label
                      htmlFor="minValue"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Min Value
                    </label>
                    <Input
                      type="number"
                      id="minValue"
                      value={minValue}
                      onChange={(e) => setMinValue(e.target.value)}
                      className="mt-1 p-2 w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>
                  <div className=" py-2">
                    <label
                      htmlFor="maxValue"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Max Value
                    </label>
                    <Input
                      type="number"
                      id="maxValue"
                      value={maxValue}
                      onChange={(e) => setMaxValue(e.target.value)}
                      className="mt-1 p-2 w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>

                {/* <Button
                  onClick={handleSearch}
                  label={"Search"}
                  className={
                    "  bg-secondary rounded-full w-full justify-center text-white"
                  }
                /> */}
              </div>
            )}
          </div>
          <Button
            onClick={openModal}
            Icon={<FiPlus />}
            label={"Add Deposit"}
            className={
              " bg-secondary text-white   font-normal py-3 rounded-full"
            }
          />
        </div>
      </div>
      <section className="mb-20 mt-7 text-gray-800">
        <div className="block rounded-lg overflow-hidden ">
          <div className="flex flex-col">
            <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full sm:px-6 lg:px-8">
                <div className="">
                  <table className="min-w-full mb-0">
                    <thead className=" ">
                      <tr className=" rounded-lg whitespace-nowrap ">
                        <th
                          scope="col"
                          className=" text-sm  text-darkGray  font-medium px-6"
                        >
                          Deposit Date
                        </th>
                        <th
                          scope="col"
                          className=" text-sm text-darkGray    font-medium px-6 "
                        >
                          Name
                        </th>
                        <th
                          scope="col"
                          className=" text-sm text-darkGray   font-medium px-6 "
                        >
                          CNIC No
                        </th>

                        <th
                          scope="col"
                          className="text-sm  text-darkGray   font-medium px-6 "
                        >
                          Type
                        </th>

                        <th
                          scope="col"
                          className="text-sm  text-darkGray     font-medium px-6 "
                        >
                          Receipt No
                        </th>
                        <th
                          scope="col"
                          className="text-sm  text-darkGray     font-medium px-6 "
                        >
                          Amount
                        </th>
                        <th
                          scope="col"
                          className="text-sm  text-darkGray     font-medium px-6 "
                        >
                          Bank Name
                        </th>
                        <th
                          scope="col"
                          className="text-sm  text-darkGray     font-medium px-6 "
                        >
                          Branch
                        </th>
                        <th
                          scope="col"
                          className="text-sm  text-darkGray     font-medium px-6 "
                        >
                          Reference
                        </th>
                        <th
                          scope="col"
                          className="text-sm  text-darkGray     font-medium px-6 "
                        >
                          Ack. Auth.
                        </th>
                        <th
                          scope="col"
                          className="text-sm  text-darkGray     font-medium px-6 "
                        >
                          Ack Date
                        </th>
                        <th
                          scope="col"
                          className="text-sm  text-darkGray     font-medium px-6 "
                        >
                          Remarks
                        </th>
                        {/* <th
                          scope="col"
                          className="text-sm  text-darkGray     font-medium px-6 "
                        >
                          Ack. Auth.
                        </th>
                        <th
                          scope="col"
                          className="text-sm  text-darkGray     font-medium px-6 "
                        >
                          Ack Date
                        </th> */}
                        <th
                          scope="col"
                          className="text-sm    text-darkGray   font-medium px-6 "
                        >
                          Slip
                        </th>
                      </tr>
                    </thead>
                    <tbody className="">
                      {currentPageData?.map((item, index) => {
                        // if(item?.createdBy===worker_data?._id){
                          

                        // }
                          return (
                            <>
                              <tr className="bg-white border-t-8 border-b-8    border-[#F6F6F6]  rounded-md ">
                                <th
                                  scope="row"
                                  className="text-sm font-normal px-6 py-4   whitespace-nowrap "
                                >
                                  <p className="mb-0.5 font-medium  text-black">
                                 {item?.depositDate}
                                    
                                  </p>
                                </th>
                                <td className="align-middle text-sm font-normal px-6 py-4 whitespace-nowrap  text-center">
                                  <span className=" text-base text-black  py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline   bg-green-200  rounded-full">
                                    {item?.name}
                                  </span>
                                </td>
                                <td className="text-sm font-normal text-center px-6 py-4 whitespace-nowrap">
                                  <span className=" text-base  text-black py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline   bg-green-200  rounded-full">
                                    {item?.cnic}
                                  </span>
                                </td>
                                <td className="align-middle text-center text-sm font-normal px-6 py-4 whitespace-nowrap text-left">
                                  <span className=" text-base text-black  py-1 px-2.5 leading-none  whitespace-nowrap    bg-green-200  rounded-full">
                                    {item?.depositType}
                                  </span>
                                </td>
                                <td className="align-middle text-center text-sm font-normal px-6 py-4 whitespace-nowrap text-left">
                                  <span className=" text-base text-black  py-1 px-2.5 leading-none  whitespace-nowrap    bg-green-200  rounded-full">
                                    {item?.receiptNumber}
                                  </span>
                                </td>

                                <td className="align-middle text-center text-sm font-normal px-6 py-4 whitespace-nowrap text-left">
                                  <span className=" text-base text-black  py-1 px-2.5 leading-none  whitespace-nowrap    bg-green-200  rounded-full">
                                    {item?.amount}
                                  </span>
                                </td>

                                <td className="align-middle text-center text-sm font-normal px-6 py-4 whitespace-nowrap text-left">
                                  <span className=" text-base text-black  py-1 px-2.5 leading-none  whitespace-nowrap    bg-green-200  rounded-full">
                                    {item?.bankName}
                                  </span>
                                </td>

                                <td className="align-middle text-center text-sm font-normal px-6 py-4 whitespace-nowrap text-left">
                                  <span className=" text-base text-black  py-1 px-2.5 leading-none  whitespace-nowrap    bg-green-200  rounded-full">
                                    {item?.branchName}
                                  </span>
                                </td>

                                <td className="align-middle  text-sm font-normal px-6 py-4 whitespace-nowrap">
                                  <span className=" text-base text-black  py-1 px-2.5 leading-none  whitespace-nowrap    bg-green-200  rounded-full">
                                    {item?.reference}
                                  </span>
                                </td>

                                <td className="align-middle  text-sm font-normal px-6 py-4 whitespace-nowrap">
                                  <span className=" text-base text-black  py-1 px-2.5 leading-none  whitespace-nowrap    bg-green-200  rounded-full">
                                    {item?.acknowledgeAuth}
                                  </span>
                                </td>
                                <td className="align-middle  text-sm font-normal px-6 py-4 whitespace-nowrap">
                                  <span className=" text-base text-black  py-1 px-2.5 leading-none  whitespace-nowrap    bg-green-200  rounded-full">
                                    {item?.acknowledgeDate}
                                  </span>
                                </td>
                                <td className="align-middle  text-sm font-normal px-6 py-4 whitespace-nowrap">
                                  <span className=" text-base text-black  py-1 px-2.5 leading-none  whitespace-nowrap    bg-green-200  rounded-full">
                                    {item?.remark}
                                  </span>
                                </td>

                                {/* <td className="align-middle  text-sm font-normal px-6 py-4 whitespace-nowrap">
                                  <span className=" text-base text-black  py-1 px-2.5 leading-none  whitespace-nowrap    bg-green-200  rounded-full">
                                    {item?.acknowledgeAuth}
                                  </span>
                                </td>

                                <td className="align-middle  text-sm font-normal px-6 py-4 whitespace-nowrap">
                                  <span className=" text-base text-black  py-1 px-2.5 leading-none  whitespace-nowrap    bg-green-200  rounded-full">
                                
                                    {moment(item?.acknowledgeDate)
                                    .format('D/M/YYYY')}
                                  </span>
                                </td> */}
                                <td className="align-middle cursor-pointer  text-sm font-normal px-6 py-4 whitespace-nowrap">
                                  <span
                                    onClick={() => {
                                      openModal2();
                                      setGetData(item);
                                    }}
                                    className=" text-base text-secondary  py-1 px-2.5 leading-none  whitespace-nowrap    bg-green-200  rounded-full"
                                  >
                                    {item?.slip?.slice(-6)}
                                  </span>
                                </td>
                              </tr>
                            </>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className=" flex justify-end items-center  py-4 gap-6">
            <button
              className={`${
                currentPage === 1
                  ? "bg-gray-500  text-white"
                  : "bg-secondary text-white"
              } flex justify-center items-center  w-10 h-10 rounded-md`}
              disabled={currentPage === 1}
              onClick={() => handlePageChange(currentPage - 1)}
            >
              <FaAngleLeft size={20} />
            </button>
            <span className="  text-primary  font-semibold">
              {currentPage} of{" "}
              {Math.ceil(filteredDeposits.length / itemsPerPage)}
            </span>
            <button
              className={`${
                currentPage * itemsPerPage >= filteredDeposits.length
                  ? "bg-gray-500  text-white"
                  : "bg-secondary text-white"
              } flex justify-center items-center  w-10 h-10 rounded-md`}
              disabled={currentPage * itemsPerPage >= filteredDeposits.length}
              onClick={() => handlePageChange(currentPage + 1)}
            >
              <FaAngleRight size={20} />
            </button>
          </div>
        </div>
      </section>
    </Worker_web_protal>
  );
};

export default Deposits;
