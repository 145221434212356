import React, { useState, useEffect } from "react";
import Modal from "../../../components/modal";
import { MdClose } from "react-icons/md";

const ViewUserSlip = ({ isModalOpen, setIsModalOpen, closeModal, getData }) => {
  return (
    <div>
      <Modal isOpen={isModalOpen} className={' md:w-[80%] w-full'} onClose={closeModal}>
        <div className="">
          <div className=" p-5">
            <div className="  flex justify-between items-center">
              <div></div>

              <MdClose
                className="  cursor-pointer text-gray-500"
                onClick={() => setIsModalOpen(false)}
                size={25}
              />
            </div>
            <div className="">
                <img src={getData?.images} alt="" />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ViewUserSlip;
