import React, { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { VscFilter } from "react-icons/vsc";
import { GoTriangleDown } from "react-icons/go";
import { FiPlus } from "react-icons/fi";
import Worker_web_protal from "../../../layout/Worker_web_portal";
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import { Base_url } from "../../../utils/Base_url";
import { IoSearchOutline } from "react-icons/io5";
import Manager_web_portal from "../../../layout/Manager_web_portal";
import { HiOutlineArrowLeft } from "react-icons/hi";
import { useAllinvestmentsQuery } from "../../../store/services/investmentService";
import ViewSlip from "./ViewSlip";
import { toast } from "react-toastify";
import Super_admin_web_protal from "../../../layout/Super_admin_web_portal";
import { PiPencilSimpleFill } from "react-icons/pi";
import Edit_collection from "./Edit_collection";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import moment from "moment";
import { RiDeleteBin5Line } from "react-icons/ri";
const Collection = () => {
  const { data, isFetching } = useAllinvestmentsQuery();

  console.log(data);
  const [searchTerm, setSearchTerm] = useState("");
  const [minValue, setMinValue] = useState("");
  const [maxValue, setMaxValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [filteredDeposits, setFilteredDeposits] = useState([]);

  const [getData, setGetData] = useState({});
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [fetchUser, setFetchUser] = useState({});
  const openModal2 = () => {
    setIsModalOpen2(true);
  };

  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    filterDeposits();
  }, [data, searchTerm, minValue, maxValue]);

  const toggleDropdown = () => setDropdownVisible(!dropdownVisible);

  const filterDeposits = () => {
    let filtered = data?.investments?.slice()?.reverse() || [];
    if (searchTerm) {
      filtered = filtered.filter(
        (deposit) =>
          deposit._id.toLowerCase().includes(searchTerm.toLowerCase()) ||
        deposit.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          deposit.cnic.toLowerCase().includes(searchTerm.toLowerCase()) 
      );
    }
    if (minValue || maxValue) {
      const min = parseFloat(minValue) || Number.MIN_SAFE_INTEGER;
      const max = parseFloat(maxValue) || Number.MAX_SAFE_INTEGER;
      filtered = filtered.filter((deposit) => {
        const amount = parseFloat(deposit.amount);
        return amount >= min && amount <= max;
      });
    }
    setFilteredDeposits(filtered);
    setCurrentPage(1); // Reset to the first page after filtering
  };

  const currentPageData = filteredDeposits.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (newPage) => setCurrentPage(newPage);

  const openModalWithItem = (item) => {
    setSelectedItem(item);
    setIsModalOpen(true);
  };

  const removeFunction = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#A47ABF",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .post(`${Base_url}/v1/admin/delete-deposit/${id}`)
          .then((res) => {
            console.log(res);
            if (res.status === 200) {
              Swal.fire("Deleted!", "Your file has been deleted.", "success");
              window.location.reload();
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  };

  return (
    <Super_admin_web_protal
      language={
        <>
          <p className=" sm:block hidden">English | Urdu</p>
        </>
      }
      ToggleBtn={
        <div className=" flex gap-4 items-center">
          <HiOutlineArrowLeft />
          <h2 className="">Collection</h2>
        </div>
      }
      title_props={
        <>
          <div className=" w-96 relative">
            <Input
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder={""}
              className={" border bg-white w-full rounded-full"}
            />

            <Button
              Icon={<IoSearchOutline className=" text-white text-xl" />}
              label={"search"}
              className={
                " absolute top-0 right-0 bg-secondary  text-white uppercase font-normal rounded-full"
              }
            />
          </div>
        </>
      }
    >
      <Edit_collection
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        getData={fetchUser}
      />

      <div className="  w-full  mb-4  md:hidden block relative">
        <Input
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder={""}
          className={" border bg-white w-full rounded-full"}
        />

        <Button
          Icon={<IoSearchOutline className=" text-white text-xl" />}
          label={"search"}
          className={
            " absolute top-0 right-0 bg-secondary text-white uppercase font-normal rounded-full"
          }
        />
      </div>
      <div className=" sm:flex block relative    justify-between items-center">
        <div>
          <h2 className=" sm:block hidden">Collection</h2>
        </div>
        <ViewSlip
          isModalOpen={isModalOpen2}
          setIsModalOpen={setIsModalOpen2}
          getData={getData}
        />

        <div className=" ">
          <Button
            Icon={<VscFilter size={27} />}
            Icons={<GoTriangleDown />}
            label={"Select Filters"}
            onClick={toggleDropdown}
            className={" bg-white font-normal text-darkGray py-3 rounded-full"}
          />
          {dropdownVisible && (
            <div className="absolute z-10 p-3 mt-2 w-80 rounded-md shadow-lg bg-white right-0 ring-black ring-opacity-5">
              <div className="py-1 gap-4 flex">
                <div className=" py-2">
                  <label
                    htmlFor="minValue"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Min Value
                  </label>
                  <Input
                    type="number"
                    id="minValue"
                    value={minValue}
                    onChange={(e) => setMinValue(e.target.value)}
                    className="mt-1 p-2 w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
                <div className=" py-2">
                  <label
                    htmlFor="maxValue"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Max Value
                  </label>
                  <Input
                    type="number"
                    id="maxValue"
                    value={maxValue}
                    onChange={(e) => setMaxValue(e.target.value)}
                    className="mt-1 p-2 w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
              </div>

              {/* <Button
                onClick={filterDeposits}
                label={"Search"}
                className={
                  "  bg-secondary rounded-full w-full justify-center text-white"
                }
              /> */}
            </div>
          )}
        </div>
      </div>
      <section className="mb-20 mt-7 text-gray-800">
        <div className="block rounded-lg ">
          <div className="flex overflow-hidden flex-col">
            <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full sm:px-6 lg:px-8">
                <div className="overflow-hidden">
                  <table className="min-w-full mb-0">
                    <thead className=" ">
                      <tr className=" rounded-lg whitespace-nowrap ">
                        <th
                          scope="col"
                          className=" text-sm  text-darkGray  font-medium px-6"
                        >
                          Deposit Date
                        </th>
                        <th
                          scope="col"
                          className=" text-sm text-darkGray    font-medium px-6 "
                        >
                          Name
                        </th>
                        <th
                          scope="col"
                          className=" text-sm text-darkGray   font-medium px-6 "
                        >
                          CNIC No
                        </th>

                        <th
                          scope="col"
                          className="text-sm  text-darkGray   font-medium px-6 "
                        >
                          Type
                        </th>

                        <th
                          scope="col"
                          className="text-sm  text-darkGray     font-medium px-6 "
                        >
                          Receipt No
                        </th>
                        <th
                          scope="col"
                          className="text-sm    text-darkGray   font-medium px-6 "
                        >
                          Amount
                        </th>

                        <th
                          scope="col"
                          className="text-sm    text-darkGray   font-medium px-6 "
                        >
                          Bank Name
                        </th>

                        <th
                          scope="col"
                          className="text-sm    text-darkGray   font-medium px-6 "
                        >
                          Branch
                        </th>

                        <th
                          scope="col"
                          className="text-sm    text-darkGray   font-medium px-6 "
                        >
                          Reference
                        </th>

                        <th
                          scope="col"
                          className="text-sm    text-darkGray   font-medium px-6 "
                        >
                          Remarks
                        </th>

                        <th
                          scope="col"
                          className="text-sm    text-darkGray   font-medium px-6 "
                        >
                          Ack. Auth.
                        </th>

                        <th
                          scope="col"
                          className="text-sm    text-darkGray   font-medium px-6 "
                        >
                          Ack Date
                        </th>

                        <th
                          scope="col"
                          className="text-sm    text-darkGray   font-medium px-6 "
                        >
                          Slip
                        </th>
                      </tr>
                    </thead>
                    <tbody className="">
                      {currentPageData?.map((item, index) => {
                        return (
                          <>
                            <tr className="bg-white border-8    border-[#F6F6F6]  rounded-md ">
                              <th
                                scope="row"
                                className="text-sm font-normal px-6 py-4   whitespace-nowrap "
                              >
                                <p className="mb-0.5 font-medium  text-secondary">
                                  {moment(item?.depositDate).format(
                                    "DD/MM/YYYY"
                                  )}
                                </p>
                              </th>
                              <td className="align-middle text-sm font-normal px-6 py-4 whitespace-nowrap  text-center">
                                <span className=" text-base text-black  py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline   bg-green-200  rounded-full">
                                  {item?.name}
                                </span>
                              </td>
                              <td className="text-sm font-normal text-center px-6 py-4 whitespace-nowrap">
                                <span className=" text-base  text-black py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline   bg-green-200  rounded-full">
                                  {item?.cnic}
                                </span>
                              </td>
                              <td className="align-middle text-center text-sm font-normal px-6 py-4 whitespace-nowrap text-left">
                                <span className=" text-base text-black  py-1 px-2.5 leading-none  whitespace-nowrap    bg-green-200  rounded-full">
                                  {item?.depositType}{" "}
                                </span>
                              </td>
                              <td className="align-middle text-center text-sm font-normal px-6 py-4 whitespace-nowrap text-left">
                                <span className=" text-base text-black  py-1 px-2.5 leading-none  whitespace-nowrap    bg-green-200  rounded-full">
                                  {item?.receiptNumber}
                                </span>
                              </td>

                              <td className="align-middle  text-sm font-normal px-6 py-4 whitespace-nowrap">
                                <span className=" text-base text-black  py-1 px-2.5 leading-none  whitespace-nowrap    bg-green-200  rounded-full">
                                  {item?.amount}
                                </span>
                              </td>
                              <td className="align-middle  text-sm font-normal px-6 py-4 whitespace-nowrap">
                                <span className=" text-base text-black  py-1 px-2.5 leading-none  whitespace-nowrap    bg-green-200  rounded-full">
                                  {item?.bankName}
                                </span>
                              </td>
                              <td className="align-middle  text-sm font-normal px-6 py-4 whitespace-nowrap">
                                <span className=" text-base text-black  py-1 px-2.5 leading-none  whitespace-nowrap    bg-green-200  rounded-full">
                                  {item?.branchName}
                                </span>
                              </td>
                              <td className="align-middle  text-sm font-normal px-6 py-4 whitespace-nowrap">
                                <span className=" text-base text-black  py-1 px-2.5 leading-none  whitespace-nowrap    bg-green-200  rounded-full">
                                  {item?.reference}
                                </span>
                              </td>

                              <td className="align-middle  text-sm font-normal px-6 py-4 whitespace-nowrap">
                                <span className=" text-base text-black  py-1 px-2.5 leading-none  whitespace-nowrap    bg-green-200  rounded-full">
                                  {item?.remark}
                                </span>
                              </td>

                              <td className="align-middle  text-sm font-normal px-6 py-4 whitespace-nowrap">
                                <span className=" text-base text-black  py-1 px-2.5 leading-none  whitespace-nowrap    bg-green-200  rounded-full">
                                  {item?.acknowledgeAuth}
                                </span>
                              </td>
                              <td className="align-middle  text-sm font-normal px-6 py-4 whitespace-nowrap">
                                <span className=" text-base text-black  py-1 px-2.5 leading-none  whitespace-nowrap    bg-green-200  rounded-full">
                                  {item?.acknowledgeDate}
                                </span>
                              </td>

                              <td className="align-middle  cursor-pointer text-sm font-normal px-6 py-4 whitespace-nowrap">
                                <span
                                  onClick={() => {
                                    openModal2();
                                    setGetData(item);
                                  }}
                                  className=" text-base  text-secondary  py-1 px-2.5 leading-none  whitespace-nowrap    bg-green-200  rounded-full"
                                >
                                  {item?.slip.slice(-6)}{" "}
                                </span>
                              </td>

                              {/* <td className="align-middle  cursor-pointer text-sm font-normal px-6 py-4 whitespace-nowrap">
                                {item?.acknowledgeStatus === true ? (
                                  <span  className=" text-base cursor-not-allowed   text-green bg-lightGray   py-2 px-4 leading-none  whitespace-nowrap    bg-green-200  rounded-full">
                                    Acknowledged{" "}
                                  </span>
                                ) : (
                                  <span
                                    onClick={() => add_acknowledge(item?._id)}
                                    className=" text-base   text-red bg-[#fdeced]  py-2 px-4 leading-none  whitespace-nowrap    bg-green-200  rounded-full"
                                  >
                                    Acknowledge{" "}
                                  </span>
                                )}
                              </td> */}
                              <td className="align-middle  text-sm font-normal px-6  whitespace-nowrap">
                                <span
                                  onClick={() => {
                                    openModal();
                                    setFetchUser(item);
                                  }}
                                  className=" text-base text-black  py-1 px-2.5 leading-none  whitespace-nowrap    bg-green-200  rounded-full"
                                >
                                  <PiPencilSimpleFill className=" text-primary" />
                                </span>
                              </td>
                              <td className="align-middle  cursor-pointer text-sm font-normal px-6  whitespace-nowrap">
                                <span
                                  onClick={() => removeFunction(item?._id)}
                                  className=" text-base text-black   leading-none  cursor-pointer  whitespace-nowrap    bg-green-200  rounded-full"
                                >
                                  <RiDeleteBin5Line className=" text-[#d33]" />
                                </span>
                              </td>
                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className=" flex justify-end items-center  py-4 gap-6">
              <button
                className={`${
                  currentPage === 1
                    ? "bg-gray-500  text-white"
                    : "bg-secondary text-white"
                } flex justify-center items-center  w-10 h-10 rounded-md`}
                disabled={currentPage === 1}
                onClick={() => handlePageChange(currentPage - 1)}
              >
                <FaAngleLeft size={20} />
              </button>
              <span className="  text-primary  font-semibold">
                {currentPage} of{" "}
                {Math.ceil(filteredDeposits.length / itemsPerPage)}
              </span>
              <button
                className={`${
                  currentPage * itemsPerPage >= filteredDeposits.length
                    ? "bg-gray-500  text-white"
                    : "bg-secondary text-white"
                } flex justify-center items-center  w-10 h-10 rounded-md`}
                disabled={currentPage * itemsPerPage >= filteredDeposits.length}
                onClick={() => handlePageChange(currentPage + 1)}
              >
                <FaAngleRight size={20} />
              </button>
            </div>
          </div>
        </div>
      </section>
    </Super_admin_web_protal>
  );
};

export default Collection;
