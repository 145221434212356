import React, { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { VscFilter } from "react-icons/vsc";
import { GoTriangleDown } from "react-icons/go";
import { FiPlus } from "react-icons/fi";
import Worker_web_protal from "../../../layout/Worker_web_portal";
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import { Base_url } from "../../../utils/Base_url";
import { IoSearchOutline } from "react-icons/io5";
import Manager_web_portal from "../../../layout/Manager_web_portal";
import { HiOutlineArrowLeft } from "react-icons/hi";
import { useAllinvestmentsQuery } from "../../../store/services/investmentService";
import ViewSlip from "./ViewSlip";
import { toast } from "react-toastify";
import moment from "moment";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
const Collection = () => {
  const { data, isFetching, refetch} = useAllinvestmentsQuery();
  const [searchTerm, setSearchTerm] = useState("");
  const [minValue, setMinValue] = useState("");
  const [maxValue, setMaxValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [statusFilter, setStatusFilter] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [filteredDeposits, setFilteredDeposits] = useState([]);
  const manager_data = JSON.parse(localStorage.getItem("manager_data"));
  const [getData, setGetData] = useState({});
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [loading, setLoading] = useState(null);
  const openModal2 = () => {
    setIsModalOpen2(true);
  };

  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  useEffect(() => {
    filterDeposits();
  }, [data, searchTerm, minValue, maxValue,statusFilter]);

  const toggleDropdown = () => setDropdownVisible(!dropdownVisible);
  console.log(data?.investments);

  const filterDeposits = () => {
    let filtered = data?.investments?.slice()?.reverse() || [];
    if (searchTerm.trim()) {
      const lowercasedSearchTerm = searchTerm.toLowerCase();
  
      filtered = filtered.filter((val) => {
        // Convert the depositDate to DD/MM/YYYY
        const depositDate = new Date(val?.depositDate).toLocaleDateString("en-GB");
        
        const depositDateMatch = depositDate
          ?.toLowerCase()
          .includes(lowercasedSearchTerm);
        const nameMatch = val?.name?.toLowerCase().includes(lowercasedSearchTerm);
        const cnicMatch = val?.cnic?.toLowerCase().includes(lowercasedSearchTerm);
  
        return depositDateMatch || nameMatch || cnicMatch;
      });
    }
    if (minValue || maxValue) {
      const min = parseFloat(minValue) || Number.MIN_SAFE_INTEGER;
      const max = parseFloat(maxValue) || Number.MAX_SAFE_INTEGER;
      filtered = filtered.filter((deposit) => {
        const amount = parseFloat(deposit.amount);
        return amount >= min && amount <= max;
      });
    }

    if (statusFilter) {
      setDropdownVisible(false)
      filtered = filtered.filter((deposit) => {
        if (statusFilter === "true") {
          return deposit.acknowledgeStatus === true;
        } else if (statusFilter === "false") {
          return deposit.acknowledgeStatus === false;
        }
        return true; // For "All" or no status selected
      });
    }


    setFilteredDeposits(filtered);
    setCurrentPage(1);
  };

  const currentPageData = filteredDeposits.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (newPage) => setCurrentPage(newPage);

  const openModalWithItem = (item) => {
    setSelectedItem(item);
    setIsModalOpen(true);
  };

  const add_acknowledge = (id) => {
    setLoading(id);
    axios
      .get(
        `https://welcome-habibi-backend.vercel.app/v1/worker/single-investor/${id}`
      )
      .then((respose) => {
        console.log(respose);

        if (respose?.data?.success === true) {
          const params = {
            acknowledgeAuth: manager_data?.name,
            acknowledgeDate: moment(Date.now()).format("L"),
            acknowledgeStatus:true,
          };

          axios
            .post(`${Base_url}/v1/manager/update-investments/${id}`, params)
            .then((res) => {
              console.log(res);
              if (res?.data?.success === true) {
                const params = {
                  plan: respose?.data?.investment?.planId?._id,
                  amount: respose?.data?.investment?.amount,
                  workerId: respose?.data?.investment?.createdBy?._id,
                };

                axios
                  .post(
                    `${Base_url}/v1/manager/worker-notification-after-ack`,
                    params
                  )
                  .then((res) => {
                    console.log(res);
                    if (res?.data?.success === true) {
                      const urlencoded = new URLSearchParams();
                      urlencoded.append(
                        "date",
                        moment(respose?.data?.investment?.depositDate).format(
                          "DD-MM-YYYY"
                        )
                      );
                      urlencoded.append(
                        "amount",
                        respose?.data?.investment?.amount
                      );
                      urlencoded.append(
                        "plan",
                        respose?.data?.investment?.planId?.planName
                      );
                      urlencoded.append(
                        "reciepient",
                        `+${respose?.data?.investor?.contactInfo}`
                      );
                      urlencoded.append("status", "approved");
                      urlencoded.append(
                        "receiptId",
                        respose?.data?.investment?.receiptNumber
                      );
                      urlencoded.append(
                        "workerName",
                        respose?.data?.investment?.createdBy?.name
                      );
                      axios
                        .post(
                          "https://whatsapp-api-nodejs-smoky.vercel.app/sendReciept",
                          urlencoded
                        )
                        .then((response) => {
                          console.log(response);
                          if (response?.data?.status === "ok") {
                            toast.success(
                              "Acknowledgment created and notification sent."
                            );

                            setLoading(null);
                            // window.location.reload();
                            refetch();
                          } else {
                          }
                        })
                        .catch((error) => {
                          console.error(error);
                        });
                    } else {
                    }
                  })
                  .catch((res) => {
                    console.log(res);
                  });
              } else {
                toast.success(res?.data?.message);
              }
            })
            .catch((error) => {});
        } else {
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Manager_web_portal
      language={
        <>
          <p className=" sm:block hidden">English | Urdu</p>
        </>
      }
      ToggleBtn={
        <div className=" flex gap-4 items-center">
          <HiOutlineArrowLeft />
          <h2 className="">Collection</h2>
        </div>
      }
      title_props={
        <>
          <div className=" w-96 relative">
            <Input
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder={""}
              className={" border bg-white w-full rounded-full"}
            />

            <Button
              Icon={<IoSearchOutline className=" text-white text-xl" />}
              label={"search"}
              className={
                " absolute top-0 right-0 bg-secondary  text-white uppercase font-normal rounded-full"
              }
            />
          </div>
        </>
      }
    >
      <div className="  w-full  mb-4  md:hidden block relative">
        <Input
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder={""}
          className={" border bg-white w-full rounded-full"}
        />

        <Button
          Icon={<IoSearchOutline className=" text-white text-xl" />}
          label={"search"}
          className={
            " absolute top-0 right-0 bg-secondary text-white uppercase font-normal rounded-full"
          }
        />
      </div>
      <div className=" sm:flex block relative    justify-between items-center">
        <div>
          <h2 className=" sm:block hidden">Collection</h2>
        </div>
        <ViewSlip
          isModalOpen={isModalOpen2}
          setIsModalOpen={setIsModalOpen2}
          getData={getData}
        />

        <div className=" ">
          <Button
            Icon={<VscFilter size={27} />}
            Icons={<GoTriangleDown />}
            label={"Select Filters"}
            onClick={toggleDropdown}
            className={" bg-white font-normal text-darkGray py-3 rounded-full"}
          />
          {dropdownVisible && (
            <div className="absolute z-10 p-3 mt-2 w-96 rounded-md shadow-lg bg-white right-0 ring-black ring-opacity-5">
              <div className="py-1 gap-4 flex">
                <div className=" py-2">
                  <label
                    htmlFor="minValue"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Min Value
                  </label>
                  <Input
                    type="number"
                    id="minValue"
                    value={minValue}
                    onChange={(e) => setMinValue(e.target.value)}
                    className="mt-1 p-2 w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
                <div className=" py-2">
                  <label
                    htmlFor="maxValue"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Max Value
                  </label>
                  <Input
                    type="number"
                    id="maxValue"
                    value={maxValue}
                    onChange={(e) => setMaxValue(e.target.value)}
                    className="mt-1 p-2 w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
                <div className="py-2">
                  <label
                      htmlFor="maxValue"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Status
                    </label>
                    <select
                      id="status"
                      className="mt-2.5 p-2 bg-[#F6F6F6] rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      onChange={(e) => setStatusFilter(e.target.value)} 
                      value={statusFilter}
                    >
                      <option value="">All</option>
                      <option value={true}>Acknowledged</option>
                      <option value={false}>Acknowledge</option>
                    </select>
                  </div>
              </div>

              {/* <Button
                onClick={handleSearch}
                label={"Search"}
                className={
                  "  bg-secondary rounded-full w-full justify-center text-white"
                }
              /> */}
            </div>
          )}
        </div>
      </div>
      <section className="mb-20 mt-7 text-gray-800">
        <div className="block rounded-lg ">
          <div className="flex overflow-hidden flex-col">
            <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full sm:px-6 lg:px-8">
                <div className="overflow-hidden">
                  <table className="min-w-full mb-0">
                    <thead className=" ">
                      <tr className=" rounded-lg whitespace-nowrap ">
                        <th
                          scope="col"
                          className=" text-sm  text-darkGray  font-medium px-6"
                        >
                          Deposit Date
                        </th>
                        <th
                          scope="col"
                          className=" text-sm text-darkGray    font-medium px-6 "
                        >
                          Name
                        </th>
                        <th
                          scope="col"
                          className=" text-sm text-darkGray   font-medium px-6 "
                        >
                          CNIC No
                        </th>

                        <th
                          scope="col"
                          className="text-sm  text-darkGray   font-medium px-6 "
                        >
                          Type
                        </th>

                        <th
                          scope="col"
                          className="text-sm  text-darkGray     font-medium px-6 "
                        >
                          Receipt No
                        </th>
                        <th
                          scope="col"
                          className="text-sm    text-darkGray   font-medium px-6 "
                        >
                          Amount
                        </th>

                        <th
                          scope="col"
                          className="text-sm    text-darkGray   font-medium px-6 "
                        >
                          Bank Name
                        </th>

                        <th
                          scope="col"
                          className="text-sm    text-darkGray   font-medium px-6 "
                        >
                          Branch
                        </th>

                        <th
                          scope="col"
                          className="text-sm    text-darkGray   font-medium px-6 "
                        >
                          Reference
                        </th>

                        <th
                          scope="col"
                          className="text-sm    text-darkGray   font-medium px-6 "
                        >
                          Remarks
                        </th>

                        <th
                          scope="col"
                          className="text-sm    text-darkGray   font-medium px-6 "
                        >
                          Ack. Auth.
                        </th>

                        <th
                          scope="col"
                          className="text-sm    text-darkGray   font-medium px-6 "
                        >
                          Ack Date
                        </th>

                        <th
                          scope="col"
                          className="text-sm    text-darkGray   font-medium px-6 "
                        >
                          Slip
                        </th>
                      </tr>
                    </thead>
                    <tbody className="">
                      {currentPageData?.map((item, index) => {
                        return (
                          <>
                            <tr className="bg-white border-8    border-[#F6F6F6]  rounded-md ">
                              <th
                                scope="row"
                                className="text-sm font-normal px-6 py-4   whitespace-nowrap "
                              >
                                <p className="mb-0.5 font-medium  text-secondary">
                                  {moment(item?.depositDate).format(
                                    "DD/MM/YYYY"
                                  )}
                                </p>
                              </th>
                              <td className="align-middle text-sm font-normal px-6 py-4 whitespace-nowrap  text-center">
                                <span className=" text-base text-black  py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline   bg-green-200  rounded-full">
                                  {item?.name}
                                </span>
                              </td>
                              <td className="text-sm font-normal text-center px-6 py-4 whitespace-nowrap">
                                <span className=" text-base  text-black py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline   bg-green-200  rounded-full">
                                  {item?.cnic}
                                </span>
                              </td>
                              <td className="align-middle text-center text-sm font-normal px-6 py-4 whitespace-nowrap text-left">
                                <span className=" text-base text-black  py-1 px-2.5 leading-none  whitespace-nowrap    bg-green-200  rounded-full">
                                  {item?.depositType}{" "}
                                </span>
                              </td>
                              <td className="align-middle text-center text-sm font-normal px-6 py-4 whitespace-nowrap text-left">
                                <span className=" text-base text-black  py-1 px-2.5 leading-none  whitespace-nowrap    bg-green-200  rounded-full">
                                  {item?.receiptNumber}
                                </span>
                              </td>

                              <td className="align-middle  text-sm font-normal px-6 py-4 whitespace-nowrap">
                                <span className=" text-base text-black  py-1 px-2.5 leading-none  whitespace-nowrap    bg-green-200  rounded-full">
                                  {item?.amount}
                                </span>
                              </td>
                              <td className="align-middle  text-sm font-normal px-6 py-4 whitespace-nowrap">
                                <span className=" text-base text-black  py-1 px-2.5 leading-none  whitespace-nowrap    bg-green-200  rounded-full">
                                  {item?.bankName}
                                </span>
                              </td>
                              <td className="align-middle  text-sm font-normal px-6 py-4 whitespace-nowrap">
                                <span className=" text-base text-black  py-1 px-2.5 leading-none  whitespace-nowrap    bg-green-200  rounded-full">
                                  {item?.branchName}
                                </span>
                              </td>
                              <td className="align-middle  text-sm font-normal px-6 py-4 whitespace-nowrap">
                                <span className=" text-base text-black  py-1 px-2.5 leading-none  whitespace-nowrap    bg-green-200  rounded-full">
                                  {item?.reference}
                                </span>
                              </td>

                              <td className="align-middle  text-sm font-normal px-6 py-4 whitespace-nowrap">
                                <span className=" text-base text-black  py-1 px-2.5 leading-none  whitespace-nowrap    bg-green-200  rounded-full">
                                  {item?.remark}
                                </span>
                              </td>

                              <td className="align-middle  text-sm font-normal px-6 py-4 whitespace-nowrap">
                                <span className=" text-base text-black  py-1 px-2.5 leading-none  whitespace-nowrap    bg-green-200  rounded-full">
                                  {item?.acknowledgeAuth}
                                </span>
                              </td>
                              <td className="align-middle  text-sm font-normal px-6 py-4 whitespace-nowrap">
                                <span className=" text-base text-black  py-1 px-2.5 leading-none  whitespace-nowrap    bg-green-200  rounded-full">
                                  {moment(item?.acknowledgeDate).format(
                                    "DD/MM/YYYY"
                                  )}
                                </span>
                              </td>

                              <td className="align-middle  cursor-pointer  text-sm font-normal px-6 py-4 whitespace-nowrap">
                                <span
                                  onClick={() => {
                                    openModal2();
                                    setGetData(item);
                                  }}
                                  className=" text-base  text-secondary  py-1 px-2.5 leading-none  whitespace-nowrap    bg-green-200  rounded-full"
                                >
                                  {item?.slip.slice(-6)}{" "}
                                </span>
                              </td>

                              <td className="align-middle  cursor-pointer text-sm font-normal px-6 py-4 whitespace-nowrap">
                                {item?.acknowledgeStatus === true ? (
                                  <span className=" text-base cursor-not-allowed   text-green bg-lightGray   py-2 px-4 leading-none  whitespace-nowrap    bg-green-200  rounded-full">
                                    Acknowledged{" "}
                                  </span>
                                ) : (
                                  <span
                                    onClick={() => add_acknowledge(item?._id)}
                                    className=" text-base  flex   items-center gap-2  text-red bg-[#fdeced]  py-3 px-4 leading-none  whitespace-nowrap    bg-green-200  rounded-full"
                                  >
                                    Acknowledge
                                    {loading===item?._id? (
                                      <svg
                                        className="animate-spin h-5 w-5 text-gray-700"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                      >
                                        <circle
                                          className="opacity-25"
                                          cx="12"
                                          cy="12"
                                          r="10"
                                          stroke="currentColor"
                                          strokeWidth="4"
                                        />
                                        <path
                                          className="opacity-75"
                                          fill="currentColor"
                                          d="M4 12a8 8 0 018-8v8H4z"
                                        />
                                      </svg>
                                    ) : (
                                      ""
                                    )}
                                  </span>
                                )}
                              </td>
                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className=" flex justify-end items-center  py-4 gap-6">
            <button
              className={`${
                currentPage === 1
                  ? "bg-gray-500  text-white"
                  : "bg-secondary text-white"
              } flex justify-center items-center  w-10 h-10 rounded-md`}
              disabled={currentPage === 1}
              onClick={() => handlePageChange(currentPage - 1)}
            >
              <FaAngleLeft size={20} />
            </button>
            <span className="  text-primary  font-semibold">
              {currentPage} of{" "}
              {Math.ceil(filteredDeposits.length / itemsPerPage)}
            </span>
            <button
              className={`${
                currentPage * itemsPerPage >= filteredDeposits.length
                  ? "bg-gray-500  text-white"
                  : "bg-secondary text-white"
              } flex justify-center items-center  w-10 h-10 rounded-md`}
              disabled={currentPage * itemsPerPage >= filteredDeposits.length}
              onClick={() => handlePageChange(currentPage + 1)}
            >
              <FaAngleRight size={20} />
            </button>
          </div>
        </div>
      </section>
    </Manager_web_portal>
  );
};

export default Collection;
