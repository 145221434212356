import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Base_url } from "../../../utils/Base_url";
import Datepicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import Input from "../../../components/Input";
import Button from "../../../components/Button";
import Modal from "../../../components/modal";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { MdClose } from "react-icons/md";
import { setSuccess } from "../../../store/reducers/globalReducer";
import { useForm } from "../../../hooks/Form";
import { useCreateMutation } from "../../../store/services/profitDeductionService";
import moment from "moment";
const AddProfits_and_deductions = ({
  isModalOpen,
  setIsModalOpen,
  closeModal,
  setUsers,
}) => {
  const [value, setValue] = useState("");

  const handleValueChange = (newValue) => {
    console.log("newValue:", newValue);
    setValue(newValue);
  };
  const manager_data = JSON.parse(localStorage.getItem('manager_data'))
  const [loading, setLaoder] = useState(false);

  const { state, onChange } = useForm({
    investmentId: "",
    deductionType: "",
    deductionAmount: "",
    profitAmount: "",
    remarks: "",
  });


  const [startDate, setStartDate] = useState(new Date());

  const handleDateChange = (date) => {
    setStartDate(date);
    console.log(date);
  };

  useEffect(() => {
    console.log(moment(startDate).format("YYYY-MM-DD"));
  }, [startDate]);

  const [create, response] = useCreateMutation();
  const onSubmit = async (e) => {
    e.preventDefault();

    if (state.investmentId.length === 0) {
      toast.error("Must enter investmentId");
    } else {
      setLaoder(true);

      const dataToSend = {
        investmentId: state.investmentId,
        profitAmount: state.profitAmount,
        remarks: state.remarks,
        managerName: manager_data?.name,
      };
      
      if (state.deductionAmount) {
        dataToSend.deductionAmount = state.deductionAmount;
        
      }

      if(state.deductionType){
        dataToSend.deductionType = state.deductionType;
      }

      create(dataToSend);

    }
  };
  useEffect(() => {
    if (response.isError) {
      toast.error(response?.error?.data?.message);
      setLaoder(false);
    }
  }, [response?.error?.data]);
  console.log(response);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (response.isSuccess) {
      if (response?.data?.success === true) {
        dispatch(setSuccess(response?.data?.message));
        toast.success(response?.data?.message);
        setLaoder(false);
        setIsModalOpen(false);

        window.location.reload()
      } else {
        toast.error(response?.data?.message);
        setLaoder(false);
      }
    }
  }, [response.isSuccess]);

  return (
    <div>
      <Modal
        className={" md:w-[40%] w-[100%]"}
        isOpen={isModalOpen}
        onClose={closeModal}
      >
        <div className="">
          <div className=" p-3 flex justify-between items-center">
            <div></div>

            <MdClose
              className="  text-gray-500"
              onClick={() => setIsModalOpen(false)}
              size={25}
            />
          </div>

          <div className=" p-5">
            <form onSubmit={onSubmit}>
              <div className=" p-5 flex gap-3 flex-wrap">
              <div className=" w-full flex flex-col">
                    <label className=" pb-3 text-lg  font-medium">
                       Date
                    </label>
                   
                    <div>
                   
                    <div className=" w-full relative">
                    <Datepicker
                      selected={startDate}
                      onChange={handleDateChange}
                      minDate={new Date()}
                      className="bg-lightGray  w-full rounded-md p-4 "
                      
                    />
                    <div className=" absolute right-4 top-4">
                    <img src={require('../../../assets/image/Calendar.png')} />
                    </div>
                    </div>
                    </div>
                  </div>

                <div className=" w-full">
                  <Input
                    onChange={onChange}
                    value={state.investmentId}
                    label={"Investment ID"}
                    placeholder={"Enter Investment ID"}
                    name={"investmentId"}
                    className={" w-full  py-4"}
                  />
                </div>

                <div className=" w-full">
                  <label
                    for="first_name"
                    className=" block mb-2 text-[18px]  font-medium text-black"
                  >
                    Deduction Type
                  </label>
                  <select
                    onChange={onChange}
                    value={state.deductionType}
                    name="deductionType"
                    className=" py-4 font-medium w-full rounded border-r-8   bg-lightGray outline-none  border-transparent px-4 text-md"
                  >
                    <option>Select..</option>
                    <option value={"Processing fee"}>Processing fee</option>
                    <option value={"Transaction fee"}>Transaction fee</option>
                    <option value={"Business expenses"}>
                      Business expenses
                    </option>
                    <option value={"Tax"}>Tax</option>
                    <option value={"Others"}>Others</option>
                   
                  </select>
                </div>

                <div className=" w-full">
                  <Input
                    onChange={onChange}
                    value={state.deductionAmount}
                    Icon={"PKR"}
                    label={"Deduction Amount"}
                    placeholder={"Enter Amount"}
                    name={"deductionAmount"}
                    className={" w-full  py-4"}
                  />
                </div>

                <div className=" w-full">
                  <Input
                    onChange={onChange}
                    value={state.profitAmount}
                    Icon={"PKR"}
                    label={" Profit"}
                    placeholder={"Enter Amount"}
                    name={"profitAmount"}
                    className={" w-full  py-4"}
                  />
                </div>

                <div className=" w-full">
                  <Input
                    onChange={onChange}
                    value={state.remarks}
                    name={"remarks"}
                    label={"Remarks"}
                    placeholder={"Write Anything"}
                    className={" w-full  py-4"}
                  />
                </div>

                {loading === true ? (
                  <button
                    disabled
                    type="button"
                    class="  bg-blue text-center  mt-3 justify-center uppercase text-white py-3 border-b-black  border-2 w-full"
                  >
                    <svg
                      aria-hidden="true"
                      role="status"
                      class="inline w-4 h-4 me-3 text-white animate-spin"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="#E5E7EB"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentColor"
                      />
                    </svg>
                    Loading...
                  </button>
                ) : (
                  <Button
                    label={"Add"}
                    type={"submit"}
                    className={
                      "       bg-blue text-center  mt-3 justify-center uppercase text-white py-3 border-b-black  border-2 w-full"
                    }
                  />
                )}
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AddProfits_and_deductions;
