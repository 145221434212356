import { useState } from "react";
import SuperAdminSidebar from "../components/sidebar/superAdminSidebar/SuperAdminSidebar";
import SuperAdminNav from "../components/navbar/superAdminNav";
const Super_admin_web_protal = ({ children, title_props, ToggleBtn,language}) => {
  const [side, setSide] = useState("-left-64 md:left-0");
  console.log(side);
  const openSidebar = () => {
    setSide("left-0 md:-left-64");
  };
  const closeSidebar = () => {
    setSide("-left-64 md:left-0");
  };

  return (
    <>
      <SuperAdminSidebar side={side} closeSidebar={closeSidebar} />
      <SuperAdminNav
        title_props={title_props}
        openSidebar={openSidebar}
        closeSidebar={closeSidebar}
        ToggleBtn={ToggleBtn}
        language={language}
        
        side={side}
      />
      <section
        className={`ml-0 ${
          side === "left-0 md:-left-64" ? "ml-0 " : "ml-0 md:ml-64"
        }  bg-white min-h-screen  md:px-4 px-0`}
      >
        <div className=" text-black  pt-32 min-h-screen bg-lightGray    px-4 py-6">
          {children}
        </div>
      </section>
    </>
  );
};
export default Super_admin_web_protal;
