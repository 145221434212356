import React, { useState, useEffect, useCallback, useRef } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { MdClose } from "react-icons/md";
import Modal from "../../../components/modal";
import Input from "../../../components/Input";
import Button from "../../../components/Button";
import Datepicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { MdOutlineFileUpload } from "react-icons/md";
import { useCreateMutation } from "../../../store/services/depositService";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setSuccess } from "../../../store/reducers/globalReducer";
import Add_ViewForm from "./Add_ViewForm";
import moment from "moment";
const Add_Deposits = ({
  isModalOpen,
  setIsModalOpen,
  closeModal,
  setUsers,
}) => {
  const [value, setValue] = useState("");
  const [loading, setLaoder] = useState(false);
  const { workerToken } = useSelector((state) => state.authReducer);
  const [selectedPlan, setSelectedPlan] = useState({
    planId: "",
    planName: "",
  });
  const handleSelectChange = (e) => {
    const selectedPlanId = e.target.value;
    const selectedPlanName =
      allPlans.find((plan) => plan.planId === selectedPlanId)?.planName || "";
    setSelectedPlan({
      planId: selectedPlanId,
      planName: selectedPlanName,
    });
  };

  const [allPlans, setAllPlan] = useState([]);

  console.log(allPlans, "allPlans");

  const [startDate, setStartDate] = useState(new Date());

  const handleDateChange = (date) => {
    setStartDate(date);
    console.log(date);
  };

  useEffect(() => {
    console.log(moment(startDate).format("YYYY-MM-DD"));
  }, [startDate]);

  const initialState = {
    name: "",
    cnic: "",
    amount: "",
    remark: "",
    reference: "",
    bankName: "",
    planId: "",
    branchName: "",
    depositType: "",
    receiptNumber: "",
  };
  const worker_data = JSON.parse(localStorage.getItem("worker_data"));

  const [state, setState] = useState(initialState);
  const [isEnable, setIsEnable] = useState(true);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImages, setSelectedImages] = useState(null);
  const [name, setName] = useState("");
  const [checked, setChecked] = useState(false);
  const [checkedPlan, setCheckedPlan] = useState(false);
  const fileInputRef = useRef(null);

  const handleInputs = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  
  const handleDragOver = useCallback((e) => {
    e.preventDefault();
  }, []);

  const handleDrop = useCallback((e) => {
    e.preventDefault();
    const file = processFile(e.dataTransfer.files[0]);
    setSelectedImages(file);
  }, []);

  const handleFileChange = (e) => {
    processFile(e.target.files[0]);
    setSelectedImages(e.target.files[0]);
  };

  const processFile = (file) => {
    setSelectedImages(file);
    if (file && file.type.startsWith("image/")) {
      const reader = new FileReader();
      reader.onload = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  const [create, response] = useCreateMutation();
  const [formData, setForms] = useState([]);
  const [contact, setContact] = useState("");
  console.log(state.cnic.replace(/[-\s]/g, ""), "dfdddddddddddd=>>>>>>>>>>>fd");
  const onSubmit = async (e) => {
    const isPatternCorrect = /^\d{13}$/.test(state.cnic.replace(/[-\s]/g, ""));

    e.preventDefault();
    if (checked === false) {
      return toast("Please Check CNIC First");
    } else if (!startDate) {
      toast.error("Please select your deposit date!");
    } else if (!name) {
      toast.error("Please provide your name");
    } else if (!isPatternCorrect) {
      toast.error("Please make sure it's exactly 13 digits.");
    } else if (state.depositType.length === 0) {
      toast.error("Please select your deposit type!");
    } else if (state.cnic.length === 0) {
      toast.error("Must enter a valid email!");
    } else if (!checkedPlan) {
      toast.error("No enrollments found for this investor");
    } else if (state.receiptNumber.length === 0) {
      toast.error("Must enter  receipt number!");
    } else if (state.amount.length === 0) {
      toast.error("Please enter  deposited amount!");
    } else if (selectedPlan.planId.length === 0) {
      toast.error("Please select your plan!");
    } else if (!selectedImage) {
      toast.error(" Please upload Slip");
    } else {
      setLaoder(true);
      let profilephoto = " ";

      try {
        let param = new FormData();

        param.append("images", selectedImages);

        profilephoto = await axios.post(
          `https://file-upload-ashen.vercel.app/api/upload`,
          param
        );

        console.log(profilephoto.data.data[0], "=====profile photo===");
        setForms(profilephoto.data.data[0]);
        const payload = {
          workerId: workerToken,
          name: name,
          cnic: state.cnic.replace(/[-\s]/g, ""),
          amount: Number(state.amount),
          remark: state.remark,
          reference: worker_data?.name,
          planId: selectedPlan.planId,
          depositDate: moment(startDate).format("YYYY-MM-DD"),
          depositType: state.depositType,
          receiptNumber: state.receiptNumber,
          slip: profilephoto.data.data[0],
        };
        if (state.depositType === "online") {
          payload.branchName = state.branchName;
          payload.bankName = state.bankName;
        } else if (state.depositType === "cash") {
          payload.branchName = "no branch";
          payload.bankName = " no bank";
        }
        create(payload);
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if (response.isError) {
      toast.error(response?.error?.data?.message);
      setLaoder(false);
    }
  }, [response?.error?.data]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (response.isSuccess) {
      console.log(response?.data?.amount);
      if (response?.data?.success === true) {
        const urlencoded = new URLSearchParams();
        urlencoded.append("date", moment(startDate).format("DD-MM-YYYY"));
        urlencoded.append("amount", state?.amount);
        urlencoded.append("plan", selectedPlan?.planName);
        urlencoded.append("reciepient", `+${contact}`);
        urlencoded.append("status", "pending");
        urlencoded.append("receiptId", state?.receiptNumber);
        urlencoded.append("workerName", worker_data?.name);
        axios.post("https://whatsapp-api-nodejs-smoky.vercel.app/sendReciept",urlencoded)
          .then((response) =>{
            console.log(response)
            if (response?.data?.status === "ok") {
              setLaoder(false);
              dispatch(setSuccess('Deposit successfully'))
              toast.success('Deposit  successfully');
              setIsModalOpen(false);
              window.location.reload();
            } else {
              setLaoder(false);
            }
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        toast.error(response?.data?.message);
        setLaoder(false);
      }
    }
  }, [response.isSuccess]);

  const CheckNow = () => {
    const formattedCnic = state.cnic.replace(/-/g, "").trim();

    if (!formattedCnic) {
      return toast.error("Field Should Not Be Empty");
    }

    if (checked === true) {
      return toast.error("Already Checked");
    }
    const isPatternCorrect = /^\d{13}$/.test(state.cnic.replace(/[-\s]/g, ""));

    if (!isPatternCorrect) {
      
      return toast.error("Please make sure it's exactly 13 digits.");
    }
    const param = {
      cnic: state.cnic.replace(/[-\s]/g, ""),
    };
    axios
      .post(
        `https://welcome-habibi-backend.vercel.app/v1/worker/check-investor-cnic`,
        param
      )
      .then((res) => {
        console.log(res, "cnic checked");

        if (res?.data?.success === true) {
          setChecked(true);
          setName(res?.data?.investor?.fullName);
          setContact(res?.data?.investor?.contactInfo);
          CheckPlanInvestor();
        } else {
          setIsEnable(true);
          setChecked(false);

          toast.error(res?.data?.message);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  const CheckPlanInvestor = () => {
    axios
      .get(
        `https://welcome-habibi-backend.vercel.app/v1/worker/my-enrolled-plan/${state.cnic}`
      )
      .then((res) => {
        console.log(res.data);

        if (res?.data?.success === true) {
          setCheckedPlan(true);
          toast.success(res.data.message);
          setAllPlan(res.data.enrolledPlans);
        } else {
          setCheckedPlan(false);
          toast.error(res.data.message);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  const [isUpdateOpen2, setIsUpdateOpen2] = useState(false);

  console.log(isUpdateOpen2);

  const resetForm = () => {
    setState(initialState);
    setName("");
    setValue("");
    setSelectedImage(null);
    setChecked(false);
  };

  return (
    <div>
      <Add_ViewForm
        isModalOpen={isUpdateOpen2}
        setIsModalOpen={setIsUpdateOpen2}
        formData={selectedImages}
        stateData={state}
        values={moment(startDate).format("YYYY-MM-DD")}
        names={name}
        workerId={workerToken}
      />

      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        className={" md:w-[80%] w-[100%]"}
      >
        <div className="">
          <div className=" p-3 flex justify-between items-center">
            <div></div>

            <MdClose
              className="  cursor-pointer text-gray-500"
              onClick={() => {
                setIsModalOpen(false);

                resetForm();
              }}
              size={25}
            />
          </div>

          <div className=" p-5">
            <form onSubmit={onSubmit}>
              <div className=" md:flex block justify-between">
                <div className=" md:w-[45%] w-[100%] flex gap-3  flex-col">
                  <div className=" w-full flex flex-col">
                    <label className=" pb-3 text-lg  font-medium">
                      Deposit Date
                    </label>

                    <div>
                      <div className=" relative">
                        <Datepicker
                          selected={startDate}
                          onChange={handleDateChange}
                          minDate={new Date()}
                          className="bg-lightGray w-full  rounded-md p-4 "
                        />
                        <div className=" absolute right-4 top-4">
                          <img
                            src={require("../../../assets/image/Calendar.png")}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className=" w-full">
                    <Input
                      label={"CNIC No"}
                      placeholder={""}
                      name={"cnic"}
                      onChange={handleInputs}
                      // value={state.cnic}
                      className={" w-full  py-4"}
                      Icon={
                        <button
                          onClick={CheckNow}
                          type="button"
                          className={`  text-white py-1.5 px-1 rounded-md`}
                          style={
                            checked === true
                              ? { backgroundColor: "green", fontSize: "14px" }
                              : { backgroundColor: "#00008B", fontSize: "14px" }
                          }
                        >
                          {checked === true ? " Checked" : "Check Now"}
                        </button>
                      }
                    />
                  </div>
                  <div className=" w-full">
                    <Input
                      className={" w-full  py-4"}
                      onChange={(e) => setName(e.target.value)}
                      value={name}
                      label={"Name"}
                      placeholder={""}
                      name={"name"}
                      disabled={isEnable}
                    />
                  </div>
                  {checkedPlan === true ? (
                    <div className=" w-full">
                      <label
                        for="first_name"
                        className=" block mb-2 text-[18px]  font-medium text-black"
                      >
                        Plan Type
                      </label>
                      <select
                        onChange={handleSelectChange}
                        name="planId"
                        value={selectedPlan.planId}
                        className=" py-4 font-medium w-full rounded border-r-8   bg-lightGray outline-none  border-transparent px-4 text-md"
                      >
                        <option>Select Type of Plan</option>
                        {allPlans?.map((item, index) => {
                          return (
                            <>
                              <option value={item?.planId}>
                                {item?.planName}
                              </option>
                            </>
                          );
                        })}
                      </select>
                    </div>
                  ) : null}

                  <div className=" w-full">
                    <label
                      for="first_name"
                      className=" block mb-2 text-[18px]  font-medium text-black"
                    >
                      Type
                    </label>
                    <select
                      onChange={handleInputs}
                      name="depositType"
                      value={state?.depositType}
                      className=" py-4 font-medium w-full rounded border-r-8   bg-lightGray outline-none  border-transparent px-4 text-md"
                    >
                      <option>Select Type of Deposit</option>
                      <option value={"cash"}>Cash</option>
                      <option value={"online"}>Online</option>
                    </select>
                  </div>

                  <div className=" w-full">
                    <Input
                      label={"Receipt No"}
                      onChange={handleInputs}
                      value={state.receiptNumber}
                      placeholder={""}
                      name={"receiptNumber"}
                      className={" w-full  py-4"}
                    />
                  </div>
                  <div className=" w-full">
                    <Input
                      Icon={"PKR"}
                      label={"Deposited Amount"}
                      placeholder={""}
                      name={"amount"}
                      type="number"
                      onChange={handleInputs}
                      value={state.amount}
                      className={" w-full  py-4"}
                    />
                  </div>
                </div>
                <div className=" flex flex-col gap-3 md:w-[40%] w-full">
                  {state.depositType === "online" ? (
                    <>
                      <div className=" w-full">
                        <Input
                          label={"Bank Name"}
                          placeholder={""}
                          name={"bankName"}
                          className={" w-full  py-4"}
                          onChange={handleInputs}
                          value={state.bankName}
                        />
                      </div>
                      <div className=" w-full">
                        <Input
                          onChange={handleInputs}
                          value={state.branchName}
                          label={"Branch"}
                          placeholder={""}
                          name={"branchName"}
                          className={" w-full  py-4"}
                        />
                      </div>
                    </>
                  ) : null}

                  <div className=" w-full">
                    <Input
                      onChange={handleInputs}
                      value={state.remark}
                      label={"Remarks"}
                      placeholder={""}
                      name={"remark"}
                      className={" w-full  py-4"}
                    />
                  </div>
                  <div
                    className="w-full border-dashed border-[#9E9E9E] border-2 rounded-lg p-4 cursor-pointer"
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}
                    onClick={triggerFileInput} // Allow clicking anywhere in the div to trigger file input
                  >
                    <div className="bg-[#F6F6F6] mx-auto w-14 flex justify-center items-center h-14 rounded-2xl">
                      <MdOutlineFileUpload size={30} color="#c8c7cf" />
                    </div>
                    <p className="text-primary font-medium mt-3 text-center">
                      <span className="text-secondary">Click here</span> or drag
                      and drop to upload a slip
                    </p>
                    {selectedImage && (
                      <img
                        src={selectedImage}
                        alt="Uploaded slip"
                        className="mt-4 mx-auto"
                        style={{ maxWidth: "100%", height: "auto" }}
                      />
                    )}
                    <input
                      type="file"
                      onChange={handleFileChange}
                      style={{ display: "none" }}
                      ref={fileInputRef}
                    />
                  </div>

                  {loading === true ? (
                    <button
                      disabled
                      type="button"
                      class="bg-blue text-center mt-3 justify-center uppercase text-white py-2.5 border-b-black  border-2 w-full"
                    >
                      <svg
                        aria-hidden="true"
                        role="status"
                        class="inline w-4 h-4 me-3 text-white animate-spin"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="#E5E7EB"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentColor"
                        />
                      </svg>
                      Loading...
                    </button>
                  ) : (
                    <Button
                      label={"Add"}
                      type={"submit"}
                      className={
                        "     bg-blue text-center mt-3 justify-center uppercase text-white py-2.5 border-b-black  border-2 w-full"
                      }
                    />
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Add_Deposits;
