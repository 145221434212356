import React, { useState, useRef, useCallback, useEffect } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import Input from "../../../components/Input";
import Button from "../../../components/Button";
import { MdClose, MdOutlineFileUpload } from "react-icons/md";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setSuccess } from "../../../store/reducers/globalReducer";
import { useCreateMutation } from "../../../store/services/eventService";
import Modal from "../../../components/modal";

const AddWhtGallery = ({ isModalOpen, setIsModalOpen, closeModal }) => {
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState('');
  const [selectedImages, setSelectedImages] = useState([]);
  const [previewImages, setPreviewImages] = useState([]);
  const fileInputRef = useRef(null);

  const handleDragOver = useCallback((e) => {
    e.preventDefault();
  }, []);

  const handleDrop = useCallback((e) => {
    e.preventDefault();
    const files = Array.from(e.dataTransfer.files);
    processFiles(files);
  }, []);

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    processFiles(files);
  };

  const processFiles = (files) => {
    const imageFiles = files.filter(file => file.type.startsWith("image/"));
    setSelectedImages(current => [...current, ...imageFiles]);
    const newPreviewImages = imageFiles.map(file => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      return new Promise(resolve => {
        reader.onload = () => resolve(reader.result);
      });
    });

    Promise.all(newPreviewImages).then(images => {
      setPreviewImages(current => [...current, ...images]);
    });
  };

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  const [create, response] = useCreateMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!title) {
      toast.error("Must enter title!");
      return;
    } else if (selectedImages.length === 0) {
      toast.error("Please choose at least one image!");
      return;
    }

    setLoading(true);
    try {
      const param = new FormData();
      selectedImages.forEach(image => {
        param.append("images", image);
      });

      const response = await axios.post(
        `https://file-upload-ashen.vercel.app/api/upload`, param
      );

      create({
        title: title,
        images: response.data.data,
      });
    } catch (error) {
      toast.error("Upload failed!");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (response.isSuccess && response?.data?.success) {
      dispatch(setSuccess(response?.data?.message));
      toast.success(response?.data?.message);
      setIsModalOpen(false);
      window.location.reload();
    }
  }, [response.isSuccess, response?.data]);

  return (
    <div>
      <Modal isOpen={isModalOpen} onClose={closeModal} className={"md:w-[40%] w-[100%]"}>
        <div className="p-5">
          <div className="flex justify-between items-center">
            <div></div>
            <MdClose className="text-gray-500" onClick={() => setIsModalOpen(false)} size={25} />
          </div>
          <form onSubmit={onSubmit}>
            <div className="flex w-full gap-3 p-5 flex-wrap">
             <div className=" w-full">
             <Input
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                label="Enter Title"
                placeholder="Enter title"
                name="title"
                className="w-full py-4"
              />
             </div>
              <div
                className="w-full mt-4 border-dashed border-2 border-gray-400 rounded-lg p-4 cursor-pointer"
                onDragOver={handleDragOver}
                onDrop={handleDrop}
                onClick={triggerFileInput}
              >
                <div className="bg-gray-100 flex mx-auto text-center justify-center w-14 h-14 items-center rounded-2xl">
                  <MdOutlineFileUpload size={30} color="#c8c7cf" />
                </div>
                <p className="text-center mt-3">
                  <span className="  text-secondary">Click here</span> or drag and drop to upload images
                </p>
                <div className="grid grid-cols-2 gap-4 mt-4">
                  {previewImages.map((img, index) => (
                    <img key={index} src={img} alt="Preview" className=" w-[100%] object-cover  h-32  rounded-md" />
                  ))}
                </div>
                <input
                  type="file"
                  multiple
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                  ref={fileInputRef}
                />
              </div>
              <Button
                disabled={loading}
                label={loading ? "Loading..." : "Add"}
                type="submit"
                className="bg-blue-500 justify-center text-white uppercase py-3 border-b-2 border-black w-full"
              />
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default AddWhtGallery;
