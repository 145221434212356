import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { FaCircle } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { logout } from "../../../store/reducers/authReducer";
const SuperAdminSidebar = ({ side, closeSidebar }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const location = useLocation();
  const isActive = (route) => location.pathname.includes(route);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const adminLogout = () => {
    dispatch(logout('adminToken'));
    toast.success('Super admin logout successfully!')
    navigate('/login')
  }


  return (
    <div
      className={`fixed top-0  shadow-xl ${side} sm:left-0    w-64 h-screen     z-20 transition-all`}
    >
      <div className="bg-primary overflow-y-auto   h-full w-64">
        <i
          className="bi bi-x-lg absolute text-black top-4 right-4 sm:hidden block cursor-pointer text-lg"
          onClick={closeSidebar}
        ></i>
        <div className="  px-5 py-8 ">
          <h1 className=" text-white text-4xl font-medium">
            <img
              src={require("../../../assets/image/logo.png")}
              className="   mx-auto"
              alt=""
            />
            {/* Logo */}
          </h1>
        </div>

        <ul className="">
          <li className="px-4 cursor-pointer  font-semibold transition-all py-3 text-white flex items-center  hover:bg-primary hover:text-white">
            <FaCircle size={12} className=" mr-2" color="white" />
            <div className="text-lg capitalize">User Management</div>
          </li>


          <li className="px-4  cursor-pointer transition-all py-3 text-white flex items-center  hover:bg-primary hover:text-white">
            <i class="bi bi-grid-fill  mr-2 inline-block text-xl"></i>
            <Link
              to="/super_admin/plans"
              className={`text-md text-white  ${isActive("/super_admin/plans")
                  ? " font-semibold"
                  : "font-extralight"
                }  capitalize`}
            >
              Plans
            </Link>
          </li>

          <li className="px-4  cursor-pointer transition-all py-3 text-white flex items-center  hover:bg-primary hover:text-white">
            <i class="bi bi-grid-fill  mr-2 inline-block text-xl"></i>
            <Link
              to="/super_admin/managers"
              className={`text-md text-white  ${isActive("/super_admin/managers")
                  ? " font-semibold"
                  : "font-extralight"
                }  capitalize`}
            >
              Managers
            </Link>
          </li>

          <li className="px-4  cursor-pointer  transition-all py-3  flex items-center  hover:bg-primary ">
            <i class="bi bi-grid-fill  mr-2 inline-block text-xl"></i>
            <Link
              to="/super_admin/dashboard"
              className={`text-md text-white ${isActive("/super_admin/dashboard")
                  ? " font-semibold"
                  : "font-extralight"
                }  font-extralight capitalize`}
            >
              Workers
            </Link>
          </li>

          <li className="px-4 cursor-pointer  transition-all py-3 text-white flex items-center  hover:bg-primary hover:text-white">
            <i class="bi bi-grid-fill  mr-2 inline-block text-xl"></i>
            <Link
              to="/super_admin/investors"
              className={`text-md text-white ${isActive("/super_admin/investors")
                  ? " font-semibold"
                  : "font-extralight"
                } capitalize`}
            >
              Investors
            </Link>
          </li>

          <li className="px-4 cursor-pointer  transition-all py-3 text-white flex items-center  hover:bg-primary hover:text-white">
            <i class="bi bi-grid-fill  mr-2 inline-block text-xl"></i>
            <Link
              to="/super/rewards"
              className={`text-white ${isActive("/super/rewards") ? " font-semibold" : "font-light"
                } text-lg font-light capitalize`}
            >
              Rewards
            </Link>
          </li>

          <li className="px-4 cursor-pointer  transition-all py-3 text-white flex items-center  hover:bg-primary hover:text-white">
            <i class="bi bi-grid-fill  mr-2 inline-block text-xl"></i>
            <Link
              to="/super_admin/requests"
              className={`text-white ${isActive("/super_admin/requests") ? " font-semibold" : "font-light"
                } text-lg font-light capitalize`}
            >
              Requests
            </Link>
          </li>

          <li className="px-4 cursor-pointer  transition-all py-3 text-white flex items-center  hover:bg-primary hover:text-white">
            <i class="bi bi-grid-fill  mr-2 inline-block text-xl"></i>
            <Link
              to="/super_admin/compnay_expence"
              className={`text-white text-lg  ${isActive("/super_admin/compnay_expence")
                  ? " font-semibold"
                  : "font-light"
                }  capitalize`}
            >
              Company Expense
            </Link>
          </li>

          <li className="px-4 cursor-pointer  transition-all py-3 text-white flex items-center  hover:bg-primary hover:text-white">
            <i class="bi bi-grid-fill  mr-2 inline-block text-xl"></i>
            <Link
              to="/super_admin/wht_gallery"
              className={`text-white text-lg  ${isActive("/super_admin/wht_gallery")
                  ? " font-semibold"
                  : "font-light"
                }   capitalize`}
            >
              WHT Gallery
            </Link>
          </li>

          <li className="px-4 cursor-pointer  transition-all py-3  flex items-center  hover:bg-primary hover:text-white">
            <i class="bi bi-grid-fill  mr-2 inline-block text-xl"></i>
            <Link
              to="/super_admin/branch_list"
              className={`text-lg text-white ${isActive("/super_admin/branch_list")
                  ? " font-semibold"
                  : "font-light"
                }  capitalize`}
            >
              Branch List
            </Link>
          </li>

          <li onClick={adminLogout} className="px-4 cursor-pointer font-semibold transition-all py-3 text-white flex items-center  hover:bg-primary hover:text-white">
            <i class="bi bi-grid-fill  mr-2 inline-block text-xl"></i>
            <Link className={`text-md   text-red`}>
              Logout
            </Link>
          </li>
        </ul>
      </div>
      <div
        onClick={closeSidebar}
        className={` bg-[rgba(0,0,0,0.6)]  ${side === "-left-64 md:left-0" ? " w-64" : " w-full"
          }     -z-20 h-screen  fixed   top-0   sm:left-0`}
      ></div>
    </div>
  );
};
export default SuperAdminSidebar;
