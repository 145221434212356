import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const investmentService = createApi({
  reducerPath: "investment",
  tagTypes: "investments",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://welcome-habibi-backend.vercel.app/v1/",
  }),
  endpoints: (builder) => {
    return {
      create: builder.mutation({
        query: (name) => {
          return {
            url: "worker/create-investment",
            method: "POST",
            body: name,
          };
        },
      }),
      updateinvestment: builder.mutation({
        query: (data) => {
          return {
            url: `update-investments/${data.id}`,
            method: "POST",
            body: { name: data.name },
          };
        },
      }),
      deleteinvestment: builder.mutation({
        query: (id) => {
          return {
            url: `delete/${id}`,
            method: "DELETE",
          };
        },
      }),

      fetchinvestment: builder.mutation({
        query: (name) => {
          return {
            url: `worker/my-investment`,
            method: "POST",
            body: name,
          };
        },
      }),
      allinvestments: builder.query({
        query: () => {
          return {
            url: "manager/all-investments",
            method: "GET",
          };
        },
      }),
      investmentprofile: builder.query({
        query: (id) => {
          return {
            url: `worker/investment-profile/${id}`,
            method: "GET",
          };
        },
      }),
    };
  },
});
export const {
  useCreateMutation,
  useFetchinvestmentMutation,
  useAllinvestmentsQuery,
  useinvestmentprofileQuery,
  useUpdateinvestmentMutation,
  useDeleteinvestmentMutation,
} = investmentService;
export default investmentService;
