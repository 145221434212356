import React, { useEffect, useState } from "react";
import Input from "../../../components/Input";
import Button from "../../../components/Button";
import { IoSearchOutline } from "react-icons/io5";
import { HiOutlineArrowLeft } from "react-icons/hi";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import moment from "moment";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { FaTableList } from "react-icons/fa6";
import { RxCountdownTimer } from "react-icons/rx";
import { toast } from "react-toastify";
import ViewSlip from "../InvestorsDetails/ViewSlip";
import View_formDetails from "./View_formDetails";
import Swal from "sweetalert2";
import { Base_url } from "../../../utils/Base_url";
import { RiDeleteBin5Line } from "react-icons/ri";
import { PiPencilSimpleFill } from "react-icons/pi";
import Edit_Deposits from "../InvestorsDetails/Edit_Deposits";
import Super_admin_web_protal from "../../../layout/Super_admin_web_portal";

const ViewPlanInvestorDetails = () => {
  const dispatch = useDispatch();
  const { id1, id2 } = useParams();

  console.log(id1, id2);

  const [fetchInvestor, setFetchInvestors] = useState([]);
  const [planData, setPlanData] = useState([]);

  console.log(planData);

  const [getData, setGetData] = useState({});
  const [getDeposit, setGetDeposit] = useState([]);
  const [enrollment, setGetEnrollment] = useState({});

  console.log(
    enrollment,
    "dfasssssssssssssssskaeejoewqo0rjaqqdjfkjasdjfkoadjf"
  );

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredDeposits, setFilteredDeposits] = useState([]);
  const itemsPerPage = 6;
  const [currentPage, setCurrentPage] = useState(1);
  const currentPageData = filteredDeposits?.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );
  const handlePageChange = (newPage) => setCurrentPage(newPage);
  const [planDetails, setPlanDetails] = useState(null);
  const [investerDetails, setInvestorDetails] = useState({});
  const [fetchUser, setFetchUser] = useState({});
  console.log(planDetails, "invetor details====>>");

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const openModal2 = () => {
    setIsModalOpen2(true);
  };



  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const openModal3 = () => {
    setIsModalOpen3(true);
  };

  useEffect(() => {
    const params = {
      cnic: id1,
      planId: id2,
    };

    axios
      .post(
        `https://welcome-habibi-backend.vercel.app/v1/worker/deposit-by-investor-plan`,
        params
      )
      .then((res) => {
        console.log(res, "dfffffffffffffffffffffff");

        setFetchInvestors(res.data.deposits);
        setInvestorDetails(res.data.invester[0]);
      })
      .catch((error) => {});

    axios
      .get(
        `https://welcome-habibi-backend.vercel.app/v1/admin/plan-detail/${id2}`
      )
      .then((res) => {
        console.log(res);

        // setPlanDetails(res.data.data);
      })
      .catch((error) => {});

    axios
      .get(
        `https://welcome-habibi-backend.vercel.app/v1/worker/my-enrolled-plan/${id2}`
      )
      .then((res) => {
        console.log(res?.data);
        setPlanData(res?.data?.enrolledPlans);
      })
      .catch((error) => {});
  }, []);

  const [CheckForm, setCheckForm] = useState(null);

  console.log(CheckForm);

  const CheckInvestorForm = () => {
    const params = {
      planId: id2,
    };
    axios
      .post(
        `https://welcome-habibi-backend.vercel.app/v1/worker/bio-data/${investerDetails?._id}`,
        params
      )
      .then((res) => {
        console.log(res.data, "dsssssssssssssssssssssdddddddddddddddds");
        if (res?.data?.success === true) {
          toast.success(res.data.message);
          setGetEnrollment(res?.data?.enrollment);
          setCheckForm(res.data.bioData);
          setPlanDetails(res.data.planDetail);
          setGetDeposit(res?.data?.deposits);
          setIsModalOpen2(true);
        } else {
          // toast.error(res.data.message);
        }
      })
      .catch((error) => {});
  };

  const removeFunction = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#A47ABF",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .post(`${Base_url}/v1/admin/delete-deposit/${id}`)
          .then((res) => {
            console.log(res);
            if (res.status === 200) {
              Swal.fire("Deleted!", "Your file has been deleted.", "success");
              window.location.reload();
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  };

  useEffect(() => {
    filterDeposits();
  }, [fetchInvestor, searchTerm]);

  const filterDeposits = () => {
    let filtered = fetchInvestor?.slice().reverse() || [];

    if (searchTerm.trim()) {
      const lowercasedSearchTerm = searchTerm.toLowerCase();
      filtered = filtered.filter((val) => {
        const investmentIdMatch = val?.name
          ?.toLowerCase()
          .includes(lowercasedSearchTerm);
        const managerIdMatch = val?._id
          ?.toLowerCase()
          .includes(lowercasedSearchTerm);
        const managerAmountMatch = String(val?.amount)
          .toLowerCase()
          .includes(lowercasedSearchTerm);

        return investmentIdMatch || managerIdMatch || managerAmountMatch;
      });
    }

    setFilteredDeposits(filtered);
    setCurrentPage(1);
  };


  
  return (
    <Super_admin_web_protal
      language={<p className="sm:block hidden">English | Urdu</p>}
      ToggleBtn={
        <div className="flex gap-4 items-center">
          <HiOutlineArrowLeft />
          <h2 className="">
            Investors{" "}
            <span className="text-darkGray font-medium text-sm">
              / {localStorage.getItem("iName")}
            </span>
          </h2>
        </div>
      }
      title_props={
        <div className="w-96 relative">
          <Input
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder=""
            className={"border bg-white w-full rounded-full"}
          />
          <Button
            Icon={<IoSearchOutline className="text-white text-xl" />}
            label={"search"}
            className={
              "absolute top-0 right-0 bg-secondary text-white uppercase font-normal rounded-full"
            }
          />
        </div>
      }
    >
      <ViewSlip
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        getData={getData}
        // getDeposit={getDeposit}
      />

<Edit_Deposits
        isModalOpen={isModalOpen3}
        setIsModalOpen={setIsModalOpen3}
        getData={fetchUser}
      />

      <View_formDetails
        isModalOpen={isModalOpen2}
        setIsModalOpen={setIsModalOpen2}
        getData={CheckForm}
        getDeposit={getDeposit}
        enrollment={enrollment}
        plan={planDetails}
      />

      {/* Modal components */}

      <div className="sm:flex block justify-between items-center">
        <div>
          <h2 className="sm:block hidden">
            Investors{" "}
            <span className="text-darkGray font-medium text-sm">
              /{localStorage.getItem("iName")}
            </span>
          </h2>
        </div>

        <div className="flex gap-3 items-center">
          <Button
            onClick={CheckInvestorForm}
            Icon={<FaTableList />}
            className={
              "bg-white border-secondary text-[#030229B2] border font-normal rounded-xl"
            }
            label={"View Form"}
          />

          <Link to={`/super_admin/history/${id1}`}>
            <Button
              Icon={<RxCountdownTimer />}
              className={"bg-white text-[#030229B2] rounded-xl"}
              label={"History"}
            />
          </Link>

          {/* <div className="relative">
            <button
              onClick={toggleDropdown}
              className="rounded-md py-2 px-3 text-[#030229B2] bg-white border border-secondary flex items-center"
            >
              Plans
              <TiArrowSortedDown className="ml-2" />
            </button>
            {isDropdownVisible && (
              <div className="mt-2 w-36 text-center absolute right-0 bg-white border border-secondary rounded-md">
                <ul className="flex flex-col gap-1 text-left">
                  {planData?.map((item) => (
                    <li
                      key={item.planId}
                      onClick={() => {
                        handlePlanSelection(item.planId);
                        setPlanDetails(item);
                      }}
                      className="cursor-pointer px-2 py-1.5 hover:bg-secondary hover:text-white text-sm"
                    >
                      {item.planName}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div> */}
        </div>
      </div>

      <section className="mb-20 mt-12 text-gray-800">
        <div className="block rounded-lg ">
          <div className="flex flex-col overflow-hidden">
            <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full sm:px-6 lg:px-8">
                <div className="">
                  <table className="min-w-full mb-0">
                    <thead className=" ">
                      <tr className=" rounded-lg whitespace-nowrap ">
                        <th
                          scope="col"
                          className=" text-sm text-darkGray    font-medium px-6 "
                        >
                          Investment ID
                        </th>
                        <th
                          scope="col"
                          className=" text-sm text-darkGray   font-medium px-6 "
                        >
                          Deposit Date
                        </th>

                        <th
                          scope="col"
                          className="text-sm  text-darkGray   font-medium px-6 "
                        >
                          Deposit Type
                        </th>

                        <th
                          scope="col"
                          className="text-sm  text-darkGray     font-medium px-6 "
                        >
                          Deposit Amount
                        </th>
                        <th
                          scope="col"
                          className="text-sm  text-darkGray     font-medium px-6 "
                        >
                          Profit
                        </th>
                        <th
                          scope="col"
                          className="text-sm  text-darkGray     font-medium px-6 "
                        >
                          Deduction Type
                        </th>

                        <th
                          scope="col"
                          className="text-sm  text-darkGray     font-medium px-6 "
                        >
                          Deduction
                        </th>

                        <th
                          scope="col"
                          className="text-sm  text-darkGray     font-medium px-6 "
                        >
                          Reference
                        </th>

                        <th
                          scope="col"
                          className="text-sm    text-darkGray   font-medium px-6 "
                        >
                          Slip
                        </th>

                        <th
                          scope="col"
                          className="text-sm    text-darkGray   font-medium px-6 "
                        ></th>
                      </tr>
                    </thead>
                    <tbody className="">
                      {currentPageData?.map((item, index) => {
                        return (
                          <>
                            <tr className="bg-white border-8    border-[#F6F6F6]  rounded-md ">
                              <td className="align-middle text-sm font-normal px-6 py-4 whitespace-nowrap  text-center">
                                <span className=" text-base text-black  py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline   bg-green-200  rounded-full">
                                  {item?._id}
                                </span>
                              </td>
                              <td className="text-sm font-normal text-center px-6 py-4 whitespace-nowrap">
                                <span className=" text-base  text-black py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline   bg-green-200  rounded-full">
                                  {moment(item?.depositDate).format(
                                    "DD/MM/YYYY"
                                  )}
                                </span>
                              </td>
                              <td className="align-middle text-center text-sm font-normal px-6 py-4 whitespace-nowrap text-left">
                                <span className=" text-base  text-black  py-1 px-2.5 leading-none  whitespace-nowrap    bg-green-200  rounded-full">
                                  {item?.depositType}
                                </span>
                              </td>
                              <td className="align-middle text-center text-sm font-normal px-6 py-4 whitespace-nowrap text-left">
                                <span className=" text-base  text-black  py-1 px-2.5 leading-none  whitespace-nowrap    bg-green-200  rounded-full">
                                  {item?.amount}
                                </span>
                              </td>
                              <td className="align-middle text-center text-sm font-normal px-6 py-4 whitespace-nowrap text-left">
                                <span className=" text-base  text-black  py-1 px-2.5 leading-none  whitespace-nowrap    bg-green-200  rounded-full">
                                  {item?.profit}
                                </span>
                              </td>
                              <td className="align-middle text-center text-sm font-normal px-6 py-4 whitespace-nowrap text-left">
                                <span className=" text-base  text-black  py-1 px-2.5 leading-none  whitespace-nowrap    bg-green-200  rounded-full">
                                  {item?.profitDeduction?.deduction?.type
                                    ? item?.profitDeduction?.deduction?.type
                                    : "-"}
                                </span>
                              </td>
                              <td className="align-middle text-center text-sm font-normal px-6 py-4 whitespace-nowrap text-left">
                                <span className=" text-base  text-black  py-1 px-2.5 leading-none  whitespace-nowrap    bg-green-200  rounded-full">
                                  {item?.deduction}
                                </span>
                              </td>

                              <td className="align-middle text-center text-sm font-normal px-6 py-4 whitespace-nowrap text-left">
                                <span className=" text-base  text-black  py-1 px-2.5 leading-none  whitespace-nowrap    bg-green-200  rounded-full">
                                  {item?.reference}
                                </span>
                              </td>

                              <td className="align-middle cursor-pointer text-center text-sm font-normal px-6 py-4 whitespace-nowrap text-left">
                                <span
                                  onClick={() => {
                                    openModal();
                                    setGetData(item);
                                  }}
                                  className=" text-base text-secondary  py-1 px-2.5 leading-none  whitespace-nowrap    bg-green-200  rounded-full"
                                >
                                  {item?.slip.slice(-6)}
                                </span>
                              </td>

                              <td className="align-middle  text-sm font-normal px-6 pr-0  whitespace-nowrap">
                                <span
                                  onClick={() => {
                                    openModal3();
                                    setFetchUser(item);
                                  }}
                                  className=" text-base text-black  py-1 px-2.5 leading-none  whitespace-nowrap    bg-green-200  rounded-full"
                                >
                                  <PiPencilSimpleFill className=" text-primary" />
                                </span>
                              </td>
                              <td className="align-middle  cursor-pointer text-sm font-normal px-6  whitespace-nowrap">
                                <span
                                  onClick={() => removeFunction(item?._id)}
                                  className=" text-base text-black   leading-none  cursor-pointer  whitespace-nowrap    bg-green-200  rounded-full"
                                >
                                  <RiDeleteBin5Line className=" text-[#d33]" />
                                </span>
                              </td>
                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className=" flex justify-end items-center  py-4 gap-6">
            <button
              className={`${
                currentPage === 1
                  ? "bg-gray-500  text-white"
                  : "bg-secondary text-white"
              } flex justify-center items-center  w-10 h-10 rounded-md`}
              disabled={currentPage === 1}
              onClick={() => handlePageChange(currentPage - 1)}
            >
              <FaAngleLeft size={20} />
            </button>
            <span className="  text-primary  font-semibold">
              {currentPage} of{" "}
              {Math.ceil(filteredDeposits.length / itemsPerPage)}
            </span>
            <button
              className={`${
                currentPage * itemsPerPage >= filteredDeposits.length
                  ? "bg-gray-500  text-white"
                  : "bg-secondary text-white"
              } flex justify-center items-center  w-10 h-10 rounded-md`}
              disabled={currentPage * itemsPerPage >= filteredDeposits.length}
              onClick={() => handlePageChange(currentPage + 1)}
            >
              <FaAngleRight size={20} />
            </button>
          </div>
        </div>
      </section>
    </Super_admin_web_protal>
  );
};

export default ViewPlanInvestorDetails;
