import { FiMenu } from "react-icons/fi";
import Button from "../../Button";
import { Link } from "react-router-dom";
const AdminNav = ({
  openSidebar,
  side,
  closeSidebar,
  title_props,
  ToggleBtn,
}) => {
  const adminToken = localStorage.getItem("adminToken");
  const managerToken = localStorage.getItem("managerToken");
  const workerToken = localStorage.getItem("workerToken");
  const investorToken = localStorage.getItem("investorToken");
  let buttonLabel = "Login";
  let buttonLink = "/login";

  if (adminToken) {
    buttonLabel = "Dashboard";
    buttonLink = "/super_admin/dashboard";
  } else if (managerToken) {
    buttonLabel = "Dashboard";
    buttonLink = "/manager/dashboard";
  } else if (workerToken) {
    buttonLabel = "Dashboard";
    buttonLink = "/workers/my_portal";
  } else if (investorToken) {
    buttonLabel = "Dashboard";
    buttonLink = "/investor/plans";
  }

  return (
    <nav
      className={`  w-full bg-white top-0 right-0 z-10   ${
        side === "left-0 md:-left-64" ? "left-0" : "left-0 md:left-64"
      }`}
    >
      <div className="   w-full flex justify-between  items-center p-6">
        <div className=" flex items-center gap-4">
          <div onClick={openSidebar} className="  md:hidden  block">
            {ToggleBtn}
          </div>
          <div className="  hidden md:block">{title_props}</div>
        </div>
        
        <div className=" flex items-center gap-6">
          <Link to={buttonLink}>
            <Button
              label={buttonLabel}
              className={" bg-secondary text-white w-32 py-3.5 justify-center"}
            />
          </Link>
        </div>
      </div>
    </nav>
  );
};
export default AdminNav;
