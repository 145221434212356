import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const bannerService = createApi({
  reducerPath: "banner",
  tagTypes: "banners",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://beauty-bridge-a647ece2d453.herokuapp.com/banner/",
    prepareHeaders: (headers, { getState }) => {
      const reducers = getState();
      const token = reducers?.authReducer?.adminToken;
      console.log(token);
      headers.set("authorization", token ? `Bearer ${token}` : "");
      return headers;
    },
  }),
  endpoints: (builder) => {
    return {
      create: builder.mutation({
        query: (name) => {
          return {
            url: "create",
            method: "POST",
            body: name,
          };
        },
        invalidatesTags: ["banners"],
      }),
      updatebanner: builder.mutation({
        query: (data) => {
          return {
            url: `update/${data.id}`,
            method: "PUT",
            body: { name: data.name },
          };
        },
        invalidatesTags: ["banners"],
      }),
      deletebanner: builder.mutation({
        query: (id) => {
          return {
            url: `delete/${id}`,
            method: "DELETE",
          };
        },
        invalidatesTags: ["banners"],
      }),

      fetchbanner: builder.query({
        query: (id) => {
          return {
            url: `get/${id}`,
            method: "GET",
          };
        },
        providesTags: ["banners"],
      }),
      allbanners: builder.query({
        query: () => {
          return {
            url: "getAll",
            method: "GET",
          };
        },
      }),
    };
  },
});
export const {
  useCreateMutation,
  useFetchbannerQuery,
  useAllbannersQuery,
  useUpdatebannerMutation,
  useDeletebannerMutation,
} = bannerService;
export default bannerService;
