import React, { useState, useEffect } from "react";
import { MdClose } from "react-icons/md";
import Modal from "../../components/modal";

const ViewEvents = ({ isModalOpen, setIsModalOpen, closeModal, getData }) => {
  console.log(getData);
  return (
    <div>
      <Modal
        isOpen={isModalOpen}
        className={" md:w-[80%] w-full"}
        onClose={closeModal}
      >
        <div className="">
          <div className=" p-6">
            <div className="  flex justify-between items-center">
              <div></div>
              <p className=" text-black  text-xl">{getData?.title}</p>
              <MdClose
                className="  text-gray-500"
                onClick={() => setIsModalOpen(false)}
                size={25}
              />
            </div>


     
            <div className=" pt-5 grid md:grid-cols-3 grid-cols-1 gap-3">
            
                {getData?.images?.map((item, index) => {
                  return(
                    <div className="w-full h-80 text-center">
                    <img
                      src={item}
                      className=" w-full h-full"
                      alt=""
                    />
                     </div>
                  )
                })}
             
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ViewEvents;
