import React, { useState } from "react";
import Button from "../../../components/Button";
import Super_admin_web_protal from "../../../layout/Super_admin_web_portal";
import { HiDotsVertical, HiOutlineArrowLeft } from "react-icons/hi";
import { IoSearchOutline } from "react-icons/io5";
import Input from "../../../components/Input";
import { FiPlus } from "react-icons/fi";
import Swal from "sweetalert2";
import axios from "axios";
import ViewEvents from "../../ViewEvents/ViewEvents";
import AddPlans from "./Add_plan";
import { PiPencilSimpleFill } from "react-icons/pi";
import { Link } from "react-router-dom";
import { useAllplansQuery } from "../../../store/services/planService";
import { toast } from "react-toastify";
import { Base_url } from "../../../utils/Base_url";
import UpdatePlan from "./Update_plan";

const Plans = () => {
  const { data, isFetching, refetch } = useAllplansQuery();
  console.log(data, isFetching, "=========>>>>>>");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [view, setView] = useState({});
  const [viewUpdate, setupdateView] = useState({});
  const [activeDropdown, setActiveDropdown] = useState(null); // Track active dropdown

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openModal2 = () => {
    setIsModalOpen2(true);
  };

  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const removeFunction = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#00afef",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${Base_url}/v1/manager/delete-event/${id}`)
          .then((res) => {
            console.log(res);
            Swal.fire("Deleted!", "Your file has been deleted.", "success");

            window.location.reload();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  };

  const postPlans = async (id) => {
    const uploadResponse = await axios.patch(
      `https://welcome-habibi-backend.vercel.app/v1/admin/close-plan/${id}`
    );

    console.log(uploadResponse);

    if (uploadResponse.data.success === true) {
      toast.success(uploadResponse.data.message);
      refetch();
    } else {
      toast.error(uploadResponse.data.message);
    }
  };

  const OpenPlans = async (id) => {
    const uploadResponse = await axios.patch(
      `https://welcome-habibi-backend.vercel.app/v1/admin/open-plan/${id}`
    );

    console.log(uploadResponse);

    if (uploadResponse.data.success === true) {
      toast.success(uploadResponse.data.message);
      refetch();
    } else {
      toast.error(uploadResponse.data.message);
    }
  };

  const toggleDropdown = (id) => {
    setActiveDropdown((prev) => (prev === id ? null : id));
  };

  return (
    <Super_admin_web_protal
      language={
        <>
          <p className=" sm:block  hidden">English | Urdu</p>
        </>
      }
      ToggleBtn={
        <div className=" flex gap-4 items-center">
          <HiOutlineArrowLeft />
          <h2 className=""> WHT GALLERY</h2>
        </div>
      }
      title_props={
        <>
          <div className=" w-96 relative">
            <Input
              placeholder={""}
              className={" border bg-white w-full rounded-full"}
            />

            <Button
              Icon={<IoSearchOutline className=" text-white text-xl" />}
              label={"search"}
              className={
                " absolute top-0 right-0 bg-secondary  text-white uppercase font-normal rounded-full"
              }
            />
          </div>
        </>
      }
    >
      <AddPlans
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        // setUsers={setUsers}
      />

      <UpdatePlan
        isModalOpen={updateModalOpen}
        setIsModalOpen={setUpdateModalOpen}
        getData={viewUpdate}
      />

      <ViewEvents
        isModalOpen={isModalOpen2}
        setIsModalOpen={setIsModalOpen2}
        getData={view}
      />

      <div className=" container m-auto pt-6">
        <div className=" flex justify-between gap-3 items-center">
          <h1 className=" font-semibold text-xl"> Plans</h1>
          <Button
            onClick={openModal}
            Icon={<FiPlus />}
            label={"Add Plan"}
            className={
              " bg-secondary text-white  font-normal py-3 rounded-full"
            }
          />
        </div>

        <div className="  w-full  mb-4  sm:hidden block relative">
          <Input
            placeholder={""}
            className={" border bg-white w-full rounded-full"}
          />

          <Button
            Icon={<IoSearchOutline className=" text-white text-xl" />}
            label={"search"}
            className={
              " absolute top-0 right-0 bg-secondary text-white uppercase font-normal rounded-full"
            }
          />
        </div>

        <div className=" my-12   grid  md:grid-cols-2 grid-cols-1 gap-12">
          {data?.Plans?.map((item, index) => {
            return (
              <div key={item?._id} className=" ">
                <div className=" relative h-80">
                  <img
                    src={item?.planImage}
                    className="  w-full h-full  object-cover"
                    alt=""
                  />
                  <div className=" absolute  top-0  bg-[rgba(0,0,0,0.1)]   h-full rounded-md w-full">
                    <div
                      onClick={() => toggleDropdown(item?._id)}
                      className=" cursor-pointer w-10 h-10 rounded-md  flex justify-center items-center bg-white absolute right-3 top-3"
                    >
                      <HiDotsVertical size={25} />
                      {activeDropdown === item?._id && (
                        <div className=" absolute top-12  w-60 bg-white rounded-xl p-2 right-0">
                          <ul className="p-0">
                            <li
                              onClick={() => {
                                setupdateView(item);

                                setUpdateModalOpen(true);
                              }}
                              className=" rounded-lg items-center justify-between flex bg-[#00AFEF1F] p-3"
                            >
                              <span className=" text-primary font-semibold">
                                Edit
                              </span>
                              <PiPencilSimpleFill className=" text-primary" />
                            </li>

                            {item?.status === "closed" ? (
                              <li
                                onClick={() => OpenPlans(item?._id)}
                                className=" rounded-lg pt-3"
                              >
                                <button className=" bg-red w-full text-white py-2 rounded-md">
                                  closed
                                </button>
                              </li>
                            ) : (
                              <li
                                onClick={() => postPlans(item?._id)}
                                className=" rounded-lg pt-3"
                              >
                                <button className=" bg-secondary w-full text-white py-2 rounded-md">
                                  {" "}
                                  Close Plan
                                </button>
                              </li>
                            )}
                          </ul>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="  bg-white px-10  py-10 flex flex-col  justify-center">
                  <p className=" text-primary font-semibold text-xl text-center ">
                    {item?.planName}
                  </p>
                  <p className=" text-primary  py-5  text-center">
                    {item?.planCategory}
                  </p>
                  <Link to={`/super_admin/view/${item?._id}`}>
                    <Button
                      label={"Details"}
                      className={
                        " bg-secondary    rounded-full justify-center w-36 mx-auto text-white  py-3.5"
                      }
                    />
                  </Link>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Super_admin_web_protal>
  );
};

export default Plans;
