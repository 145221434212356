import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const planService = createApi({
  reducerPath: "plan",
  tagTypes: "plans",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://welcome-habibi-backend.vercel.app/v1/admin/",
  }),
  endpoints: (builder) => {
    return {
      create: builder.mutation({
        query: (name) => {
          return {
            url: "plan",
            method: "POST",
            body: name,
          };
        },
      }),
      updateplan: builder.mutation({
        query: (data) => {
          return {
            url: `update/${data.id}`,
            method: "PUT",
            body: { name: data.name },
          };
        },
      }),
      deleteplan: builder.mutation({
        query: (id) => {
          return {
            url: `delete/${id}`,
            method: "DELETE",
          };
        },
      }),

      fetchplan: builder.query({
        query: (id) => {
          return {
            url: `get/${id}`,
            method: "GET",
          };
        },
      }),
      allplans: builder.query({
        query: () => {
          return {
            url: "plan",
            method: "GET",
          };
        },
      }),
    };
  },
});
export const {
  useCreateMutation,
  useFetchplanQuery,
  useAllplansQuery,
  useUpdateplanMutation,
  useDeleteplanMutation,
} = planService;
export default planService;
