import React, { useEffect, useState } from "react";

import Button from "../../../components/Button";
import Input from "../../../components/Input";
import { IoSearchOutline } from "react-icons/io5";
import Manager_web_portal from "../../../layout/Manager_web_portal";
import { HiOutlineArrowLeft } from "react-icons/hi";
import { Link } from "react-router-dom";
import { useAllinvestorsQuery } from "../../../store/services/investorService";
import Super_admin_web_protal from "../../../layout/Super_admin_web_portal";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { RiDeleteBin5Line } from "react-icons/ri";
import Swal from "sweetalert2";
import { Base_url } from "../../../utils/Base_url";
import axios from "axios";
const Investors = () => {
  const { data, isFetching } = useAllinvestorsQuery();
  console.log(data, isFetching);

  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [filteredDeposits, setFilteredDeposits] = useState([]);
  const currentPageData = filteredDeposits.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (newPage) => setCurrentPage(newPage);

  useEffect(() => {
    filterDeposits();
  }, [data, searchTerm]);

  const filterDeposits = () => {
    let filtered = data?.investors || [];

    if (searchTerm.trim()) {
      const lowercasedSearchTerm = searchTerm.toLowerCase();
      filtered = filtered.filter((val) => {
        const investmentIdMatch = val?.name
          ?.toLowerCase()
          .includes(lowercasedSearchTerm);
        const managerIdMatch = val?.cnic
          ?.toLowerCase()
          .includes(lowercasedSearchTerm);
       

        return investmentIdMatch || managerIdMatch;
      });
    }

    setFilteredDeposits(filtered);
    setCurrentPage(1);
  };



  const removeFunction = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#00afef",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        const params = {
          ids: [id],
        };
        axios
          .post(`${Base_url}/v1/admin/delete-investors`,params)
          .then((res) => {
            console.log(res);
            Swal.fire("Deleted!", "Your file has been deleted.", "success");

            window.location.reload();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  };


  return (
    <Super_admin_web_protal
      language={
        <>
          <p className=" sm:block hidden">English | Urdu</p>
        </>
      }
      ToggleBtn={
        <div className=" flex gap-4 items-center">
          <HiOutlineArrowLeft />
          <h2 className="">Investors</h2>
        </div>
      }
      title_props={
        <>
          <div className=" w-96 relative">
            <Input
             onChange={(e) => setSearchTerm(e.target.value)}
              placeholder={""}
              className={" border bg-white w-full rounded-full"}
            />

            <Button
              Icon={<IoSearchOutline className=" text-white text-xl" />}
              label={"search"}
              className={
                " absolute top-0 right-0 bg-secondary  text-white uppercase font-normal rounded-full"
              }
            />
          </div>
        </>
      }
    >
      <div className="  w-full  mb-4  md:hidden block relative">
        <Input
          placeholder={""}
          className={" border bg-white w-full rounded-full"}
        />

        <Button
          Icon={<IoSearchOutline className=" text-white text-xl" />}
          label={"search"}
          className={
            " absolute top-0 right-0 bg-secondary text-white uppercase font-normal rounded-full"
          }
        />
      </div>
      <div className=" sm:flex block    justify-between items-center">
        <div>
          <h2 className="sm:block hidden">Investors</h2>
        </div>

        <div className=" flex gap-3 items-center"></div>
      </div>
      <section className="mb-20 mt-7 text-gray-800">
        <div className="block rounded-lg ">
          <div className="flex overflow-x-auto flex-col">
            <div className=" sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full sm:px-6 lg:px-8">
                <div className="overflow-hidden">
                  <table className="min-w-full mb-0">
                    <thead className=" ">
                      <tr className=" rounded-lg whitespace-nowrap ">
                        <th
                          scope="col"
                          className=" text-sm  text-darkGray  font-medium px-6"
                        >
                          Name
                        </th>
                        <th
                          scope="col"
                          className=" text-sm text-darkGray    font-medium px-6 "
                        >
                          CNIC No
                        </th>

                        <th
                          scope="col"
                          className="text-sm  text-darkGray   font-medium px-6 "
                        >
                          Phone Number
                        </th>

                        <th
                          scope="col"
                          className="text-sm  text-darkGray     font-medium px-6 "
                        >
                          CNIC Copy
                        </th>
                        <th
                          scope="col"
                          className="text-sm  text-darkGray     font-medium px-6 "
                        >
                          Password
                        </th>
                        <th
                          scope="col"
                          className="text-sm    text-darkGray   font-medium px-6 "
                        >
                          Active
                        </th>
                      </tr>
                    </thead>
                    <tbody className="">
                      {currentPageData?.map((item, index) => {
                        return (
                          <>
                            <tr className="bg-white border-t-8 border-b-8    border-[#F6F6F6]  rounded-md ">
                              <th
                                scope="row"
                                className="text-sm font-normal px-6 py-4   whitespace-nowrap "
                              >
                                <Link
                                  onClick={() =>
                                    localStorage.setItem("iName", item?.fullName)
                                  }
                                  to={`/super_admin/investorsDetails/${item?.cnic}/${item?._id}`}
                                  className="mb-0.5 font-medium  text-secondary"
                                >
                                  {item?.fullName}
                                </Link>
                              </th>
                              <td className="align-middle text-sm font-normal px-6 py-4 whitespace-nowrap  text-center">
                                <span className=" text-base text-black  py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline   bg-green-200  rounded-full">
                                  {item?.cnic}
                                </span>
                              </td>

                              <td className="align-middle text-center text-sm font-normal px-6 py-4 whitespace-nowrap text-left">
                                <span className=" text-base text-black  py-1 px-2.5 leading-none  whitespace-nowrap    bg-green-200  rounded-full">
                                  {item?.contactInfo}
                                </span>
                              </td>
                              <td className="align-middle text-center text-sm font-normal px-6 py-4 whitespace-nowrap text-left">
                              <span className=" text-base text-secondary  py-1 px-2.5 leading-none  whitespace-nowrap    bg-green-200  rounded-full">
                                  {item?.images?.slice(-6)}
                                </span>
                              </td>

                              <td className="align-middle  text-center text-sm font-normal px-6 py-4 whitespace-nowrap">
                                <span className=" text-base text-black  py-1 px-2.5 leading-none  whitespace-nowrap    bg-green-200  rounded-full">
                                  {item?.password}
                                </span>
                              </td>
                              <td className="align-middle  cursor-pointer text-sm font-normal px-6  whitespace-nowrap">
                                <span
                                  onClick={() => removeFunction(item?._id)}
                                  className=" text-base text-black   leading-none  cursor-pointer  whitespace-nowrap    bg-green-200  rounded-full"
                                >
                                  <RiDeleteBin5Line className=" text-[#d33]" />
                                </span>
                              </td>
                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className=" flex justify-end items-center  py-4 gap-6">
            <button
              className={`${
                currentPage === 1
                  ? "bg-gray-500  text-white"
                  : "bg-secondary text-white"
              } flex justify-center items-center  w-10 h-10 rounded-md`}
              disabled={currentPage === 1}
              onClick={() => handlePageChange(currentPage - 1)}
            >
              <FaAngleLeft size={20} />
            </button>
            <span className="  text-primary  font-semibold">
              {currentPage} of{" "}
              {Math.ceil(filteredDeposits.length / itemsPerPage)}
            </span>
            <button
              className={`${
                currentPage * itemsPerPage >= filteredDeposits.length
                  ? "bg-gray-500  text-white"
                  : "bg-secondary text-white"
              } flex justify-center items-center  w-10 h-10 rounded-md`}
              disabled={currentPage * itemsPerPage >= filteredDeposits.length}
              onClick={() => handlePageChange(currentPage + 1)}
            >
              <FaAngleRight size={20} />
            </button>
          </div>
        </div>
      </section>
    </Super_admin_web_protal>
  );
};

export default Investors;
