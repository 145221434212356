import { useState } from "react";
import Sidebar from "../components/sidebar/Sidebar";
import AdminNav from "../components/navbar/adminNav/AdminNav";
const Wrapper = ({ children, title_props, ToggleBtn }) => {
  const [side, setSide] = useState("-left-64 md:left-0");

  console.log(side);
  const openSidebar = () => {
    setSide("left-0 md:-left-64");
  };
  const closeSidebar = () => {
    setSide("-left-64 md:left-0");
  };

  return (
    <>
      <div className=" flex">
        <Sidebar side={side} closeSidebar={closeSidebar} />
        <div className=" w-full">
          <AdminNav
            title_props={title_props}
            openSidebar={openSidebar}
            closeSidebar={closeSidebar}
            ToggleBtn={ToggleBtn}
            side={side}
          />

          
      <section
        className={`ml-0 ${
          side === "left-0 md:-left-64" ? "ml-0 " : "ml-0 "
        }  bg-white min-h-screen  px-0`}
      >
        <div className=" text-black   sm:px-4  px-0">{children}</div>
      </section>
        </div>
      </div>

    </>
  );
};
export default Wrapper;
