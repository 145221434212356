import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const rewardService = createApi({
  reducerPath: "reward",
  tagTypes: "rewards",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://welcome-habibi-backend.vercel.app/v1/",
  }),
  endpoints: (builder) => {
    return {
      create: builder.mutation({
        query: (name) => {
          return {
            url: "admin/create-reward",
            method: "POST",
            body: name,
          };
        },
      }),
      updatereward: builder.mutation({
        query: (data) => {
          return {
            url: `update/${data.id}`,
            method: "PUT",
            body: { name: data.name },
          };
        },
      }),
      deletereward: builder.mutation({
        query: (id) => {
          return {
            url: `delete/${id}`,
            method: "DELETE",
          };
        },
      }),

      fetchreward: builder.query({
        query: (id) => {
          return {
            url: `worker/all-reward/${id}`,
            method: "GET",
          };
        },
      }),
      allrewards: builder.query({
        query: (id) => {
          return {
            url:`worker/all-my-reward/${id}`,
            method: "GET",
          };
        },
      }),
      overallrewards: builder.query({
        query: () => {
          return {
            url:`manager/all-rewards`,
            method: "GET",
          };
        },
      }),
    };
  },
});
export const {
  useCreateMutation,
  useFetchrewardQuery,
  useAllrewardsQuery,
  useOverallrewardsQuery,
  useUpdaterewardMutation,
  useDeleterewardMutation,
} = rewardService;
export default rewardService;
