import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { logout } from "../../../store/reducers/authReducer";
import { toast } from "react-toastify";
const WorkSidebar = ({ side, closeSidebar }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };


  const location = useLocation();
  const isActive = (route) => location.pathname.includes(route);


const dispatch = useDispatch();
const navigate = useNavigate();
const adminLogout = () => {
  dispatch(logout('workerToken'));
  toast.success('Worker logout successfully!')
  navigate('/login')
}

  return (
    <div
      className={`fixed top-0  shadow-xl ${side} sm:left-0    w-64 h-screen     z-30 transition-all`}
    >
      <div className="bg-primary   h-full w-64">
        <i
          className="bi bi-x-lg absolute text-black top-4 right-4 sm:hidden block cursor-pointer text-lg"
          onClick={closeSidebar}
        ></i>
        <div className="  px-5 py-8 overflow-y-auto">
          <h1 className=" text-white text-4xl font-medium">
            <img
              src={require("../../../assets/image/logo.png")}
              className="   mx-auto"
              alt=""
            />
            {/* Logo */}
          </h1>
        </div>

        <ul className="">
          <li className="px-4 cursor-pointer transition-all py-3 text-white flex items-center  hover:bg-primary hover:text-white">
            <i class="bi bi-grid-fill  mr-2 inline-block text-xl"></i>
            <Link   to="/workers/my_portal"
              className={`text-md text-white  ${
                isActive("/workers/my_portal")
                  ? " font-semibold"
                  : "font-extralight"
              }  capitalize`}>
              My Portal
            </Link>
          </li>

          <li className="px-4 cursor-pointer  transition-all py-3 text-white flex items-center  hover:bg-primary hover:text-white">
            <i class="bi bi-grid-fill  mr-2 inline-block text-xl"></i>
            <Link to="/workers/plan"  className={`text-md text-white  ${
                isActive("/workers/plan")
                  ? " font-semibold"
                  : "font-extralight"
              }  capitalize`}>
              Plans
            </Link>
          </li>
          <li className="px-4 cursor-pointer  transition-all py-3 text-white flex items-center  hover:bg-primary hover:text-white">
            <i class="bi bi-grid-fill  mr-2 inline-block text-xl"></i>
            <Link to="/workers/investors"  className={`text-md text-white  ${
                isActive("/workers/investors")
                  ? " font-semibold"
                  : "font-extralight"
              }  capitalize`}>
              Investors
            </Link>
          </li>

          <li className="px-4 cursor-pointer  transition-all py-3 text-white flex items-center  hover:bg-primary hover:text-white">
            <i class="bi bi-grid-fill  mr-2 inline-block text-xl"></i>
            <Link to="/workers/deposits" className={`text-md text-white  ${
                isActive("/workers/deposits")
                  ? " font-semibold"
                  : "font-extralight"
              }  capitalize`}>
              Deposits
            </Link>
          </li>

          <li className="px-4 cursor-pointer  transition-all py-3 text-white flex items-center  hover:bg-primary hover:text-white">
            <i class="bi bi-grid-fill  mr-2 inline-block text-xl"></i>
            <Link to="/workers/rewards" className={`text-md text-white  ${
                isActive("/workers/rewards")
                  ? " font-semibold"
                  : "font-extralight"
              }  capitalize`}>
              Rewards
            </Link>
          </li>
          <li className="px-4 cursor-pointer  transition-all py-3 text-white flex items-center  hover:bg-primary hover:text-white">
            <i class="bi bi-grid-fill  mr-2 inline-block text-xl"></i>
            <Link to="/workers/wht_gallery" className={`text-md text-white  ${
                isActive("/workers/wht_gallery")
                  ? " font-semibold"
                  : "font-extralight"
              }  capitalize`}>
              WHT Gallery
            </Link>
          </li>
          <li className="px-4 cursor-pointer font-semibold transition-all py-3 text-white flex items-center  hover:bg-primary hover:text-white">
            <i class="bi bi-grid-fill  mr-2 inline-block text-xl"></i>
            <Link to="/workers/branch_list" className={`text-md text-white  ${
                isActive("/workers/branch_list")
                  ? " font-semibold"
                  : "font-extralight"
              }  capitalize`}>
              Branch List
            </Link>
          </li>

          <li onClick={adminLogout} className="px-4 cursor-pointer font-semibold transition-all py-3 text-white flex items-center  hover:bg-primary hover:text-white">
            <i class="bi bi-grid-fill  mr-2 inline-block text-xl"></i>
            <Link className={`text-md   text-red`}>
              Logout
            </Link>
          </li>


        </ul>
      </div>
      <div
        onClick={closeSidebar}
        className={` bg-[rgba(0,0,0,0.6)]  ${
          side === "-left-64 md:left-0" ? " w-64" : " w-full"
        }     -z-20 h-screen  fixed   top-0   sm:left-0`}
      ></div>
    </div>
  );
};
export default WorkSidebar;
