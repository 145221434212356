import { FiMenu } from "react-icons/fi";
const SuperAdminNav = ({
  openSidebar,
  side,
  closeSidebar,
  title_props,
  language,
  ToggleBtn,
}) => {
  return (
    <nav
      className={`fixed  z-20 bg-white top-0 right-0   ${
        side === "left-0 md:-left-64" ? "left-0" : "left-0 md:left-64"
      }`}
    >
      <div className="   w-full flex justify-between  items-center p-6">
        <div className=" flex items-center gap-4">
          <div onClick={openSidebar} className="  md:hidden  block">
            {ToggleBtn}
          </div>
          <div className="  hidden md:block">{title_props}</div>
        </div>

        <div className=" flex items-center gap-6">
          <div>
            <p>{language}</p>
          </div>
          <div className=" relative">
            <img src={require("../../../assets/image/Notification.png")} />

            <span className=" bg-[#FF6A6A] w-3 h-3 right-0 rounded-full absolute top-0"></span>
          </div>
        </div>
      </div>
    </nav>
  );
};
export default SuperAdminNav;
