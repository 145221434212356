import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const profitDeductionService = createApi({
  reducerPath: "profitDeduction",
  tagTypes: "profitDeductions",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://welcome-habibi-backend.vercel.app/v1/manager/",
  }),
  endpoints: (builder) => {
    return {
      create: builder.mutation({
        query: (name) => {
          return {
            url: "profit-deduction",
            method: "POST",
            body: name,
          };
        },
      }),
      updateprofitDeduction: builder.mutation({
        query: (data) => {
          return {
            url: `update/${data.id}`,
            method: "PUT",
            body: { name: data.name },
          };
        },
      }),
      deleteprofitDeduction: builder.mutation({
        query: (id) => {
          return {
            url: `delete/${id}`,
            method: "DELETE",
          };
        },
      }),

      fetchprofitDeduction: builder.query({
        query: (id) => {
          return {
            url: `get/${id}`,
            method: "GET",
          };
        },
      }),
      allprofitDeductions: builder.query({
        query: () => {
          return {
            url: "all-profit-deduction",
            method: "GET",
          };
        },
      }),
    };
  },
});
export const {
  useCreateMutation,
  useFetchprofitDeductionQuery,
  useAllprofitDeductionsQuery,
  useUpdateprofitDeductionMutation,
  useDeleteprofitDeductionMutation,
} = profitDeductionService;
export default profitDeductionService;
