import React, { useState } from "react";
import { MdClose } from "react-icons/md";
import Modal from "../../components/modal";
import Input from "../../components/Input";
import Button from "../../components/Button";
import { toast } from "react-toastify";
import axios from "axios";

const ForgetPassword = ({ isModalOpen, setIsModalOpen, closeModal,getData }) => {
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");

  console.log(getData);
  

  const onSubmit = async (e) => {
    e.preventDefault();

    if (!password) {
      toast.error('Must Enter Your Password');
      return;
    } else if (password!=newPassword) {
      toast.error('Password and confirm password must be same');
      return;
    }

    setLoading(true);

    const params = {
      cnic: getData?.cnic,
      password: newPassword,
    };

    try {
      const response = await axios.post(`https://welcome-habibi-backend.vercel.app/v1/worker/change-password`, params);
      if (response.data.success) {
        toast.success("Password updated successfully!");
        setPassword(''); // Reset email state
        setNewPassword(''); // Reset newPassword state
        setIsModalOpen(false); // Close the modal
      } else {
        toast.error("Failed to update password");
      }
    } catch (error) {
      console.error(error);
      toast.error("An error occurred!");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Modal isOpen={isModalOpen} className={"md:w-[50%] w-full"} onClose={closeModal}>
        <div className="p-5">
          <div className="flex justify-between items-center">
            <p>Reset Password</p>
            <MdClose className="cursor-pointer text-gray-500" onClick={() => setIsModalOpen(false)} size={25} />
          </div>
          {/* <form onSubmit={onSubmit}> */}
            <div className="flex gap-3 p-5 flex-wrap">
              <div className="w-full">
                <Input label={"Password"} value={password} onChange={(e) => setPassword(e.target.value)} name={"email"} type="password" className={"w-full py-4"} />
              </div>
              <div className="w-full">
                <Input label={"Confirm Password"} name={"newPassword"} type="password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} className={"w-full py-4"} />
              </div>

              {loading ? (
                <button disabled type="button" className="bg-blue text-center mt-3 justify-center uppercase text-white py-3 border-b-black border-2 w-full">
                  <svg aria-hidden="true" role="status" className="inline w-4 h-4 mr-3 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    {/* SVG spinner code here */}
                  </svg>
                  Loading...
                </button>
              ) : (
                <Button  onClick={onSubmit}  label={"Update"}  className={"bg-blue text-center mt-3 justify-center uppercase text-white py-3 border-b-black border-2 w-full"} />
              )}
            </div>
          {/* </form> */}
        </div>
      </Modal>
    </div>
  );
};

export default ForgetPassword;
