import React, { useEffect, useState } from "react";
import { Base_url } from "../../../utils/Base_url";
import axios from "axios";
import Manager_web_portal from "../../../layout/Manager_web_portal";
import { Link } from "react-router-dom";
import { useAllworkersQuery } from "../../../store/services/workerService";
import { useAllcollectionsQuery } from "../../../store/services/collectionService";
const Dashboard = () => {
 
  const { data:dataWorker} = useAllworkersQuery()
  console.log(dataWorker);



   
  const { data:dataCollection} = useAllcollectionsQuery()
  console.log(dataCollection);



  return (
    <Manager_web_portal
    language={<>
      <p>English | Urdu</p>
      </>}
      
    
      title_props={
        <h1 className="main_title">Welcome Habibi Trading </h1>
      }
      ToggleBtn={
        <div className="  md:hidden  block">
          <img
            // onClick={openSidebar}
            src={require("../../../assets/image/menu.png")}
            alt=""
          />
        </div>
      }
    >
      <h2 className="">Workers</h2>
      <section>
        <div className=" py-4  w-full grid  grid-cols-1  xl:grid-cols-3 md:grid-cols-2 gap-5">
          <div className=" w-full">
            <Link to={'/manager/collection'} className="  bg-[#FF9A3E] p-5 pb-7 rounded-2xl flex  justify-between">
              <div>
                <span className=" text-white text-lg font-medium">
                  Collection
                </span>
                <div className="  flex items-center gap-3 pt-7">
                  <p className="text-4xl text-white  font-bold">
                    {dataCollection?.totalInvestment} <sub className=" text-lg">(PKR)</sub>{" "}
                  </p>
                </div>
              </div>
            </Link>
          </div>
          <div className=" w-full">
            <Link to={'/manager/workers_list'} className="  bg-[#39593C] p-5 pb-7 rounded-2xl flex  justify-between">
              <div>
                <span className=" text-white text-lg font-medium">
                  Workers List
                </span>
                <div className="  flex items-center gap-3 pt-7">
                  <p className="text-4xl text-white  font-bold">{dataWorker?.workers?.length} </p>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </section>
    </Manager_web_portal>
  );
};

export default Dashboard;
