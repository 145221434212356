import React, { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import Worker_web_protal from "../../../layout/Worker_web_portal";
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import { Base_url } from "../../../utils/Base_url";
import { IoSearchOutline } from "react-icons/io5";
import { HiOutlineArrowLeft } from "react-icons/hi";
import { useAllrewardsQuery } from "../../../store/services/rewardService";
import { useSelector } from "react-redux";
import { VscFilter } from "react-icons/vsc";
import { GoTriangleDown } from "react-icons/go";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import moment from "moment";
const Rewards = () => {
  const { workerToken } = useSelector((state) => state.authReducer);
  const { data, isFetching } = useAllrewardsQuery(workerToken);
  console.log(data, isFetching);

  const [searchTerm, setSearchTerm] = useState("");
  const [minValue, setMinValue] = useState("");
  const [maxValue, setMaxValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filteredDeposits, setFilteredDeposits] = useState([]);
  useEffect(() => {
    filterDeposits();
  }, [data, searchTerm, minValue, maxValue]);

  const toggleDropdown = () => setDropdownVisible(!dropdownVisible);

  const filterDeposits = () => {
    let filtered = data?.rewards?.slice().reverse() || [];
    if (searchTerm) {
      filtered = filtered.filter(
        (deposit) =>
          deposit.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          deposit.cnic.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
    if (minValue || maxValue) {
      const min = parseFloat(minValue) || Number.MIN_SAFE_INTEGER;
      const max = parseFloat(maxValue) || Number.MAX_SAFE_INTEGER;
      filtered = filtered.filter((deposit) => {
        const amount = parseFloat(deposit.amount);
        return amount >= min && amount <= max;
      });
    }
    setFilteredDeposits(filtered);
    setCurrentPage(1); // Reset to the first page after filtering
  };

  const currentPageData = filteredDeposits.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (newPage) => setCurrentPage(newPage);

  return (
    <Worker_web_protal
      language={
        <>
          <p className=" sm:block  hidden">English | Urdu</p>
        </>
      }
      ToggleBtn={
        <div className=" flex gap-4 items-center">
          <HiOutlineArrowLeft />
          <h2 className="">Rewards</h2>
        </div>
      }
      title_props={
        <>
          {/* <div className=" w-96 relative">
            <Input
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder={""}
              className={" border bg-white w-full rounded-full"}
            />

            <Button
              Icon={<IoSearchOutline className=" text-white text-xl" />}
              label={"search"}
              className={
                " absolute top-0 right-0 bg-secondary  text-white uppercase font-normal rounded-full"
              }
            />
          </div> */}
            <h1 className="  font-semibold text-xl  text-primary pb-4">
                Welcome Habibi Trading 
              </h1>
        </>
      }
    >
      <div className="  w-full  mb-4  sm:hidden block relative">
        <Input
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder={""}
          className={" border bg-white w-full rounded-full"}
        />

        <Button
          Icon={<IoSearchOutline className=" text-white text-xl" />}
          label={"search"}
          className={
            " absolute top-0 right-0 bg-secondary text-white uppercase font-normal rounded-full"
          }
        />
      </div>

      <div className=" sm:flex block    justify-between items-center">
        <div>
          <h2 className=" sm:block  hidden">Rewards</h2>
        </div>

        {/* <div className=" ">
          <Button
            Icon={<VscFilter size={27} />}
            Icons={<GoTriangleDown />}
            label={"Select Filters"}
            onClick={toggleDropdown}
            className={" bg-white font-normal text-darkGray py-3 rounded-full"}
          />
          {dropdownVisible && (
            <div className="absolute z-10 p-3 mt-2 w-80 rounded-md shadow-lg bg-white right-0 ring-black ring-opacity-5">
              <div className="py-1 gap-4 flex">
                <div className=" py-2">
                  <label
                    htmlFor="minValue"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Min Value
                  </label>
                  <Input
                    type="number"
                    id="minValue"
                    value={minValue}
                    onChange={(e) => setMinValue(e.target.value)}
                    className="mt-1 p-2 w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
                <div className=" py-2">
                  <label
                    htmlFor="maxValue"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Max Value
                  </label>
                  <Input
                    type="number"
                    id="maxValue"
                    value={maxValue}
                    onChange={(e) => setMaxValue(e.target.value)}
                    className="mt-1 p-2 w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
              </div>
              
                <Button
                  onClick={handleSearch}
                  label={"Search"}
                  className={
                    "  bg-secondary rounded-full w-full justify-center text-white"
                  }
                />
            </div>
          )}
        </div> */}
      </div>
      <section className="mb-20 mt-7 text-gray-800">
        <div className="block rounded-lg ">
          <div className="flex overflow-x-auto flex-col">
            <div className=" sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full sm:px-6 lg:px-8">
                <div className="overflow-hidden">
                  <table className="min-w-full mb-0">
                    <thead className=" ">
                      <tr className=" rounded-lg whitespace-nowrap ">
                        <th
                          scope="col"
                          className=" text-sm  text-darkGray  font-medium px-6"
                        >
                          Date
                        </th>
                        <th
                          scope="col"
                          className=" text-sm  text-darkGray  font-medium px-6"
                        >
                          Investment ID
                        </th>
                        <th
                          scope="col"
                          className=" text-sm text-darkGray    font-medium px-6 "
                        >
                          Name
                        </th>
                        <th
                          scope="col"
                          className=" text-sm text-darkGray   font-medium px-6 "
                        >
                          CNIC No
                        </th>

                        {/* <th
                          scope="col"
                          className="text-sm  text-darkGray   font-medium px-6 "
                        >
                          Remarks
                        </th> */}

                        <th
                          scope="col"
                          className="text-sm  text-darkGray     font-medium px-6 "
                        >
                          Deposit Amount
                        </th>
                        <th
                          scope="col"
                          className="text-sm  text-darkGray     font-medium px-6 "
                        >
                          Reward Amount
                        </th>
                        <th
                          scope="col"
                          className="text-sm    text-darkGray   font-medium px-6 "
                        >
                          Status
                        </th>
                      </tr>
                    </thead>
                    <tbody className="">
                      {currentPageData?.map((item, index) => {
                        if (item?.status === "approved") {
                          return (
                            <>
                              <tr className="bg-white border-t-8 border-b-8    border-[#F6F6F6]  rounded-md ">
                                <th
                                  scope="row"
                                  className="text-sm font-normal px-6 py-4   whitespace-nowrap "
                                >
                                  <p className="mb-0.5 font-medium  text-black">
                                    {moment(item?.createdAt).format(
                                      "DD/MM/YYYY"
                                    )}
                                  </p>
                                </th>
                                <th
                                  scope="row"
                                  className="text-sm font-normal px-6 py-4   whitespace-nowrap "
                                >
                                  <p className="mb-0.5 font-medium  text-black">
                                    {item?.investment}
                                  </p>
                                </th>
                                <td className="align-middle text-sm font-normal px-6 py-4 whitespace-nowrap  text-center">
                                  <span className=" text-base text-black  py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline   bg-green-200  rounded-full">
                                    {item?.name}
                                  </span>
                                </td>
                                <td className="text-sm font-normal text-center px-6 py-4 whitespace-nowrap">
                                  <span className=" text-base  text-black py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline   bg-green-200  rounded-full">
                                    {item?.cnic}
                                  </span>
                                </td>
                                {/* <td className="align-middle text-center text-sm font-normal px-6 py-4 whitespace-nowrap text-left">
                                  <span className=" text-base text-black  py-1 px-2.5 leading-none  whitespace-nowrap    bg-green-200  rounded-full">
                                    {item?.remarks}
                                  </span>
                                </td> */}
                                <td className="align-middle text-center text-sm font-normal px-6 py-4 whitespace-nowrap text-left">
                                  <span className=" text-base text-black  py-1 px-2.5 leading-none  whitespace-nowrap    bg-green-200  rounded-full">
                                    {item?.depositAmount}
                                  </span>
                                </td>
                                <td className="align-middle text-center text-sm font-normal px-6 py-4 whitespace-nowrap text-left">
                                  <span className=" text-base text-black  py-1 px-2.5 leading-none  whitespace-nowrap    bg-green-200  rounded-full">
                                    {item?.amount}
                                  </span>
                                </td>

                                <td className="align-middle  text-sm font-normal px-6  whitespace-nowrap">
                                  <span className=" text-base text-black   px-2.5 leading-none  whitespace-nowrap    bg-green-200  rounded-full">
                                    {item?.status2 === "paid" ? (
                                      <span className=" text-base  text-green bg-lightGray py-2 cursor-not-allowed px-3 leading-none  flex justify-center  whitespace-nowrap    bg-green-200  rounded-full">
                                        Paid
                                      </span>
                                    ) : (
                                      <span className="  text-base  text-[#EF434E]  bg-[#fdeced] cursor-not-allowed py-2 px-3 leading-none  whitespace-nowrap    bg-green-200  rounded-full">
                                        Unpaid
                                      </span>
                                    )}
                                  </span>
                                </td>
                              </tr>
                            </>
                          );
                        }
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className=" flex justify-end items-center  py-4 gap-6">
            <button
              className={`${
                currentPage === 1
                  ? "bg-gray-500  text-white"
                  : "bg-secondary text-white"
              } flex justify-center items-center  w-10 h-10 rounded-md`}
              disabled={currentPage === 1}
              onClick={() => handlePageChange(currentPage - 1)}
            >
              <FaAngleLeft size={20} />
            </button>
            <span className="  text-primary  font-semibold">
              {currentPage} of{" "}
              {Math.ceil(filteredDeposits.length / itemsPerPage)}
            </span>
            <button
              className={`${
                currentPage * itemsPerPage >= filteredDeposits.length
                  ? "bg-gray-500  text-white"
                  : "bg-secondary text-white"
              } flex justify-center items-center  w-10 h-10 rounded-md`}
              disabled={currentPage * itemsPerPage >= filteredDeposits.length}
              onClick={() => handlePageChange(currentPage + 1)}
            >
              <FaAngleRight size={20} />
            </button>
          </div>
        </div>
      </section>
    </Worker_web_protal>
  );
};

export default Rewards;
