import React, { useState } from "react";
import Button from "../../../components/Button";
import Worker_web_protal from "../../../layout/Worker_web_portal";
import { HiOutlineArrowLeft } from "react-icons/hi";
import { IoSearchOutline } from "react-icons/io5";
import Input from "../../../components/Input";
import Manager_web_portal from "../../../layout/Manager_web_portal";
import { useAlleventsQuery } from "../../../store/services/eventService";
import Add_wht_gallery from "./Add_wht_gallery";
import { FiPlus } from "react-icons/fi";
import Super_admin_web_protal from "../../../layout/Super_admin_web_portal";
import { RiDeleteBinLine } from "react-icons/ri";
import Swal from "sweetalert2";
import { Base_url } from "../../../utils/Base_url";
import axios from "axios";
import ViewEvents from "../../ViewEvents/ViewEvents";

const WhtGallery = () => {
  const { data, isFetching } = useAlleventsQuery();
  console.log(data, isFetching);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };




  const [isModalOpen2, setIsModalOpen2] = useState(false);

  const openModal2 = () => {
    setIsModalOpen2(true);
  };

  const closeModal2 = () => {
    setIsModalOpen2(false);
  };


  const [view,setView] = useState({})

  const removeFunction = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#00afef",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${Base_url}/v1/manager/delete-event/${id}`)
          .then((res) => {
            console.log(res);
            Swal.fire("Deleted!", "Your file has been deleted.", "success");

            window.location.reload();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  };



  return (
    <Manager_web_portal
      language={
        <>
          <p className=" sm:block  hidden">English | Urdu</p>
        </>
      }
      ToggleBtn={
        <div className=" flex gap-4 items-center">
          <HiOutlineArrowLeft />
          <h2 className=""> WHT GALLERY</h2>
        </div>
      }
      title_props={
        <>
          <div className=" w-96 relative">
            <Input
              placeholder={""}
              className={" border bg-white w-full rounded-full"}
            />

            <Button
              Icon={<IoSearchOutline className=" text-white text-xl" />}
              label={"search"}
              className={
                " absolute top-0 right-0 bg-secondary  text-white uppercase font-normal rounded-full"
              }
            />
          </div>
        </>
      }
    >
      <Add_wht_gallery
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        // setUsers={setUsers}
      />


<ViewEvents
        isModalOpen={isModalOpen2}
        setIsModalOpen={setIsModalOpen2}
        getData={view}
      />

      <div className=" container m-auto">
        <div className=" flex justify-between gap-3 items-center">
          <h1 className=" font-semibold text-xl"> WHT GALLERY</h1>
          <Button
            onClick={openModal}
            Icon={<FiPlus />}
            label={"Add Event"}
            className={
              " bg-secondary text-white  font-normal py-3 rounded-full"
            }
          />
        </div>

        <div className="  w-full  mb-4  sm:hidden block relative">
          <Input
            placeholder={""}
            className={" border bg-white w-full rounded-full"}
          />

          <Button
            Icon={<IoSearchOutline className=" text-white text-xl" />}
            label={"search"}
            className={
              " absolute top-0 right-0 bg-secondary text-white uppercase font-normal rounded-full"
            }
          />
        </div>

        <div className=" my-12   grid  md:grid-cols-2 grid-cols-1 gap-12">
          {data?.events?.map((item, index) => {
            return (
              <div className=" ">
                <p className=" text-primary font-semibold text-xl text-center py-3">
                  {item?.title}
                </p>
                <div className=" relative h-96">
                  <img
                    src={item?.images[0]}
                    className="  w-full h-full  object-cover"
                    alt=""
                  />
                  <div className=" absolute  top-0  bg-[rgba(0,0,0,0.1)]   h-full rounded-md w-full">
                    <div
                      onClick={() => removeFunction(item?._id)}
                      className=" cursor-pointer w-6 h-6 rounded-md  flex justify-center items-center bg-white absolute right-3 top-3"
                    >
                      <RiDeleteBinLine className=" text-red" />
                    </div>
                    <div className=" h-full     pb-4 flex justify-end items-end">
                      <Button

                         onClick={()=>{openModal2()

                            setView(item)

                         }}
                        label={"Show More"}
                        className={
                          " bg-secondary    justify-center w-36 mx-auto text-white  py-3.5"
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Manager_web_portal>
  );
};

export default WhtGallery;
