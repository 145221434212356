import React, { useEffect, useState } from "react";
import axios from "axios";
import { Base_url } from "../../../utils/Base_url";
import Swal from "sweetalert2";
import { VscFilter } from "react-icons/vsc";
import { GoTriangleDown } from "react-icons/go";
import Input from "../../../components/Input";
import Button from "../../../components/Button";
import { FiPlus } from "react-icons/fi";
import AddProfits_and_deductions from "./AddProfits_and_deductions";
import Manager_web_portal from "../../../layout/Manager_web_portal";
import { HiOutlineArrowLeft } from "react-icons/hi";
import { IoSearchOutline } from "react-icons/io5";
import { useAllprofitDeductionsQuery } from "../../../store/services/profitDeductionService";
import moment from "moment";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
const Profits_and_deducations = () => {
  const [users, setUsers] = useState([]);
  const [isUpdateOpen, setIsUpdateOpen] = useState(false);
  const [singleUser, setSingleUser] = useState({});
  const UpdateModal = () => {
    setIsUpdateOpen(true);
  };

  const closeUpdateModal = () => {
    setIsUpdateOpen(false);
  };

  console.log(isUpdateOpen);

  const [deleteUser, setDeleteUser] = useState({});

  console.log(deleteUser, "deleteUser");

  useEffect(() => {
    axios
      .get(`${Base_url}/customer/getAll`)
      .then((res) => {
        console.log(res);

        setUsers(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  console.log(users);



  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const { data, isFetching } = useAllprofitDeductionsQuery();
  console.log(data, isFetching);

  const [searchTerm, setSearchTerm] = useState("");
  const [minValue, setMinValue] = useState("");
  const [maxValue, setMaxValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filteredDeposits, setFilteredDeposits] = useState([]);
  useEffect(() => {
    filterDeposits();
  }, [data, searchTerm, minValue, maxValue]);

  const toggleDropdown = () => setDropdownVisible(!dropdownVisible);

  const filterDeposits = () => {
    let filtered = data?.profitDeductions?.slice()
    .reverse() || [];
    if (searchTerm) {
      filtered = filtered.filter(
        (deposit) =>
          deposit.investment.toLowerCase().includes(searchTerm.toLowerCase()) ||
        deposit.managerName.toLowerCase().includes(searchTerm.toLowerCase())
        
      );
    }
    if (minValue || maxValue) {
      const min = parseFloat(minValue) || Number.MIN_SAFE_INTEGER;
      const max = parseFloat(maxValue) || Number.MAX_SAFE_INTEGER;
      filtered = filtered?.filter((deposit) => {
        const amount = parseFloat(deposit?.deduction?.amount);
        return amount >= min && amount <= max;
      });
    }
    setFilteredDeposits(filtered);
    setCurrentPage(1); // Reset to the first page after filtering
  };

  const currentPageData = filteredDeposits.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (newPage) => setCurrentPage(newPage);

  return (
    <Manager_web_portal
      language={
        <>
          <p className=" sm:block hidden">English | Urdu</p>
        </>
      }
      ToggleBtn={
        <div className=" flex gap-4 items-center">
          <HiOutlineArrowLeft />
          <h2 className="">Profits and Deductions</h2>
        </div>
      }
      title_props={
        <>
          <div className=" w-96 relative">
            <Input
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder={""}
              className={" border bg-white w-full rounded-full"}
            />

            <Button
              Icon={<IoSearchOutline className=" text-white text-xl" />}
              label={"search"}
              className={
                " absolute top-0 right-0 bg-secondary  text-white uppercase font-normal rounded-full"
              }
            />
          </div>
        </>
      }
    >
      <AddProfits_and_deductions
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        setUsers={setUsers}
      />

      <div className="  w-full  mb-4  md:hidden block relative">
        <Input
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder={""}
          className={" border bg-white w-full rounded-full"}
        />

        <Button
          Icon={<IoSearchOutline className=" text-white text-xl" />}
          label={"search"}
          className={
            " absolute top-0 right-0 bg-secondary  text-white uppercase font-normal rounded-full"
          }
        />
      </div>

      <div className=" sm:flex    justify-between items-center">
        <div>
          <h2 className=" sm:block hidden">Profits and Deductions</h2>
        </div>

        <div className=" flex relative gap-3 items-center justify-between">
          <div>
            <Button
              onClick={openModal}
              Icon={<FiPlus />}
              label={"Add New"}
              className={
                " bg-secondary text-white  font-normal py-3 rounded-full"
              }
            />
          </div>

          <div className=" ">
            <Button
              Icon={<VscFilter size={27} />}
              Icons={<GoTriangleDown />}
              label={"Select Filters"}
              onClick={toggleDropdown}
              className={
                " bg-white font-normal text-darkGray py-3 rounded-full"
              }
            />
            {dropdownVisible && (
              <div className="absolute z-10 p-3 mt-2 w-80 rounded-md shadow-lg bg-white right-0 ring-black ring-opacity-5">
                <div className="py-1 gap-4 flex">
                  <div className=" py-2">
                    <label
                      htmlFor="minValue"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Min Value
                    </label>
                    <Input
                      type="number"
                      id="minValue"
                      value={minValue}
                      onChange={(e) => setMinValue(e.target.value)}
                      className="mt-1 p-2 w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>
                  <div className=" py-2">
                    <label
                      htmlFor="maxValue"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Max Value
                    </label>
                    <Input
                      type="number"
                      id="maxValue"
                      value={maxValue}
                      onChange={(e) => setMaxValue(e.target.value)}
                      className="mt-1 p-2 w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>

                {/* <Button
                  onClick={handleSearch}
                  label={"Search"}
                  className={
                    "  bg-secondary rounded-full w-full justify-center text-white"
                  }
                /> */}
              </div>
            )}
          </div>
        </div>
      </div>
      <section className="mb-20 mt-7 text-gray-800">
        <div className="block rounded-lg ">
          <div className="flex overflow-hidden flex-col">
            <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full sm:px-6 lg:px-8">
                <div className="">
                  <table className="min-w-full mb-0 m-3">
                    <thead className=" ">
                      <tr className=" rounded-lg whitespace-nowrap ">
                        <th
                          scope="col"
                          className=" text-sm  text-darkGray font-semibold px-6 py-4"
                        >
                          Date
                        </th>
                        <th
                          scope="col"
                          className=" text-sm text-darkGray    font-semibold px-6 py-4"
                        >
                          Investment ID
                        </th>
                        <th
                          scope="col"
                          className=" text-sm text-darkGray   font-semibold px-6 py-4"
                        >
                          Profit 
                        </th>

                        <th
                          scope="col"
                          className="text-sm  text-darkGray     font-semibold px-6 py-4"
                        >
                          Deduction Type
                        </th>

                        <th
                          scope="col"
                          className="text-sm  text-darkGray     font-semibold px-6 py-4"
                        >
                          Deduction Amount
                        </th>
                        <th
                          scope="col"
                          className="text-sm    text-darkGray   font-semibold px-6 py-4"
                        >
                          Remarks
                        </th>

                        <th
                          scope="col"
                          className="text-sm   text-darkGray   font-semibold px-6 py-4"
                        >
                          Added by
                        </th>
                      </tr>
                    </thead>
                    <tbody className="">
                      {currentPageData?.map((item, index) => {
                        return (
                          <>
                            <tr className="bg-white  border-t-8   border-lightGray  rounded-md ">
                              <th
                                scope="row"
                                className="text-sm font-normal px-6 py-4   whitespace-nowrap "
                              >
                                <p className="mb-0.5 font-medium text-black">
                                

                                  {moment(item?.createdAt)
                                    .format('DD/MM/YYYY')}
                                </p>
                              </th>
                              <td className="align-middle text-sm font-normal px-6 py-4 whitespace-nowrap  text-center">
                                <span className=" text-base text-black  py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline   bg-green-200  rounded-full">
                                  {item?.investment}
                                </span>
                              </td>

                              <td className="align-middle text-center text-sm font-normal px-6 py-4 whitespace-nowrap text-left">
                                <span className=" text-base text-black  py-1 px-2.5 leading-none  whitespace-nowrap    bg-green-200  rounded-full">
                                  {item?.profit===0?'-':item?.profit}
                                </span>
                              </td>
                              <td className="align-middle text-center text-sm font-normal px-6 py-4 whitespace-nowrap text-left">
                                <span className=" text-base text-black  py-1 px-2.5 leading-none  whitespace-nowrap    bg-green-200  rounded-full">
                                  {item?.deduction?.type?item?.deduction?.type:'-'}
                                </span>
                              </td>
                              <td className="align-middle text-center text-sm font-normal px-6 py-4 whitespace-nowrap text-left">
                                <span className=" text-base text-black  py-1 px-2.5 leading-none  whitespace-nowrap    bg-green-200  rounded-full">
                                  {item?.deduction?.amount===0?'-':item?.deduction?.amount}
                                </span>
                              </td>

                              <td className="align-middle  text-sm font-normal px-6 py-4 whitespace-nowrap">
                                <span className=" text-base text-black  py-1 px-2.5 leading-none  whitespace-nowrap    bg-green-200  rounded-full">
                                  {item?.remarks}
                                </span>
                              </td>
                              <td className="align-middle  text-sm font-normal px-6 py-4 whitespace-nowrap">
                                <span className=" text-base text-black  py-1 px-2.5 leading-none  whitespace-nowrap    bg-green-200  rounded-full">
                                  {item?.managerName}
                                </span>
                              </td>
                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className=" flex justify-end items-center  py-4 gap-6">
            <button
              className={`${
                currentPage === 1
                  ? "bg-gray-500  text-white"
                  : "bg-secondary text-white"
              } flex justify-center items-center  w-10 h-10 rounded-md`}
              disabled={currentPage === 1}
              onClick={() => handlePageChange(currentPage - 1)}
            >
              <FaAngleLeft
               size={20} />
            </button>
            <span className="  text-primary  font-semibold">
              {currentPage} of{" "}
              {Math.ceil(filteredDeposits.length / itemsPerPage)}
            </span>
            <button
              className={`${
                currentPage * itemsPerPage >= filteredDeposits.length
                  ? "bg-gray-500  text-white"
                  : "bg-secondary text-white"
              } flex justify-center items-center  w-10 h-10 rounded-md`}
              disabled={currentPage * itemsPerPage >= filteredDeposits.length}
              onClick={() => handlePageChange(currentPage + 1)}
            >
              <FaAngleRight size={20} />
            </button>
          </div>
        </div>
      </section>
    </Manager_web_portal>
  );
};

export default Profits_and_deducations;
