import { Link } from "react-router-dom";
import Footer from "../../components/footer";
import ContactUs from "../../components/contactUs";
import Wrapper from "../../layout/Wrapper";
import Input from "../../components/Input";
import Button from "../../components/Button";
import { IoSearchOutline } from "react-icons/io5";
import { useAllbranchsQuery } from "../../store/services/branchService";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { RiMapPinFill } from "react-icons/ri";
import { useAlleventsQuery } from "../../store/services/eventService";
import { useEffect, useState } from "react";
import { GoogleMap, LoadScript, MarkerF } from "@react-google-maps/api";
import { MdLocationPin } from "react-icons/md";
import ViewEvents from "../ViewEvents/ViewEvents";
import { useAllalertsQuery } from "../../store/services/alertService";
const Home = () => {
  // const position = [32.0837411,72.67185960000006];

  // const customIcon = L.icon({
  //   iconUrl:require('../../assets/image/map.png'),
  //   iconSize: [35, 35],
  //   iconAnchor: [17, 35],
  //   popupAnchor: [0, -35],
  // });

  const { data, isFetching } = useAlleventsQuery();
  console.log(data, isFetching);

  const { data: alert, isFetching: Fetching, refetch } = useAllalertsQuery();

  const [currentLocation, setCurrentLocation] = useState({
    lat: 32.08574,
    lng: 72.659829,
  });

  const libraries = ["places"];

  const [map, setMap] = useState(null);
  const [autocomplete, setAutocomplete] = useState(null);
  // State variables for address, latitude, and longitude
  const [address, setAddress] = useState("");
  const [lat, setLat] = useState(currentLocation.lat);
  const [lng, setLng] = useState(currentLocation.lng);

  useEffect(() => {
    const getCurrentLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            // const pos = {
            //   lat: position.coords.latitude,
            //   lng: position.coords.longitude,
            // };
            // setCurrentLocation(pos);
            // setLat(pos.lat);
            // setLng(pos.lng);
            // map?.panTo(pos);
          },
          () => {
            console.error("The Geolocation service failed check server.");
          }
        );
      } else {
        console.error("Your browser doesn't support geolocation.");
      }
    };

    getCurrentLocation();
  }, [map]);

  const onLoad = (autocomplete) => {
    console.log("autocomplete: ", autocomplete);
    setAutocomplete(autocomplete);
  };

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      console.log(place);
      setCurrentLocation({
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      });
      // Update state with the selected place's details
      setAddress(place.formatted_address || "");
      setLat(place.geometry.location.lat());
      setLng(place.geometry.location.lng());
      map.panTo(place.geometry.location);
    } else {
      console.log("Autocomplete is not loaded yet!");
    }
  };

  const containerStyle = {
    width: "100%",
    height: "500px",
  };

  const [isModalOpen2, setIsModalOpen2] = useState(false);

  const openModal2 = () => {
    setIsModalOpen2(true);
  };

  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const [view, setView] = useState({});

  return (
    <>
      <Wrapper
        ToggleBtn={
          <div className="  md:hidden  block">
            <img
              // onClick={openSidebar}
              src={require("../../assets/image/menu.png")}
              alt=""
            />
          </div>
        }
        title_props={
          <>
            <h1 className="main_title">Welcome Habibi Trading </h1>
          </>
        }
        className="   flex justify-center items-center"
      >
        <form className="  bg-white shadow-2xl md:flex  block w-full rounded">
          <div className=" text-center  md:w-[50%] w-full">
            <img
              src={require("../../assets/image/login_bg.png")}
              className=" h-full  object-cover"
              alt=""
            />
          </div>
          <ViewEvents
            isModalOpen={isModalOpen2}
            setIsModalOpen={setIsModalOpen2}
            getData={view}
          />
          <div className=" bg-[#0054A5] bg-BG bg-center bg-cover md:px-14 px-5 py-3 flex items-center justify-center md:w-[50%]  w-full">
            <div>
              <h1 className="  font-semibold text-4xl text-white pb-4">
                Welcome Habibi Trading
              </h1>
              <p className=" text-white font-light text-lg ">
                We are building your future so feel safe & secure. A Riba Free
                business with sharia guidance.
              </p>
              <div className=" mt-8 bg-white">
                <button
                  type="submit"
                  value={"WHT ALERTS"}
                  className="   bg-blue   text-xl text-center w-full   py-3  text-white  font-semibold cursor-pointer"
                >
                  WHT Alerts
                </button>
                <div>
                  <ul className="  h-52 overflow-y-scroll">
                    {alert?.data?.map((item, index) => {
                      return (
                        <>
                          <li className=" p-4 text-center text-sm">
                            {item?.message}
                          </li>
                          <hr />
                        </>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </form>
      </Wrapper>

      <div
        id="gallerySection"
        className=" container m-auto md:px-16  px-6 md:py-20 py-12"
      >
        <div className=" md:w-[40%]  w-full mx-auto text-center">
          <h1 className=" font-semibold text-4xl"> WHT GALLERY</h1>
          <p className=" py-6">A journey to the Bright future.</p>
        </div>

        <div className=" my-12   grid md:grid-cols-2 grid-cols-1 gap-12">
          {data?.events?.map((item, index) => {
            return (
              <div className=" ">
                <p className=" text-primary font-semibold text-xl text-center py-3">
                  {item?.title}
                </p>
                <div className=" relative h-96">
                  <img
                    src={item?.images[0]}
                    className="  w-full h-full  object-cover"
                    alt=""
                  />
                  <div className=" absolute  top-0  bg-[rgba(0,0,0,0.1)]   h-full rounded-md w-full">
                    <div className=" h-full     pb-4 flex justify-end items-end">
                      <Button
                        onClick={() => {
                          openModal2();

                          setView(item);
                        }}
                        label={"Show More"}
                        className={
                          " bg-secondary    justify-center w-36 mx-auto text-white  py-3.5"
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className=" relative" id="contactUsSection">
        <ContactUs />
        <div className=" mx-auto   md:w-[90%]  w-[100%]  md:left-16 left-0 md:bottom-12 bottom-0  md:absolute relative">
          {/* <MapContainer center={position} zoom={13} style={{ height: '75vh', width: '100%' }}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      <Marker position={position} icon={customIcon} >
        <Popup>
          A simple popup. <br /> Easily customizable.
        </Popup>
      </Marker>
    </MapContainer> */}

          <div className=" ">
            <LoadScript
              googleMapsApiKey={"AIzaSyA0ezzOFq6lTHs4i4DlmMPJpV48LAWMx7o"}
              libraries={libraries}
            >
              <GoogleMap
                mapContainerStyle={containerStyle}
                center={currentLocation}
                zoom={10}
                onLoad={(map) => setMap(map)}
              >
                <MarkerF
                  position={currentLocation}
                  icon={<MdLocationPin className="text-textColor" size={20} />}
                />
              </GoogleMap>
            </LoadScript>
          </div>
        </div>
        <div className="  md:h-[55vh] h-auto"></div>
      </div>

      <Footer />
    </>
  );
};
export default Home;
