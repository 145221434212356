import React, { useEffect, useState } from "react";
import Input from "../../../components/Input";
import Button from "../../../components/Button";
import { FiPlus } from "react-icons/fi";
import Manager_web_portal from "../../../layout/Manager_web_portal";
import { IoSearchOutline } from "react-icons/io5";
import { HiOutlineArrowLeft } from "react-icons/hi";
import moment from "moment";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import AddAlerts from "./AddAlerts";
import { RiDeleteBin5Line } from "react-icons/ri";
import { PiPencilSimpleFill } from "react-icons/pi";
import { useAllalertsQuery } from "../../../store/services/alertService";
import Swal from "sweetalert2";
import { Base_url } from "../../../utils/Base_url";
import axios from "axios";
import EditAlerts from "./EditAlerts";
const Alerts = () => {


  
  const { data, isFetching,refetch} = useAllalertsQuery();
  console.log(data, isFetching);


  const [users, setUsers] = useState([]);
  
  const [isUpdateOpen, setIsUpdateOpen] = useState(false);
  const [singleUser, setSingleUser] = useState({});
  const UpdateModal = () => {
    setIsUpdateOpen(true);
  };

  const closeUpdateModal = () => {
    setIsUpdateOpen(false);
  };

  console.log(isUpdateOpen);


  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };


  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [filteredDeposits, setFilteredDeposits] = useState([]);
  const currentPageData = filteredDeposits.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (newPage) => setCurrentPage(newPage);

  useEffect(() => {
    filterDeposits();
  }, [data, searchTerm]);

  const filterDeposits = () => {
    let filtered = data?.data?.slice()
    .reverse() || [];

    if (searchTerm.trim()) {
      const lowercasedSearchTerm = searchTerm.toLowerCase();
      filtered = filtered.filter((val) => {
        const investmentIdMatch = val?.investmentId
          ?.toLowerCase()
          .includes(lowercasedSearchTerm);
        const managerIdMatch = val?.managerData?.name
          ?.toLowerCase()
          .includes(lowercasedSearchTerm);
        const statusMatch = val?.status
          ?.toLowerCase()
          .includes(lowercasedSearchTerm);

        return investmentIdMatch || managerIdMatch || statusMatch;
      });
    }

    setFilteredDeposits(filtered);
    setCurrentPage(1);
  };


  const [fetchUser,setFetchUser] = useState({});


  const removeFunction = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#00afef",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${Base_url}/v1/manager/delete-alert/${id}`)
          .then((res) => {
            console.log(res);
            Swal.fire("Deleted!", "Your file has been deleted.", "success");

            refetch()
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  };

  return (
    <Manager_web_portal
    language={
      <>
        <p className=" sm:block hidden">English | Urdu</p>
      </>
    }
    ToggleBtn={
      <div className=" flex gap-4 items-center">
        <HiOutlineArrowLeft/>
        <h2 className="">Requests</h2>
      </div>
    }

      title_props={
        <>
         <h1 className="  font-semibold text-xl  text-primary ">
                Welcome Habibi Trading 
              </h1>
        </>
      }
    >
      <AddAlerts
        isModalOpen={isUpdateOpen}
        setIsModalOpen={setIsUpdateOpen}
        setUsers={refetch}
      />


<EditAlerts
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        setUsers={setUsers}
        getData={fetchUser}
      />

      <div className=" sm:flex block    justify-between items-center">
        <div>
          <h2 className=" sm:block hidden">Alerts</h2>
        </div>

        <div className=" flex gap-3 items-center">
          <Button
            onClick={()=>setIsUpdateOpen(true)}
            Icon={<FiPlus />}
            label={"Add Alert"}
            className={
              " bg-secondary text-white  font-normal py-3 rounded-full"
            }
          />

        </div>
      </div>
      <section className="mb-20 mt-5 text-gray-800">
        <div className="block rounded-lg ">
          <div className="flex overflow-hidden flex-col">
            <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full sm:px-6 lg:px-8">
                <div className="">
                  <table className="min-w-full mb-0">
                    <thead className=" ">
                      <tr className=" rounded-lg whitespace-nowrap ">
                      <th
                          scope="col"
                          className=" text-sm text-darkGray   text-left   font-semibold px-6 py-4"
                        >
                          Date
                        </th>
                        <th
                          scope="col"
                          className=" text-sm text-darkGray   text-left   font-semibold px-6 py-4"
                        >
                          Time
                        </th>
                        <th
                          scope="col"
                          className=" text-sm  text-left text-darkGray font-semibold px-6 py-4"
                        >
                            Description
                         </th>
                        
                         <th
                          scope="col"
                          className=" text-sm  text-left text-darkGray font-semibold px-6 py-4"
                        >
                            
                         </th>
                       
                      </tr>
                    </thead>
                    <tbody className="">
                      {currentPageData?.map((item, index) => {
                        return (
                          <>
                            <tr className="bg-white border-t-8  border-b-8 border-lightGray  rounded-md ">
                            <td className="align-middle text-sm font-normal px-6 py-4 whitespace-nowrap  ">
                                <span className=" text-base text-black  py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline   bg-green-200  rounded-full">
                                {moment(item?.date).format('DD/MM/YYYY')}
                                </span>
                              </td>

                              <td className="align-middle text-sm font-normal px-6 py-4 whitespace-nowrap  ">
                                <span className=" text-base text-black  py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline   bg-green-200  rounded-full">
                                {item?.time}
                                </span>
                              </td>

                              <td className="align-middle text-sm font-normal  px-6 py-4">
                                <span className=" text-base text-black  py-1 px-2.5 leading-none   align-baseline   bg-green-200  rounded-full">
                                {item?.message}
                                </span>
                              </td>
                             
                              <td className="align-middle  text-sm font-normal  whitespace-nowrap">
                                <div className="  flex items-center   gap-2">
                                <span
                                  onClick={() => {
                                    openModal();
                                    setFetchUser(item);
                                  }}
                                  className=" text-base text-black  py-1 px-2.5 leading-none  whitespace-nowrap    bg-green-200  rounded-full"
                                >
                                  <PiPencilSimpleFill className=" text-primary" />
                                </span>
                                <span
                                  onClick={() => removeFunction(item?._id)}
                                  className=" text-base text-black   leading-none  cursor-pointer  whitespace-nowrap    bg-green-200  rounded-full"
                                >
                                  <RiDeleteBin5Line className=" text-[#d33]" />
                                </span>
                                </div>
                               
                              </td>
                            
                              
                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className=" flex justify-end items-center  py-4 gap-6">
            <button
              className={`${
                currentPage === 1
                  ? "bg-gray-500  text-white"
                  : "bg-secondary text-white"
              } flex justify-center items-center  w-10 h-10 rounded-md`}
              disabled={currentPage === 1}
              onClick={() => handlePageChange(currentPage - 1)}
            >
              <FaAngleLeft size={20} />
            </button>
            <span className="  text-primary  font-semibold">
              {currentPage} of{" "}
              {Math.ceil(filteredDeposits.length / itemsPerPage)}
            </span>
            <button
              className={`${
                currentPage * itemsPerPage >= filteredDeposits.length
                  ? "bg-gray-500  text-white"
                  : "bg-secondary text-white"
              } flex justify-center items-center  w-10 h-10 rounded-md`}
              disabled={currentPage * itemsPerPage >= filteredDeposits.length}
              onClick={() => handlePageChange(currentPage + 1)}
            >
              <FaAngleRight size={20} />
            </button>
          </div>
        </div>
      </section>
    </Manager_web_portal>
  );
};

export default Alerts;
