import React, { useState, useRef, useCallback, useEffect } from "react";
import Select from 'react-select';
import { toast } from "react-toastify";
import axios from "axios";
import Input from "../../../components/Input";
import Button from "../../../components/Button";
import { MdClose, MdOutlineFileUpload } from "react-icons/md";
import Modal from "../../../components/modal";
import { useNavigate } from "react-router-dom";
import JodiEditor from 'jodit-react';
const UpdatePlan = ({ isModalOpen, setIsModalOpen, closeModal, getData }) => {
  const [loading, setLoading] = useState(false);
  const [planName, setPlanName] = useState("");
  const [minimumInvestmentAmount, setMinimumInvestmentAmount] = useState("");
  const [availableYears, setAvailableYears] = useState([]);
  const [planCategory, setPlanCategory] = useState("");
  const [planDescription, setPlanDescription] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [selectedImageDetails, setSelectedImageDetails] = useState(null);
  const [previewImageDetails, setPreviewImageDetails] = useState(null);

  const fileInputRef = useRef(null);
  const fileInputDetailsRef = useRef(null);
  const navigate = useNavigate();
  const editor = useRef(null);
  // Load default values from getData when the component mounts
  useEffect(() => {
    if (getData) {
      setPlanName(getData.planName || "");
      setMinimumInvestmentAmount(getData.minimumInvestmentAmount || "");
      setAvailableYears(getData.availableYears || []);
      setPlanCategory(getData.planCategory || "");
      setPreviewImageDetails(getData.planDescription || null);
      setPreviewImage(getData.planImage || null);
    }
  }, [getData]);

  const handleDragOver = useCallback((e) => {
    e.preventDefault();
  }, []);


  const handleDragOverDetails = useCallback((e) => {
    e.preventDefault();
  }, []);


  const handleDrop = useCallback((e) => {
    e.preventDefault();
    const files = Array.from(e.dataTransfer.files);
    processFiles(files);
  }, []);

  const handleDropDetails = useCallback((e) => {
    e.preventDefault();
    const files = Array.from(e.dataTransfer.files);
    processFilesDetails(files);
  }, []);

 
  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    processFiles(files);
  };



  const handleFileChangeDetails = (e) => {
    const files = Array.from(e.target.files);
    processFilesDetails(files);
  };

  const processFiles = (files) => {
    const imageFile = files.find((file) => file.type.startsWith("image/"));
    if (imageFile) {
      setSelectedImage(imageFile);
      const reader = new FileReader();
      reader.readAsDataURL(imageFile);
      reader.onload = () => setPreviewImage(reader.result);
    }
  };


  const processFilesDetails = (files) => {
    const imageFile = files.find((file) => file.type.startsWith("image/"));
    if (imageFile) {
      setSelectedImageDetails(imageFile);
      const reader = new FileReader();
      reader.readAsDataURL(imageFile);
      reader.onload = () => setPreviewImageDetails(reader.result);
    }
  };


  // Trigger file input click
  const triggerFileInput = () => {
    fileInputRef.current.click();
  };



  const triggerFileInputDetails= () => {
    fileInputDetailsRef.current.click();
  };

  const options = Array.from({ length:20}, (_, i) => ({ value: `${i + 1}`, label: `${i + 1}` }));

  const handleChange = (selected) => {
    const selectedValues = selected ? selected.map(option => Number(option.value)) : [];
    setAvailableYears(selectedValues);
  };


  const uploadImage = async (imageFile, url) => {
    const formData = new FormData();
    formData.append("images", imageFile);
    const response = await axios.post(url, formData);
    return response.data.data[0]; 
  };



  const onSubmit = async (e) => {
    e.preventDefault();
    if (!planName) {
      toast.error("Must enter title!");
      return;
    } else if (!selectedImage && !previewImage) {
      toast.error("Please choose an image!");
      return;
    }

    setLoading(true);
    try {

      const planImageUrl = selectedImage ? await uploadImage(selectedImage, "https://file-upload-ashen.vercel.app/api/upload") : previewImage;
      const planDetailsImageUrl = selectedImageDetails ? await uploadImage(selectedImageDetails, "https://file-upload-ashen.vercel.app/api/upload") : previewImageDetails;


      const updateData = {
        ...(planName && { planName }),
        ...(planCategory && { planCategory }),
        ...(minimumInvestmentAmount && { minimumInvestmentAmount: Number(minimumInvestmentAmount) }),
        ...(availableYears.length > 0 && { availableYears }),
        ...(planImageUrl && { planImage: planImageUrl }),
        ...(planDetailsImageUrl && { planDescription: planDetailsImageUrl })
        
      };

      const response = await axios.patch(
        `https://welcome-habibi-backend.vercel.app/v1/admin/plan/${getData?._id}`,
        updateData
      );

      if (response.data.success === true) {
        toast.success(response.data.message);
        setIsModalOpen(false);
        window.location.reload();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Upload failed!");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        className={"md:w-[70%] w-[100%]"}
      >
        <div className="p-5">
          <div className="flex justify-between items-center">
            <div></div>
            <MdClose
              className="text-gray-500"
              onClick={() => setIsModalOpen(false)}
              size={25}
            />
          </div>
          <form onSubmit={onSubmit}>
            <div className="grid grid-cols-2 gap-10">
              <div className="flex w-full gap-3 p-5 flex-wrap">
                <div className="w-full">
                  <label
                    htmlFor="planCategory"
                    className="block mb-2 text-[18px] font-medium text-black"
                  >
                    Choose Plan Category
                  </label>
                  <select
                    value={planCategory}
                    name="planCategory"
                    onChange={(e) => setPlanCategory(e.target.value)}
                    className="py-4 font-medium w-full rounded border-r-8 bg-lightGray outline-none border-transparent px-4 text-md"
                  >
                    <option>Select Category</option>
                    <option>A</option>
                    <option>B</option>
                    <option>C</option>
                  </select>
                </div>
                <div className="w-full">
                  <Input
                    value={planName}
                    onChange={(e) => setPlanName(e.target.value)}
                    label="Plan Name"
                    placeholder="Plan Name"
                    name="planName"
                    className="w-full py-4"
                  />
                </div>
                <div className="w-full">
                  <label className="block mb-2 text-[18px] font-medium text-black">
                    Enter Details
                  </label>
                  {/* <div className="rounded-lg">
                    
                     <JodiEditor  ref={editor}  value={planDescription} onChange={setPlanDescription} />
                  </div> */}
                   <div
                  className="w-full border-dashed border-[#9E9E9E] border-2 rounded-lg p-4 cursor-pointer"
                  onDragOver={handleDragOverDetails}
                  onDrop={handleDropDetails}
                  onClick={triggerFileInputDetails}
                >
                  <div className="bg-[#F6F6F6] mx-auto w-14 flex justify-center items-center h-14 rounded-2xl">
                    <MdOutlineFileUpload size={30} color="#c8c7cf" />
                  </div>
                  <p className="text-primary font-medium mt-3 text-center">
                    <span className="text-secondary">Click here</span> or drag and drop to upload a slip
                  </p>
                  {previewImageDetails && (
                    <img
                      src={previewImageDetails}
                      alt="Uploaded slip"
                      className="mt-4 mx-auto"
                      style={{ maxWidth: "100%", height: "auto" }}
                    />
                  )}
                  <input
                    type="file"
                    onChange={handleFileChangeDetails}
                    style={{ display: "none" }}
                    ref={fileInputDetailsRef}
                  />
                </div>
                </div>
              </div>

              <div className="flex w-full gap-3 p-5 flex-wrap">
                <div className="w-full">
                  <Input
                    value={minimumInvestmentAmount}
                    onChange={(e) => setMinimumInvestmentAmount(e.target.value)}
                    label="Minimum Installment"
                    placeholder=""
                    name="minimumInvestmentAmount"
                    className="w-full py-4"
                  />
                </div>

                <div className="w-full">
                  <label className="font-semibold">No. of Years</label>
                  <Select
                    isMulti
                    name="availableYears"
                    options={options}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    value={options.filter(option => availableYears.includes(Number(option.value)))}
                    onChange={handleChange}
                  />
                </div>

                <div
                  className="w-full border-dashed border-[#9E9E9E] border-2 rounded-lg p-4 cursor-pointer"
                  onDragOver={handleDragOver}
                  onDrop={handleDrop}
                  onClick={triggerFileInput}
                >
                  <div className="bg-[#F6F6F6] mx-auto w-14 flex justify-center items-center h-14 rounded-2xl">
                    <MdOutlineFileUpload size={30} color="#c8c7cf" />
                  </div>
                  <p className="text-primary font-medium mt-3 text-center">
                    <span className="text-secondary">Click here</span> or drag and drop to upload a slip
                  </p>
                  {previewImage && (
                    <img
                      src={previewImage}
                      alt="Uploaded slip"
                      className="mt-4 mx-auto"
                      style={{ maxWidth: "100%", height: "auto" }}
                    />
                  )}
                  <input
                    type="file"
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                    ref={fileInputRef}
                  />
                </div>
                <div className="pt-4 w-full">
                  <Button
                    disabled={loading}
                    label={loading ? "Loading..." : "Update"}
                    type="submit"
                    className="bg-blue-500 justify-center text-white uppercase py-3 border-b-2 border-black w-full"
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default UpdatePlan;
