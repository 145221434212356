import React from "react";
import { useAllbranchsQuery } from "../../store/services/branchService";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
const ContactUs = () => {
  const { data, isFetching } = useAllbranchsQuery();
  console.log(data, isFetching);
  const displayShortLocation = (location) => {
    if (!location) return "";
    const words = location.split(" ");
    return words.slice(0, 3).join(" ");
  };
  return (
    <div className="  bg-blue">
      <div className=" container px-7  md:flex  block justify-around items-center py-20    md:pb-60 pb-6 mx-auto">
        <div className=" md:w-[60%] w-full ">
          <h1 className=" text-white font-bold text-3xl">Contact Us</h1>
          <p className=" text-white">
            Contact us, visit and Join us for Halal business.
          </p>
          <div className=" flex flex-col my-8 gap-4">
            {data?.branches?.map((item, index) => {
              return (
                <div className=" bg-[#2C3036] flex md:flex-row flex-col gap-4   justify-between rounded-lg p-2.5">
                  <div className=" flex gap-2">
                    <div className=" pt-1">
                      <img
                        src={require("../../assets/image/map-pin.png")}
                        alt=""
                      />
                    </div>
                    <div>
                      <h2 className=" text-white ">{item?.city}</h2>
                      <p className=" m-0 text-white">
                        {displayShortLocation(item?.location)}..
                      </p>
                    </div>
                  </div>

                  <div className=" flex gap-2">
                    <div className=" pt-1">
                      <img
                        src={require("../../assets/image/call2.png")}
                        alt=""
                      />
                    </div>
                    <div>
                      <p className=" m-0 text-white">
                        Contact 1: {item?.contactNumber1}
                      </p>
                      <p className=" m-0 text-white">
                        Contact 2: {item?.contactNumber2}
                      </p>
                    </div>
                  </div>
                  <div className=" flex  items-center gap-2">
                    <div className=" pt-1">
                      <img
                        src={require("../../assets/image/email.png")}
                        alt=""
                      />
                    </div>
                    <div>
                      <p className=" m-0 text-white">{item?.email}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div>
          <img src={require("../../assets/image/pngwing.png")} alt="" />
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
