import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Base_url } from "../../utils/Base_url";

const authService = createApi({
  reducerPath: "auth",
  baseQuery: fetchBaseQuery({
    baseUrl:`${Base_url}/v1/admin/`,
  }),
  endpoints: (builder) => {
    return {
      authLogin: builder.mutation({
        query: (loginData) => {
          return {
            url: "login",
            method: "POST",
            body: loginData,
          };
        },
      }),
      userRegister: builder.mutation({
        query: (data) => {
          return {
            url: "register",
            method: "POST",
            body: data,
          };
        },
      }),

      updateuser: builder.mutation({
        query: (data) => {
          return {
            url: `update/${data.id}`,
            method: "PUT",
            body: { name: data.name },
          };
        },
      }),
      deleteuser: builder.mutation({
        query: (id) => {
          return {
            url: `delete/${id}`,
            method: "DELETE",
          };
        },
      }),

      fetchuser: builder.query({
        query: (id) => {
          return {
            url: `get/${id}`,
            method: "GET",
          };
        },
      }),
      allusers: builder.query({
        query: () => {
          return {
            url: "getAll",
            method: "GET",
          };
        },
      }),
    };
  },
});
export const {
  useAuthLoginMutation,
  useUserRegisterMutation,
  useFetchuserQuery,
  useAllusersQuery,
  useUpdateuserMutation,
  useDeleteuserMutation,
} = authService;
export default authService;
