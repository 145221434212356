import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import Input from "../../../../components/Input";
import Modal from "../../../../components/modal";
import { MdClose } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { setSuccess } from "../../../../store/reducers/globalReducer";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "../../../../hooks/Form";
import { useEnrollMutation } from "../../../../store/services/investorService";
import Add_ViewForm from "../../deposits/Add_ViewForm";

const AddEnrollInvestor = ({
  isModalOpen,
  setIsModalOpen,
  closeModal,
  getData,
  viewPlans,
}) => {
  const [loading, setLoading] = useState(false);
  const { workerToken } = useSelector((state) => state.authReducer);
  const { state, onChange } = useForm({
    planId: "",
    cnic: "",
    monthlyInstallment: "",
    numberOfYears: "",
    investorName: "",
    installmentS: "",
  });


  console.log(state);
  

  const [name, setName] = useState("");
  const [isEnable, setIsEnable] = useState(true);
  const [checked, setChecked] = useState(false);
  const [isUpdateOpen2, setIsUpdateOpen2] = useState(false); // Controls second form modal state
  const [viewData,setViewData] = useState(null)

  console.log(viewData);
  
  // Checking investor's CNIC
  const checkInvestor = () => {
    const formattedCnic = state.cnic.replace(/-/g, "").trim();

    if (!formattedCnic) {
      return toast.error("CNIC field should not be empty");
    }

    if (checked) {
      return toast.error("Already checked");
    }

    const isPatternCorrect = /^\d{13}$/.test(formattedCnic);
    if (!isPatternCorrect) {
      return toast.error("Please make sure the CNIC is exactly 13 digits.");
    }

    axios
      .post(
        "https://welcome-habibi-backend.vercel.app/v1/worker/check-investor-cnic",
        { cnic: formattedCnic }
      )
      .then((res) => {
        if (res?.data?.success) {
          if (res?.data?.investor?.bioStatus === "true") {
            // Investor bio data already present, just set the name and proceed.
            setChecked(true);
            setName(res?.data?.investor?.fullName);
          } else {
            setViewData(res?.data?.investor);
            // When bioStatus is false, close current modal and open the view form
            toast.error("Please fill in your data.");
            setIsModalOpen(false); // Close the first modal
            setIsUpdateOpen2(true); // Open the second modal (Add_ViewForm)
          }
        } else {
          setIsEnable(false);
          setChecked(true);
        }
      })
      .catch((error) => {
        toast.error(error.response?.data?.message);
      });
  };

  const [create, response] = useEnrollMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSubmit = (e) => {
    e.preventDefault();
    const isPatternCorrect = /^\d{13}$/.test(state.cnic.replace(/[-\s]/g, ""));
    if (checked === false) {
      return toast("Please Check CNIC First");
    }
    if (!isPatternCorrect) {
      toast.error("Please make sure it's exactly 13 digits.");
    }

    if (!name) {
      return toast.error("Please enter the investor's name.");
    }
    if (!state.numberOfYears) {
      return toast.error("Please enter the number of years.");
    }
    if (viewPlans?.planCategory === "C" && !state.installmentS) {
      return toast.error(
        "Installment Schedule is required for plan category C."
      );
    }

    setLoading(true);

    const payload = {
      investorCnic: state.cnic,
      planId: getData?._id,
      numberOfYears: state.numberOfYears,
      investorName: name,
      workerId: workerToken,
    };

    if (state.installmentS) {
      payload.paymentFrequency = state.installmentS;
    }

    create(payload);
  };

  useEffect(() => {
    if (response.isError) {
      toast.error(response?.error?.data?.message);
    }
  }, [response?.error?.data]);

  useEffect(() => {
    if (response.isSuccess) {
      if (response?.data?.success) {
        dispatch(setSuccess(response?.data?.message));
        toast.success(response?.data?.message);
        setLoading(false);
        setIsModalOpen(false);
        window.location.reload();
      } else {
        toast.error(response?.data?.message);
        setLoading(false);
      }
    }
  }, [response.isSuccess]);

  return (
    <>
      {/* Main Enroll Modal */}
      <Modal
        className="md:w-[40%] w-[100%]"
        isOpen={isModalOpen}
        onClose={closeModal}
      >
        <div className="p-5">
          <div className="flex justify-between items-center">
            <div></div>
            <MdClose
              className="text-gray-500"
              onClick={() => setIsModalOpen(false)}
              size={25}
            />
          </div>

          <form onSubmit={onSubmit}>
            <div className="p-5 flex gap-3 flex-wrap">
              <div className="w-full">
                <Input
                  onChange={onChange}
                  name="cnic"
                  value={state.cnic}
                  label="Search Investor"
                  placeholder="Search By CNIC No"
                  className="w-full py-4"
                  Icon={
                    <button
                      onClick={checkInvestor}
                      type="button"
                      className={`  text-white py-1.5 px-1 rounded-md`}
                      style={
                        checked === true
                          ? { backgroundColor: "green", fontSize: "14px" }
                          : { backgroundColor: "#00008B", fontSize: "14px" }
                      }
                    >
                      {checked === true ? " Checked" : "Check Now"}
                    </button>
                  }
                />
              </div>

              <div className="w-full">
                <Input
                  className={" w-full  py-4"}
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  label={"Full Name"}
                  placeholder={""}
                  name={"name"}
                  disabled={isEnable}
                />
              </div>

              {viewPlans?.planCategory === "C" && (
                <div className="w-full">
                  <label className="block mb-2 text-[18px] font-medium text-black">
                    Installment Schedule
                  </label>
                  <select
                    value={state.installmentS}
                    onChange={onChange}
                    name="installmentS"
                    className="py-4 font-medium w-full rounded border-r-8 bg-lightGray outline-none border-transparent px-4 text-md"
                  >
                    <option>Select...</option>
                    <option value={"monthly"}>Monthly</option>
                    <option value={"quarterly"}>Quarterly</option>
                  </select>
                </div>
              )}

              <div className="w-full">
                <label
                  htmlFor="numberOfYears"
                  className="block mb-2 text-[18px] font-medium text-black"
                >
                  No. of Years
                </label>
                <select
                  onChange={onChange}
                  name="numberOfYears"
                  value={state.numberOfYears}
                  className="py-4 font-medium w-full rounded border-r-8 bg-lightGray outline-none border-transparent px-4 text-md"
                >
                  <option>Select Year</option>
                  {getData?.availableYears?.map((item) => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
              </div>

              {loading ? (
                <button
                  disabled
                  type="button"
                  className="bg-blue text-center mt-3 uppercase text-white py-3 border-b-black border-2 w-full"
                >
                  <svg
                    aria-hidden="true"
                    role="status"
                    className="inline w-4 h-4 mr-3 text-white animate-spin"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908"
                      fill="currentColor"
                    />
                  </svg>
                  Submitting...
                </button>
              ) : (
                <button
                  className="bg-blue text-center mt-3 uppercase text-white py-3 border-b-black border-2 w-full"
                  type="submit"
                >
                  Submit
                </button>
              )}
            </div>
          </form>
        </div>
      </Modal>

      {/* Second Modal (Add_ViewForm) */}
      <Add_ViewForm
      getData={viewData}
        isModalOpen={isUpdateOpen2}
        setIsModalOpen={() => setIsUpdateOpen2(false)} // Close manually
      />
    </>
  );
};

export default AddEnrollInvestor;
