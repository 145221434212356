import React, { useState, useRef, useCallback, useEffect } from "react";
import Select from "react-select";
import { toast } from "react-toastify";
import axios from "axios";
import Input from "../../../components/Input";
import Button from "../../../components/Button";
import { MdClose, MdOutlineFileUpload } from "react-icons/md";
import Modal from "../../../components/modal";
import { useNavigate } from "react-router-dom";
import JodiEditor from 'jodit-react';
const AddPlans = ({ isModalOpen, setIsModalOpen, closeModal }) => {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState("");
  console.log(value);
  const [planName, setPlanName] = useState("");
  const [minimumInvestmentAmount, setMinimumInvestmentAmount] = useState("");
  const [planCategory, setPlanCategory] = useState("");
  const [totalAmount, setTotalAmount] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);

  const [selectedImageDetails, setSelectedImageDetails] = useState(null);
  const [previewImageDetails, setPreviewImageDetails] = useState(null);

  const fileInputRef = useRef(null);
  const fileInputDetailsRef = useRef(null);
  const editor = useRef(null);
  const handleDragOver = useCallback((e) => {
    e.preventDefault();
  }, []);


  const handleDragOverDetails = useCallback((e) => {
    e.preventDefault();
  }, []);


  const handleDrop = useCallback((e) => {
    e.preventDefault();
    const files = Array.from(e.dataTransfer.files);
    processFiles(files);
  }, []);

  const handleDropDetails = useCallback((e) => {
    e.preventDefault();
    const files = Array.from(e.dataTransfer.files);
    processFilesDetails(files);
  }, []);

 
  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    processFiles(files);
  };



  const handleFileChangeDetails = (e) => {
    const files = Array.from(e.target.files);
    processFilesDetails(files);
  };

  const processFiles = (files) => {
    const imageFile = files.find((file) => file.type.startsWith("image/"));
    if (imageFile) {
      setSelectedImage(imageFile);
      const reader = new FileReader();
      reader.readAsDataURL(imageFile);
      reader.onload = () => setPreviewImage(reader.result);
    }
  };


  const processFilesDetails = (files) => {
    const imageFile = files.find((file) => file.type.startsWith("image/"));
    if (imageFile) {
      setSelectedImageDetails(imageFile);
      const reader = new FileReader();
      reader.readAsDataURL(imageFile);
      reader.onload = () => setPreviewImageDetails(reader.result);
    }
  };


  // Trigger file input click
  const triggerFileInput = () => {
    fileInputRef.current.click();
  };



  const triggerFileInputDetails= () => {
    fileInputDetailsRef.current.click();
  };

  const navigate = useNavigate();

  const options = [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
    { value: "6", label: "6" },
    { value: "7", label: "7" },
    { value: "8", label: "8" },
    { value: "9", label: "9" },
    { value: "10", label: "10" },
    { value: "11", label: "11" },
    { value: "12", label: "12" },
    { value: "13", label: "13" },
    { value: "14", label: "14" },
    { value: "15", label: "15" },
    { value: "16", label: "16" },
    { value: "17", label: "17" },
    { value: "18", label: "18" },
    { value: "19", label: "19" },
    { value: "20", label: "20" },
  ];

  const [selectedOptions, setSelectedOptions] = useState([]);

  // Log selected options
  console.log(selectedOptions);

  const handleChange = (selected) => {
    const selectedValues = selected
      ? selected.map((option) => Number(option.value))
      : [];
    setSelectedOptions(selectedValues);
  };


  const uploadImage = async (imageFile, url) => {
    const formData = new FormData();
    formData.append("images", imageFile);
    const response = await axios.post(url, formData);
    return response.data.data[0]; // Assuming the uploaded image URL is returned here
  };

  // Handle form submission
  const onSubmit = async (e) => {
    e.preventDefault();
    if (!planName) {
      toast.error("Must enter title!");
      return;
    } else if (!selectedImage) {
      toast.error("Please choose a plan image!");
      return;
    } else if (!selectedImageDetails) {
      toast.error("Please choose an image for plan details!");
      return;
    }

    setLoading(true);
    try {

      const planImageUrl = await uploadImage(selectedImage, "https://file-upload-ashen.vercel.app/api/upload");
      const planDetailsImageUrl = await uploadImage(selectedImageDetails, "https://file-upload-ashen.vercel.app/api/upload");

      const param = new FormData();
      param.append("images", selectedImage);

      const uploadResponse = await axios.post(
        `https://file-upload-ashen.vercel.app/api/upload`,
        param
      );

      console.log(uploadResponse.data.data[0]);

      const response = await axios.post(
        "https://welcome-habibi-backend.vercel.app/v1/admin/plan",
        {
          planName: planName,
          planCategory: planCategory,
          minimumInvestmentAmount: Number(minimumInvestmentAmount),
          availableYears: selectedOptions,
          planImage: planImageUrl,
          planDescription:planDetailsImageUrl,
          planAmount: Number(totalAmount),
          // .split("\n")
          // .map((item) => item.trim())
          // .filter((item) => item),
        }
      );

      console.log(response);

      if (response.data.success === true) {
        toast.success(response.data.message);
        setIsModalOpen(false);
        window.location.reload();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Upload failed!");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        className={"md:w-[70%] w-[100%]"}
      >
        <div className="p-5">
          <div className="flex justify-between items-center">
            <div></div>
            <MdClose
              className="text-gray-500"
              onClick={() => setIsModalOpen(false)}
              size={25}
            />
          </div>
          <form onSubmit={onSubmit}>
            <div className="grid grid-cols-2 gap-10">
              <div className="flex w-full gap-3 p-5 flex-wrap">
                <div className="w-full">
                  <label
                    htmlFor="planCategory"
                    className="block mb-2 text-[18px] font-medium text-black"
                  >
                    Choose Plan Category
                  </label>
                  <select
                    value={planCategory}
                    name="planCategory"
                    onChange={(e) => setPlanCategory(e.target.value)}
                    className="py-4 font-medium w-full rounded border-r-8 bg-lightGray outline-none border-transparent px-4 text-md"
                  >
                    <option>Select Category</option>
                    <option>A</option>
                    <option>B</option>
                    <option>C</option>
                  </select>
                </div>

                {planCategory === "C" ? (
                  <div className="w-full">
                    <Input
                      value={totalAmount}
                      onChange={(e) => setTotalAmount(e.target.value)}
                      label="Total Amount"
                      placeholder="enter amount"
                      name="totalAmount"
                      type="number"
                      className="w-full py-4"
                    />
                  </div>
                ) : null}
                <div className="w-full">
                  <Input
                    value={planName}
                    onChange={(e) => setPlanName(e.target.value)}
                    label="Plan Name"
                    placeholder="Plan Name"
                    name="planName"
                    className="w-full py-4"
                  />
                </div>
                <div className="w-full">
                  <label className="block mb-2 text-[18px] font-medium text-black">
                    Upload Details
                  </label>
                  
                  {/* <div className="bg-lightGray border-transparent border-0">
                  

                    <JodiEditor  ref={editor}  value={value} onChange={setValue} />
                    
                  </div> */}

<div
                  className="w-full mt-4 border-dashed border-2 border-gray-400 rounded-lg p-4 cursor-pointer"
                  onDragOver={handleDragOverDetails}
                  onDrop={handleDropDetails}
                  onClick={triggerFileInputDetails}
                >
                  <div className="bg-gray-100 flex mx-auto text-center justify-center w-14 h-14 items-center rounded-2xl">
                    <MdOutlineFileUpload size={30} color="#c8c7cf" />
                  </div>
                  <p className="text-center mt-3">
                    <span className="text-secondary">Click here</span> or drag
                    and drop to upload an image
                  </p>
                  <div className="mt-4">
                    {previewImageDetails && (
                      <img
                        src={previewImageDetails}
                        alt="Preview"
                        className="w-[100%] object-cover h-32 rounded-md"
                      />
                    )}
                  </div>
                  <input
                    type="file"
                    onChange={handleFileChangeDetails}
                    style={{ display: "none" }}
                    ref={fileInputDetailsRef}
                  />
                </div>

                </div>
              </div>

              <div className="flex w-full gap-3 p-5 flex-wrap">
                <div className="w-full">
                  <Input
                    value={minimumInvestmentAmount}
                    onChange={(e) => setMinimumInvestmentAmount(e.target.value)}
                    label="Minimum Installment"
                    placeholder=""
                    name="setMinimumInvestmentAmount"
                    className="w-full py-4"
                  />
                </div>

                <div className="w-full">
                  {/* <MultiSelect
                    value={minimumInvestmentAmount}
                    onChange={(e) => setMinimumInvestmentAmount(e.target.value)}
                    name={"availableYears"}
                    id="multiSelect"
                  /> */}

                  <label className="  font-semibold ">No. of Years</label>

                  <Select
                    isMulti
                    name="flavors"
                    options={options}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    value={options.filter((option) =>
                      selectedOptions.includes(Number(option.value))
                    )}
                    onChange={handleChange}
                  />
                </div>

                <div
                  className="w-full mt-4 border-dashed border-2 border-gray-400 rounded-lg p-4 cursor-pointer"
                  onDragOver={handleDragOver}
                  onDrop={handleDrop}
                  onClick={triggerFileInput}
                >
                  <div className="bg-gray-100 flex mx-auto text-center justify-center w-14 h-14 items-center rounded-2xl">
                    <MdOutlineFileUpload size={30} color="#c8c7cf" />
                  </div>
                  <p className="text-center mt-3">
                    <span className="text-secondary">Click here</span> or drag
                    and drop to upload an image
                  </p>
                  <div className="mt-4">
                    {previewImage && (
                      <img
                        src={previewImage}
                        alt="Preview"
                        className="w-[100%] object-cover h-32 rounded-md"
                      />
                    )}
                  </div>
                  <input
                    type="file"
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                    ref={fileInputRef}
                  />
                </div>
                <div className=" pt-4 w-full">
                  <Button
                    disabled={loading}
                    label={loading ? "Loading..." : "Add"}
                    type="submit"
                    className="bg-blue-500 justify-center text-white uppercase py-3 border-b-2 border-black w-full"
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default AddPlans;
