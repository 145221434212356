import { Link, useNavigate } from "react-router-dom";
import Footer from "../../components/footer";
import Wrapper from "../../layout/Wrapper";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useForm } from "../../hooks/Form";
import { useAuthLoginMutation } from "../../store/services/authService";
import {
  setAdminToken,
  setInvestorToken,
  setManagerToken,
  setWorkerToken,
} from "../../store/reducers/authReducer";
import { setSuccess } from "../../store/reducers/globalReducer";
import ForgetPassword from "../forget_password";

const AdminLogin = () => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const cnicRegex = /^\d{13}$/;
  const [loading, setLoader] = useState(false);
  const [errors, setErrors] = useState([]);
  const { state, onChange } = useForm({
    identifier: "",
    password: "",
  });
  const [loginUser, response] = useAuthLoginMutation();
  console.log(response);
  const onSubmit = (e) => {
    e.preventDefault();
    let hasError = false;

    if (!state.identifier) {
      toast.error("Must enter email");
    } else if (
      !emailRegex.test(state.identifier) &&
      !cnicRegex.test(state.identifier)
    ) {
      toast.error("Must enter a valid email address or CNIC number");
      hasError = true;
    } else if (!state.password) {
      toast.error("Must enter password");
      hasError = true;
    } else {
      setLoader(true);
      loginUser(state);
    }
  };
  useEffect(() => {
    if (response.isError) {
      setErrors(response?.error?.data?.message);
      toast.error(response?.error?.data?.message);
      setLoader(false);
    }
  }, [response?.error?.data]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (response.isSuccess) {
      if (response?.data?.success === true) {
        setLoader(false);
        if (response?.data?.user?.role === "admin") {
          dispatch(setAdminToken(response?.data?.user?._id));
          navigate("/super_admin/dashboard");
          toast.success(response?.data?.message);
          dispatch(setSuccess(response?.data?.msg));
        } else if (response?.data?.user?.role === "manager") {
          dispatch(setManagerToken(response?.data?.user?._id));

          localStorage.setItem(
            "manager_data",
            JSON.stringify(response?.data?.user)
          );
          navigate("/manager/dashboard");
          toast.success(response?.data?.message);
          dispatch(setSuccess(response?.data?.msg));
        } else if (response?.data?.user?.role === "worker") {
          dispatch(setWorkerToken(response?.data?.user?._id));
          localStorage.setItem(
            "worker_data",
            JSON.stringify(response?.data?.user)
          );
          navigate("/workers/my_portal");
          toast.success(response?.data?.message);
          dispatch(setSuccess(response?.data?.msg));
        } else if (response?.data?.model === "Investor") {
          dispatch(setInvestorToken(response?.data?.user?._id));
          navigate("/investor/plans");
          toast.success(response?.data?.message);
          dispatch(setSuccess(response?.data?.msg));
          localStorage.setItem(
            "investor_data",
            JSON.stringify(response?.data?.user)
          );
        } else {
        }
      } else {
        toast.error(response?.data?.message);
        setLoader(false);
      }
    }
  }, [response.isSuccess]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Wrapper
        className="    h-screen flex justify-center items-center"
        ToggleBtn={
          <div className="  md:hidden  block">
            <img
              // onClick={openSidebar}
              src={require("../../assets/image/menu.png")}
              alt=""
            />
          </div>
        }
        title_props={
          <>
                        <h1 className="main_title">Welcome Habibi Trading </h1>

          </>
        }
      >
        <form
          onSubmit={onSubmit}
          className="   bg-white shadow-2xl md:flex block  w-full rounded"
        >
          <div className=" text-center  md:w-[50%] w-full">
            <img
              src={require("../../assets/image/login_bg.png")}
              className=" h-full  object-cover"
              alt=""
            />
          </div>

          <ForgetPassword
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            // getData={getData}
          />
          <div className=" bg-BG  md:px-14  px-6 pt-2 bg-cover bg-center bg-[#0054A5] flex items-center justify-center md:w-[50%] w-full">
            <div>
              <h1 className=" text-center font-semibold text-3xl text-white pb-8">
                Login
              </h1>
              <div className="mb-4 mt-4">
                <label className=" text-white font-normal py-3">
                  Email Address/CNIC Number
                </label>
                <input
                  onChange={onChange}
                  type="text"
                  value={state.identifier}
                  name="identifier"
                  className="w-full    bg-white mt-2 p-4 rounded outline-none text-black"
                  placeholder="Enter email..."
                  autoComplete="off"
                />
              </div>
              <div className="mb-4">
                <label className=" text-white font-normal py-3">Password</label>
                <input
                   
                  onChange={onChange}
                  type="text"
                  value={state.password}
                  name="password"
                  className="w-full mt-2     bg-white  p-4 rounded outline-none text-black"
                  placeholder="Enter password..."
                  autoComplete="off"
                />
              </div>
              {/* <div className=" text-end pb-3">
                <Link   onClick={openModal}  className=" text-white border-b ">
                Forget Password
                </Link>
              </div> */}
              <div className="mb-4">
                {loading === true ? (
                  <button
                    disabled
                    type="button"
                    class="bg-blue   text-xl text-center w-full   py-3 rounded text-white  font-semibold cursor-pointer"
                  >
                    <svg
                      aria-hidden="true"
                      role="status"
                      class="inline w-4 h-4 me-3 text-white animate-spin"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="#E5E7EB"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentColor"
                      />
                    </svg>
                    Loading...
                  </button>
                ) : (
                  <button
                    type="submit"
                    value={"sign in"}
                    className="   bg-blue   text-xl text-center w-full   py-3 rounded text-white  font-semibold cursor-pointer"
                  >
                    Login
                  </button>
                )}
              </div>
            </div>
          </div>
        </form>
      </Wrapper>

      <Footer />
    </>
  );
};
export default AdminLogin;
